import React, { useState, useEffect } from "react";
import { API_URL } from "../config";
import { Link, useParams } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { initializeEcho } from "../utils/Echo";

function PrayerCenterBookingView() {
    const [booking, setBooking] = useState(null);
    const [loading, setLoading] = useState(false);
    const { bookingToken } = useParams();
    const [showBackButton, setShowBackButton] = useState(false);
    const [mpesaPhoneNumber, setMpesaPhoneNumber] = useState("");
    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [broadcastToken, setBroadcastToken] = useState(null);

    useEffect(() => {
        const fetchBookingDetails = async () => {
            setLoading(true);
            setError(null); // Clear any previous errors
            setBooking(null); // Reset booking to null on new fetch

            try {
                const response = await fetch(`${API_URL}/bookings/token/${bookingToken}`);

                if (response.ok) {
                    const data = await response.json();
                    if (!data) {
                        throw new Error("No booking details found.");
                    }
                    setBooking(data);
                } else if (response.status === 403) {
                    const errorResponse = await response.json();
                    setShowBackButton(true);
                    throw new Error(errorResponse.message)
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchBookingDetails();
    }, [bookingToken]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Clear previous messages
        setError("");
        setSuccessMessage("");

        // Validate phone number
        const strippedNumber = mpesaPhoneNumber.startsWith("0")
            ? mpesaPhoneNumber.slice(1)
            : mpesaPhoneNumber;

        if (!/^[1-9][0-9]{8}$/.test(strippedNumber)) {
            setError("Invalid phone number.");
            return;
        }

        setLoading(true);

        try {
            const response = await fetch(`${API_URL}/bookings/pay`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    phone_number: strippedNumber,
                    booking_id: booking.id,
                }),
            });

            if (response.ok) {
                const data = await response.json();
                setBroadcastToken(data.broadcastToken);
                setSuccessMessage(data.message);
            } else {
                const errorData = await response.json();
                setError(errorData.phone_number || "An error occurred while processing your request.");
            }
        } catch (err) {
            setError("An unexpected error occurred. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    /* useEffect(() => {
        if (!broadcastToken) return; // Avoid running if token is not set

        const echo = initializeEcho();
        const channel = echo.channel(`payments.${broadcastToken}`);

        channel.listen(".PaymentStatusUpdated", (event) => {
            console.log("Payment Event:", event);
            
            if (event.status === "successful") {
                setSuccessMessage(`Success: ${event.payment.reason} (ID: ${event.payment.modelId})`);
            } else if (event.status === "failed") {
                setError(`Failed: ${event.payment.reason} (ID: ${event.payment.modelId})`);
            }
        });

        return () => {
            echo.leaveChannel(`payments.${broadcastToken}`);
        };
    }, [broadcastToken]); */

    const checkPaymentStatus = (broadcastToken, setSuccessMessage, setError) => {
        const echo = initializeEcho(); // Initialize Echo
        let timeoutId = null; // Control timeout

        echo.channel(`payments.${broadcastToken}`)
            .listen(".PaymentStatusUpdated", (event) => {
                console.log("Payment Event:", event);

                if (event.status === "successful") {
                    setSuccessMessage(`Success: ${event.payment.reason} (ID: ${event.payment.modelId})`);
                } else if (event.status === "failed") {
                    setError(`Failed: ${event.payment.reason} (ID: ${event.payment.modelId})`);
                }

                // Stop listening once response is received
                clearTimeout(timeoutId);
                echo.leaveChannel(`payments.${broadcastToken}`);
            });

        // Set timeout to stop listening after 60 seconds
        timeoutId = setTimeout(() => {
            console.log("Stopping listening after 70 seconds...");
            echo.leaveChannel(`payments.${broadcastToken}`);
        }, 70000);

        return () => {
            clearTimeout(timeoutId);
            echo.leaveChannel(`payments.${broadcastToken}`);
        };
    };

    useEffect(() => {
        if (broadcastToken) {
            setTimeout(() => {
                checkPaymentStatus(broadcastToken, setSuccessMessage, setError);
            }, 5000);
        }
    }, [broadcastToken]);

    return (
        <div>
            <Header />
            <div className={`container mt-0 ${!booking ? "with-min-height" : ""}`}>
                <div className="section">
                    {error && (
                        <div className="section box">
                            <p className="has-text-danger is-size-6">Error: {error}</p>
                            {showBackButton && (
                                <Link to="/makuyu-prayer-center-booking" className="navbar-item is-size-5">
                                    <span className="icon is-large">
                                        <i className="fas fa-arrow-left"></i>
                                    </span>
                                    <span>Try Again</span>
                                </Link>
                            )}
                        </div>

                    )}

                    {(loading && !booking) && <p>Loading booking details...</p>}
                    {booking ? (
                        <div className="section box">
                            <div className="columns is-multiline">
                                {/* Payment Details Section */}

                                {/* {!booking.payment_id && ( */}
                                <div className="box">
                                    <p className="is-size-5">
                                        Please note that booking cannot be reserved for you until you make payment.
                                        <br />
                                        Pay <strong>Kes ###</strong> to reserve your space.
                                        <br />
                                        Your Payment option is <strong>Mpesa Express</strong>.
                                    </p>

                                    <div className="columns is-multiline">
                                        {/* Payment Instructions */}
                                        <div className="column is-half">
                                            <p className="mt-3">
                                                Click on the "Make Payment" button to send a payment request to your phone.
                                                A popup will appear on your phone. Enter your MPESA PIN. You will get a
                                                confirmation SMS and an email from us.
                                            </p>
                                        </div>

                                        {/* Payment Form */}
                                        <div className="column is-half mt-0 pt-0">
                                            <div className="box  mt-0 pt-0">
                                                <form onSubmit={handleSubmit}>
                                                    <div className="field">
                                                        <label className="label">Mpesa Phone Number</label>
                                                        <div className="control is-flex has-addons">
                                                            <span className="button is-static">+254</span>
                                                            <input
                                                                className={`input ${error ? "is-danger" : ""}`}
                                                                type="text"
                                                                placeholder="Enter your phone number"
                                                                value={mpesaPhoneNumber}
                                                                onChange={(e) => setMpesaPhoneNumber(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>

                                                    {error && <p className="help is-danger">{error}</p>}
                                                    {successMessage && <p className="help is-success">{successMessage}</p>}


                                                    <div className="field">
                                                        <div className="control">
                                                            <button
                                                                className={`button is-primary ${loading ? "is-loading" : ""}`}
                                                                type="submit"
                                                                disabled={loading}
                                                            >
                                                                Make Payment
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* )} */}

                                {/* Reference Number */}
                                <div className="column is-half">
                                    <div className="field">
                                        <label className="label">Reference Number</label>
                                        <p className="control is-expanded has-background-light p-2">{booking.reference_no}</p>
                                    </div>
                                </div>

                                {/* Booking Type */}
                                <div className="column is-half">
                                    <div className="field">
                                        <label className="label">Booking Type</label>
                                        <p className="control is-expanded has-background-light p-2">{booking.booking_type}</p>
                                    </div>
                                </div>

                                {/* Individual Name */}
                                <div className="column is-half">
                                    <div className="field">
                                        <label className="label">Your Name</label>
                                        <p className="control is-expanded has-background-light p-2">{booking.individual_name}</p>
                                    </div>
                                </div>

                                {/* Group Name */}
                                {booking.booking_type === "Group" && (
                                    <div className="column is-half">
                                        <div className="field">
                                            <label className="label">Group Name</label>
                                            <p className="control is-expanded has-background-light p-2">{booking.group_name}</p>
                                        </div>
                                    </div>
                                )}

                                {/* Start Date */}
                                <div className="column is-half">
                                    <div className="field">
                                        <label className="label">Start Date</label>
                                        <p className="control is-expanded has-background-light p-2">{booking.start_date}</p>
                                    </div>
                                </div>

                                {/* End Date */}
                                <div className="column is-half">
                                    <div className="field">
                                        <label className="label">End Date</label>
                                        <p className="control is-expanded has-background-light p-2">{booking.end_date}</p>
                                    </div>
                                </div>

                                {/* Number of People */}
                                <div className="column is-half">
                                    <div className="field">
                                        <label className="label">Number of People</label>
                                        <p className="control is-expanded has-background-light p-2">{booking.number_of_people}</p>
                                    </div>
                                </div>

                                {/* Email */}
                                <div className="column is-half">
                                    <div className="field">
                                        <label className="label">Email</label>
                                        <p className="control is-expanded has-background-light p-2">{booking.email}</p>
                                    </div>
                                </div>

                                {/* Phone Number */}
                                <div className="column is-half">
                                    <div className="field">
                                        <label className="label">Phone Number</label>
                                        <p className="control is-expanded has-background-light p-2">{booking.phone_number}</p>
                                    </div>
                                </div>

                                {/* Status */}
                                <div className="column is-half">
                                    <div className="field">
                                        <label className="label">Status</label>
                                        <p className="control is-expanded has-background-light p-2">{booking.status}</p>
                                    </div>
                                </div>

                                {/* Payment Status */}
                                <div className="column is-half">
                                    <div className="field">
                                        <label className="label">Payment Status</label>
                                        <p className="control is-expanded has-background-light p-2">{booking.payment_id ? 'PAID' : 'NOT PAID'}</p>
                                    </div>
                                </div>

                                {/* Comment */}
                                {booking.comment && (
                                    <div className="column is-half">
                                        <div className="field">
                                            <label className="label">Remarks</label>
                                            <p className="control is-expanded has-background-light p-2">{booking.comment}</p>
                                        </div>
                                    </div>
                                )}

                                {/* Special Requests */}
                                <div className="column is-full">
                                    <div className="field">
                                        <label className="label">Special Requests</label>
                                        <p className="control is-expanded has-background-light p-2">{booking.special_requests || "None"}</p>
                                    </div>
                                </div>

                                {/* Optional Fields */}
                                {booking.amount_paid && (
                                    <div className="column is-half">
                                        <div className="field">
                                            <label className="label">Amount Paid</label>
                                            <p className="control is-expanded has-background-light p-2">{booking.amount_paid}</p>
                                        </div>
                                    </div>
                                )}

                                {booking.payment_reference && (
                                    <div className="column is-half">
                                        <div className="field">
                                            <label className="label">Payment Reference</label>
                                            <p className="control is-expanded has-background-light p-2">{booking.payment_reference}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        !error && !loading && <p className="has-text-centered is-size-5">No booking details available.</p>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default PrayerCenterBookingView;
