import React, { useEffect, useState, useCallback } from "react";
import { API_URL } from "../../config";
import Notification from "../../components/Notification";
import { allowedToView } from "../../utils/PermissionUtils";

function Chapters() {
    const [chapters, setChapters] = useState([]);
    const [newChapter, setNewChapter] = useState({ name: "", description: "", chair_id: "" });
    const [editChapter, setEditChapter] = useState(null);
    const [notification, setNotification] = useState({ type: "", message: "" });
    const [errors, setErrors] = useState({}); // State for input errors

    const token = localStorage.getItem("access_token");
    const userRole = localStorage.getItem("userRole");

    const canEdit = (userRole) => {
        return (allowedToView(userRole, ['admin', 'superadmin', 'missions-coordinator']));
    };

    const fetchChapters = useCallback(async () => {
        try {
            const response = await fetch(`${API_URL}/chapters`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });
            const data = await response.json();
            setChapters(data);
        } catch (error) {
            console.error("There was an error fetching the chapters!", error);
            setNotification({ type: "danger", message: "Failed to fetch chapters." });
        }
    }, [token]);

    useEffect(() => {
        fetchChapters();
    }, [fetchChapters]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({}); // Clear previous errors

        try {
            const response = editChapter
                ? await updateChapter(editChapter.id, newChapter)
                : await createChapter(newChapter);

            if (!response.ok) {
                const errorData = await response.json();
                setErrors(errorData || {});
                return;
            }

            const message = editChapter
                ? "Chapter updated successfully."
                : "Chapter added successfully.";

            setNotification({ type: "success", message });
            setNewChapter({ name: "", description: "", chair_id: "" });
            setEditChapter(null);
            fetchChapters();
        } catch (error) {
            console.error("There was an error submitting the form!", error);
            setNotification({ type: "danger", message: "Failed to submit the chapter." });
        }
    };

    const createChapter = async (chapterData) => {
        const response = await fetch(`${API_URL}/chapters`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify(chapterData),
        });
        return response;
    };

    const updateChapter = async (id, chapterData) => {
        const response = await fetch(`${API_URL}/chapters/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify(chapterData),
        });
        return response;
    };

    const handleEdit = (chapter) => {
        setNewChapter({ name: chapter.name, description: chapter.description, chair_id: chapter.chair_id });
        setEditChapter(chapter);
    };

    const handleDelete = async (id) => {
        try {
            await fetch(`${API_URL}/chapters/${id}`, {
                method: "DELETE",
                headers: {
                    "Authorization": `Bearer ${token}`,
                },
            });
            fetchChapters();
            setNotification({ type: "success", message: "Chapter deleted successfully." });
        } catch (error) {
            console.error("There was an error deleting the chapter!", error);
            setNotification({ type: "danger", message: "Failed to delete the chapter." });
        }
    };

    const closeNotification = () => {
        setNotification({ type: "", message: "" });
    };

    return (
        <main>
            <nav className="level">
                <div className="level-left">
                    <div className="level-item">
                        <p className="title">Chapters</p>
                    </div>
                </div>

                <div className="level-right">
                    
                </div>
            </nav>

            <div className="columns">
                <div className={`column  ${canEdit(userRole) ? 'is-half' : 'is-fullwidth'} dash-section vertical-separator`}>
                    <h1 className="section-title">Chapters</h1>
                    <table className="table is-striped is-fullwidth">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Chair ID</th>
                                {canEdit(userRole) && (
                                    <th>Options</th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {chapters.map((chapter, index) => (
                                <tr key={chapter.id}>
                                    <td>{index + 1}</td>
                                    <td>{chapter.name}</td>
                                    <td>{chapter.description}</td>
                                    <td>{chapter.chair_id}</td>
                                    {canEdit(userRole) && (
                                        <td>
                                            <div className="dropdown is-right">
                                                <div className="dropdown-trigger">
                                                    <button
                                                        className="button is-white"
                                                        aria-haspopup="true"
                                                        aria-controls="dropdown-menu"
                                                        onClick={() => handleEdit(chapter)}
                                                    >
                                                        <span className="icon">
                                                            <i className="fas fa-edit"></i>
                                                        </span>
                                                    </button>
                                                    <button
                                                        className="button is-white"
                                                        aria-haspopup="true"
                                                        aria-controls="dropdown-menu"
                                                        onClick={() => handleDelete(chapter.id)}
                                                    >
                                                        <span className="icon">
                                                            <i className="fas fa-trash-alt"></i>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {canEdit(userRole) && (
                    <div className="column is-half dash-section">
                        <h1 className="section-title">{editChapter ? "Edit Chapter" : "Add New Chapter"}</h1>

                        {notification.message && (
                            <Notification
                                type={notification.type}
                                message={notification.message}
                                onClose={closeNotification}
                            />
                        )}

                        <form onSubmit={handleSubmit}>
                            <div className="field">
                                <label className="label">Name</label>
                                <div className="control has-icons-left has-icons-right">
                                    <input
                                        className={`input ${errors.name ? "is-danger" : ""}`}
                                        type="text"
                                        placeholder="e.g Nyeri"
                                        value={newChapter.name}
                                        onChange={(e) => setNewChapter({ ...newChapter, name: e.target.value })}
                                        required
                                    />
                                    <span className="icon is-small is-left">
                                        <i className="fas fa-book"></i>
                                    </span>
                                </div>
                                {errors.name && errors.name.map((error, index) => (
                                    <p key={index} className="help is-danger">{error}</p>
                                ))}
                            </div>

                            <div className="field">
                                <label className="label">Description</label>
                                <div className="control">
                                    <textarea
                                        className={`textarea ${errors.description ? "is-danger" : ""}`}
                                        placeholder="Description"
                                        value={newChapter.description}
                                        onChange={(e) => setNewChapter({ ...newChapter, description: e.target.value })}
                                    />
                                </div>
                                {errors.description && errors.description.map((error, index) => (
                                    <p key={index} className="help is-danger">{error}</p>
                                ))}
                            </div>

                            <div className="field">
                                <label className="label">Chair ID</label>
                                <div className="control has-icons-left">
                                    <input
                                        className={`input ${errors.chair_id ? "is-danger" : ""}`}
                                        type="number"
                                        placeholder="Chair ID"
                                        value={newChapter.chair_id}
                                        onChange={(e) => setNewChapter({ ...newChapter, chair_id: e.target.value })}
                                    />
                                    <span className="icon is-small is-left">
                                        <i className="fas fa-user-tie"></i>
                                    </span>
                                </div>
                                {errors.chair_id && errors.chair_id.map((error, index) => (
                                    <p key={index} className="help is-danger">{error}</p>
                                ))}
                            </div>

                            <div className="field">
                                <div className="control">
                                    <button type="submit" className="button primary-bg">{editChapter ? "Update" : "Add New"}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </main>
    );
}

export default Chapters;
