import React from "react";
import PropTypes from "prop-types";

const ConfirmDialog = ({
    isOpen,
    theme,
    title,
    message,
    onConfirm,
    onCancel
}) => {
    if (!isOpen) return null;

    return (
        <div className={`modal ${isOpen ? "is-active" : ""}`}>
            <div className="modal-background" onClick={onCancel}></div>
            <div className="modal-card">
                <header className={`modal-card-head ${theme}`}>
                    <p className="modal-card-title">{title}</p>
                    <button className="delete" aria-label="close" onClick={onCancel}></button>
                </header>
                <section className="modal-card-body">
                    <p>{message}</p>
                </section>
                <footer className="modal-card-foot">
                    <button className={`button ${theme}`} onClick={onConfirm}>
                        Confirm
                    </button>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <button className="button" onClick={onCancel}>
                        Cancel
                    </button>
                </footer>
            </div>
        </div>
    );
};

ConfirmDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    theme: PropTypes.string, // For dynamic theme like 'is-danger', 'is-warning', etc.
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

ConfirmDialog.defaultProps = {
    theme: "is-danger", // Default to 'is-danger' (danger theme)
};

export default ConfirmDialog;
