import Echo from "laravel-echo";
import { WEBSOCKET_URL } from "../config";
window.Pusher = require("pusher-js");

let echoInstance = null; // Store the instance to prevent multiple initializations

export const initializeEcho = () => {
    if (!echoInstance) {
        echoInstance = new Echo({
            broadcaster: "pusher",
            key: "local", // Match your .env PUSHER_APP_KEY
            cluster: "mt1", // Match your .env PUSHER_APP_CLUSTER
            wsHost: WEBSOCKET_URL, // WebSocket host  
            wsPort: 6001,        // WebSocket port (matches Laravel WebSocket server)
            forceTLS: false,     // No TLS for local dev
            disableStats: true,  // Disable Pusher stats
        });
    }
    return echoInstance;
};
