import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Notification from "../components/Notification";
import { API_URL } from "../config";
import '../styles/SignIn.css';

function LogIn() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [notification, setNotification] = useState({ type: "", message: "", isVisible: false });
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    // Validation states
    const [errors, setErrors] = useState({
        email: "",
        password: ""
    });

    const validate = () => {
        let isValid = true;
        const errors = {};

        if (!email) {
            errors.email = "Email is required.";
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = "Email is invalid.";
            isValid = false;
        }

        if (!password) {
            errors.password = "Password is required.";
            isValid = false;
        }

        setErrors(errors);
        return isValid;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        if (validate()) {
            try {
                const response = await fetch(API_URL+ "/auth/login", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        email: email,
                        password: password
                    }),
                });

                const data = await response.json();

                if (response.ok) {
                    // Save the token to localStorage
                    if (data.token) {
                        localStorage.setItem("access_token", data.token);
                        // After successful login, store user data
                        localStorage.setItem('user', JSON.stringify(data.user));
                        localStorage.setItem('userRole', data.role);

                        setNotification({ type: "success", message: "Login successful! Redirecting ...", isVisible: true });
                        setTimeout(() => {
                            navigate("/dashboard");
                        }, 2000);
                    } else if (data.temp_token) {
                        localStorage.setItem("temp_token", data.temp_token);
                        setNotification({ type: "danger", message: data.error || "Login failed.", isVisible: true });
                        setTimeout(() => {
                            navigate("/signup/more-details");
                        }, 13000);

                    } else if (data.error) {
                        setNotification({ type: "danger", message: data.error || "Login failed.", isVisible: true });
                    } else {
                        setNotification({ type: "danger", message: "Login failed.", isVisible: true });
                    }
                    setEmail("")
                    setPassword("")
                    setLoading(false);
                } else {
                    setNotification({ type: "danger", message: data.error || "Login failed.", isVisible: true });
                    if (data.errors) {
                        setErrors({
                            ...errors,
                            ...data.errors
                        });
                    }
                    setLoading(false)
                }

            } catch (error) {
                console.log(error)
                setNotification({ type: "danger", message: "An error occurred. Please try again later.", isVisible: true });
                setLoading(false)
            }

        } else {
            setNotification({ type: "danger", message: "Please fix the errors in the form.", isVisible: true });
            setLoading(false)
        }
    };

    const handleCloseNotification = () => {
        setNotification({ ...notification, isVisible: false });
    };

    return (
        <div>
            <Header />
            <main>
                <div className="signin-page mb-4">
                    <div className="siginin-bg"></div>
                    <div className="signin-card">
                        <div className="signin-content">
                            <div className="media pb-4 mb-4 is-justify-content-space-between">
                                <div className="media-left">
                                    <Link to="/signup"><i className="fa fa-arrow-left"></i> &nbsp; To Sign Up</Link>
                                </div>
                                <div className="media-right">
                                    <Link to="/"><i className="fa-solid fa-x"></i></Link>
                                </div>
                            </div>
                            <div className="content">
                                <h1 className="sign-in-title">Sign In</h1>

                                <p>Login to your account</p>

                                {notification.isVisible && (
                                    <Notification
                                        type={notification.type}
                                        message={notification.message}
                                        onClose={handleCloseNotification}
                                    />
                                )}

                                <form onSubmit={handleSubmit}>
                                    <div className={`field ${errors.email ? 'is-danger' : ''}`}>
                                        <label className="label">Email</label>
                                        <div className="control has-icons-left has-icons-right">
                                            <input
                                                className={`input ${errors.email ? 'is-danger' : ''}`}
                                                type="email"
                                                placeholder="Email input"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            <span className="icon is-small is-left">
                                                <i className="fas fa-envelope"></i>
                                            </span>
                                            {errors.email && (
                                                <span className="icon is-small is-right">
                                                    <i className="fas fa-exclamation-triangle"></i>
                                                </span>
                                            )}
                                        </div>
                                        {errors.email && <p className="help is-danger">{errors.email}</p>}
                                    </div>

                                    <div className={`field ${errors.password ? 'is-danger' : ''}`}>
                                        <label className="label">Password</label>
                                        <div className="control has-icons-left has-icons-right">
                                            <input
                                                className={`input ${errors.password ? 'is-danger' : ''}`}
                                                type="password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                placeholder="Enter password"
                                            />
                                            <span className="icon is-small is-left">
                                                <i className="fas fa-lock"></i>
                                            </span>
                                        </div>
                                        {errors.password && <p className="help is-danger">{errors.password}</p>}
                                    </div>

                                    <div className="field">
                                        <div className="control">
                                            <button type="submit" className="button is-fullwidth primary-bg" disabled={loading}>
                                                {loading ? "Loading..." : "Sign In"}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                <div className="field mb-5 mt-4">
                                    <div className="control">
                                        <label>
                                            <Link to="/account/forgot-password"> I Forgot Password</Link>
                                        </label> <br />
                                        <label>
                                            Don't have an account? &nbsp;
                                            <Link to="/signup">Sign Up Here.</Link>
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default LogIn;
