import React, { useEffect, useState } from "react";
import Notification from "../../components/Notification"; // Assuming you have a notification component
import { useParams, useNavigate } from "react-router-dom";
import { API_URL } from "../../config";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

function MissionFeedbackUpdate() {
    const { missionId, feedbackId } = useParams();
    const [mission, setMission] = useState(null);
    const [feedback, setFeedback] = useState({
        no_of_converts: "",
        books_carried: "",
        cost_of_mission: "",
        token_amount_from_school: "",
        host_person_name: "",
        host_person_phone: "",
        prayer_items: "",
        school_needs: "",
        feedback_comments: "",
        no_of_missioners_present: "",
        names_of_missioners_present: ""
    });

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [notification, setNotification] = useState(null);
    const token = localStorage.getItem("access_token");
    const [showTooltip, setShowTooltip] = useState(false);
    const navigate = useNavigate();
    const sessionUser = JSON.parse(localStorage.getItem("user"));

    useEffect(() => {
        // Fetch mission details
        const fetchMission = async () => {
            try {
                const response = await fetch(`${API_URL}/missions/${missionId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setMission(data); // Set the mission data
                } else if (response.status === 404) {
                    setNotification({ type: "danger", message: "Mission not found." });
                } else if (response.status === 401) {
                    navigate("/logout");
                } else {
                    throw new Error("Failed to fetch mission.");
                }
            } catch (error) {
                setNotification({ type: "danger", message: "An error occurred while fetching the mission." });
            }
        };

        // Fetch feedback if feedbackId is present
        const fetchFeedback = async () => {
            if (feedbackId) {
                try {
                    const response = await fetch(`${API_URL}/missions/${missionId}/feedback`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    if (response.ok) {
                        const data = await response.json();
                        setFeedback(data[0]);
                        setIsEditing(true);
                    } else if (response.status === 404) {
                        setNotification({ type: "danger", message: "Mission feedback not found." });
                    } else if (response.status === 401) {
                        navigate("/logout");
                    } else {
                        throw new Error("Failed to fetch feedback.");
                    }
                } catch (error) {
                    setNotification({ type: "danger", message: "An error occurred while fetching the feedback." });
                }
            }
        };

        fetchMission();
        fetchFeedback();
    }, [missionId, feedbackId, navigate, token]);

    useEffect(() => {
        handlePermission()
    }, [feedback]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFeedback({ ...feedback, [name]: value });
    };

    const handlePermission = () => {
        if (sessionUser?.id && feedback?.feedback_by?.id) {
            if (sessionUser?.id !== feedback?.feedback_by?.id) {
                navigate(`/dashboard/missions/${missionId}/feedback/details`);
            }
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setErrors({});

        try {
            // Prepare the data to send in the request
            const requestData = { ...feedback }; // Use the feedback object as is

            // Remove any empty or null values from the requestData
            for (const key in requestData) {
                if (requestData[key] === null || requestData[key] === "") {
                    delete requestData[key]; // Remove empty or null fields
                }
            }

            const method = isEditing ? "PUT" : "POST";
            const url = `${API_URL}/missions/${missionId}/feedback/`;

            const response = await fetch(url, {
                method: method,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json", // Sending JSON data
                },
                body: JSON.stringify(requestData), // Convert the request data to JSON
            });

            if (response.ok) {
                setNotification({ message: "Feedback successfully saved.", type: "success" });
                navigate(`/dashboard/missions/${missionId}/feedback/details`);
            } else if (response.status === 401) {
                navigate("/logout");
            } else {
                const errorData = await response.json();
                setErrors(errorData || {});
                setNotification({ message: errorData.message || "An error occurred.", type: "danger" });
            }
        } catch (error) {
            setNotification({ message: "An error occurred while saving the feedback.", type: "danger" });
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <main className="container">
            <nav className="level">
                <div className="level-left">
                    <div className="level-item">
                        <p className="title">
                            {isEditing ? `Edit` : "Write"} Feeback for: <br />
                            <small>{mission?.name}</small>
                        </p>
                    </div>
                </div>
                <div className="level-right">
                    <p className="level-item">
                        <Link to={`/dashboard/missions/${mission?.id}/details`} className="button primary-bg">
                            <i className="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Back
                        </Link>
                        &nbsp;
                        &nbsp;
                        <a
                            href={`/dashboard/missions/${mission?.id}/details`}
                            className="button primary-bg"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <i className="fa fa-eye" aria-hidden="true"></i> &nbsp; View
                        </a>
                    </p>
                </div>
            </nav>
            {notification && <Notification type={notification.type} message={notification.message} />}

            <div className="box mb-6 pb-6">
                <form onSubmit={handleSubmit}>
                    <div className="columns">
                        <div className="column is-half">
                            <div className="field">
                                <label className="label">Mission Details</label>
                                <div className="control">
                                    <input
                                        className={`input`}
                                        type="text"
                                        name="missionName"
                                        value={mission?.name}
                                        readOnly
                                    />
                                    <small><a href={`/dashboard/missions/${mission?.id}/details`} target="_blank" className="light-primary-text-color" rel="noreferrer" ><i className="fas fa-eye"></i> View more mission details</a></small>
                                </div>
                            </div>
                            <div className="field mb-4">
                                <label className="label">School</label>
                                <div className="control">
                                    <input
                                        className={`input`}
                                        type="text"
                                        name="schoolName"
                                        value={mission?.school?.name}
                                        readOnly
                                    />
                                    <small><a href={`/dashboard/schools/${mission?.school?.id}/details`} target="_blank" className="light-primary-text-color" rel="noreferrer" ><i className="fas fa-eye"></i> View school details</a></small>
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Number of Converts</label>
                                <div className="control">
                                    <input
                                        className={`input ${errors.no_of_converts ? "is-danger" : ""}`}
                                        type="number"
                                        name="no_of_converts"
                                        min="0"
                                        value={feedback.no_of_converts}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                {errors.no_of_converts && <p className="help is-danger">{errors.no_of_converts}</p>}
                            </div>

                            <div className="field">
                                <label className="label">Feedback Comments</label>
                                <div className="control">
                                    <textarea
                                        className={`textarea ${errors.feedback_comments ? "is-danger" : ""}`}
                                        name="feedback_comments"
                                        value={feedback.feedback_comments}
                                        onChange={handleChange}
                                        placeholder="Enter your comments"
                                        required
                                    ></textarea>
                                </div>
                                {errors.feedback_comments && <p className="help is-danger">{errors.feedback_comments}</p>}
                            </div>


                            <div className="field">
                                <label className="label">Which books did you carry?</label>
                                <div className="control">
                                    <textarea
                                        className={`textarea ${errors.books_carried ? "is-danger" : ""}`}
                                        name="books_carried"
                                        value={feedback.books_carried}
                                        onChange={handleChange}
                                        rows="2"
                                        placeholder="Separate with commas"
                                    ></textarea>
                                    <small><a href={`/dashboard/books`} target="_blank" className="light-primary-text-color" rel="noreferrer"><i className="fas fa-eye"></i> View our books</a></small>
                                </div>
                                {errors.books_carried && <p className="help is-danger">{errors.books_carried}</p>}
                            </div>

                            <div className="field">
                                <label className="label">Cost Of Mission (Money spent by CFCM) </label>
                                <div className="control">
                                    <input
                                        className={`input ${errors.cost_of_mission ? "is-danger" : ""}`}
                                        type="number"
                                        min="0"
                                        name="cost_of_mission"
                                        value={feedback.cost_of_mission}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                {errors.cost_of_mission && <p className="help is-danger">{errors.cost_of_mission}</p>}
                            </div>

                            <div className="field">
                                <label className="label">
                                    How much money did the school give you?
                                    &nbsp;   &nbsp;
                                    <span
                                        onMouseEnter={() => setShowTooltip(true)}
                                        onMouseLeave={() => setShowTooltip(false)}
                                        style={{ position: "relative", cursor: "pointer" }}
                                    >
                                        <FontAwesomeIcon icon={faCircleInfo} />
                                        {showTooltip && (
                                            <div style={{
                                                position: "absolute",
                                                backgroundColor: "#f9f9f9",
                                                border: "1px solid #ccc",
                                                borderRadius: "5px",
                                                padding: "10px",
                                                zIndex: 1,
                                                marginTop: "5px",
                                                boxShadow: "0px 0px 5px rgba(0,0,0,0.2)"
                                            }}>
                                                Kindly send the amount received from the school to CFCM Mpesa Paybill Number 
                                            </div>
                                        )}
                                    </span>
                                </label>
                                <div className="control">
                                    <input
                                        className={`input ${errors.token_amount_from_school ? "is-danger" : ""}`}
                                        type="number"
                                        min="0"
                                        name="token_amount_from_school"
                                        value={feedback.token_amount_from_school}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                {errors.token_amount_from_school && <p className="help is-danger">{errors.token_amount_from_school}</p>}
                            </div>

                        </div>
                        <div className="column is-half">

                            <div className="field">
                                <label className="label">Host Person Name</label>
                                <div className="control">
                                    <input
                                        className={`input ${errors.host_person_name ? "is-danger" : ""}`}
                                        type="text"
                                        name="host_person_name"
                                        value={feedback.host_person_name}
                                        onChange={handleChange}
                                    />
                                </div>
                                {errors.host_person_name && <p className="help is-danger">{errors.host_person_name}</p>}
                            </div>

                            <div className="field">
                                <label className="label">Host Person Phone Number</label>
                                <div className="control">
                                    <input
                                        className={`input ${errors.host_person_phone ? "is-danger" : ""}`}
                                        type="text"
                                        name="host_person_phone"
                                        value={feedback.host_person_phone}
                                        onChange={handleChange}
                                    />
                                </div>
                                {errors.host_person_phone && <p className="help is-danger">{errors.host_person_phone}</p>}
                            </div>

                            <div className="field">
                                <label className="label">Which prayer items do you recommend for the school?</label>
                                <div className="control">
                                    <textarea
                                        className={`textarea ${errors.prayer_items ? "is-danger" : ""}`}
                                        name="prayer_items"
                                        value={feedback.prayer_items}
                                        onChange={handleChange}
                                        rows="4"
                                        placeholder="Separate with enter key"
                                        required
                                    ></textarea>
                                </div>
                                {errors.prayer_items && <p className="help is-danger">{errors.prayer_items}</p>}
                            </div>

                            <div className="field">
                                <label className="label">What needs does the school have?</label>
                                <div className="control">
                                    <textarea
                                        className={`textarea ${errors.school_needs ? "is-danger" : ""}`}
                                        name="school_needs"
                                        value={feedback.school_needs}
                                        onChange={handleChange}
                                        rows="4"
                                        placeholder="Separate with enter key"
                                    ></textarea>
                                </div>
                                {errors.school_needs && <p className="help is-danger">{errors.school_needs}</p>}
                            </div>

                            <div className="field">
                                <label className="label">Number of Missioners Present</label>
                                <div className="control">
                                    <input
                                        className={`input ${errors.no_of_missioners_present ? "is-danger" : ""}`}
                                        type="number"
                                        min="1"
                                        name="no_of_missioners_present"
                                        value={feedback.no_of_missioners_present}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                {errors.no_of_missioners_present && <p className="help is-danger">{errors.no_of_missioners_present}</p>}
                            </div>


                            <div className="field">
                                <label className="label">Names of Missioners Present <br />
                                    <small>* Separate names with enter key or commas</small>

                                </label>
                                <div className="control">
                                    <textarea
                                        className={`textarea ${errors.names_of_missioners_present ? "is-danger" : ""}`}
                                        name="names_of_missioners_present"
                                        value={feedback.names_of_missioners_present}
                                        onChange={handleChange}
                                        rows="4"
                                        placeholder="Separate with enter key or commas"
                                        required
                                    ></textarea>
                                </div>
                                {errors.schoolNeeds && <p className="help is-danger">{errors.names_of_missioners_present}</p>}
                            </div>

                            <div className="field mt-6">
                                <div className="control">
                                    <button className={`button primary-bg is-fullwidth ${loading ? "is-loading" : ""}`} type="submit">
                                        {isEditing ? "Update Feedback" : "Create Feedback"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </main>
    );
}

export default MissionFeedbackUpdate;
