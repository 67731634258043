import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home.js';
import Login from './pages/Login.js';
import DashboardLayout from './components/DashboardLayout.js';
import Schools from './pages/Dashboard/Schools.js';
import Users from './pages/Dashboard/Users.js';
import AboutUs from './pages/AboutUs.js';
import PublicEvents from './pages/PublicEvents.js';
import EventDetails from './pages/EventDetails.js';
import DashBoard from './pages/Dashboard/Dashboard.js';
import Events from './pages/Dashboard/Events.js';
import Missions from './pages/Dashboard/Missions.js';
import Books from './pages/Dashboard/Books.js';
import Zones from './pages/Dashboard/Zones.js';
import Settings from './pages/Dashboard/Settings.js';
import MissionTypes from './pages/Dashboard/MissionTypes.js';
import Chapters from './pages/Dashboard/Chapters.js';
import SchoolUpdate from './pages/Dashboard/SchoolUpdate.js';
import SchoolView from './pages/Dashboard/SchoolView.js';
import EventUpdate from './pages/Dashboard/EventUpdate.js';
import EventView from './pages/Dashboard/EventView.js';
import MissionUpdate from './pages/Dashboard/MissionUpdate.js';
import MissionView from './pages/Dashboard/MissionView.js';
import Profile from './pages/Dashboard/Profile.js';
import ProfileUpdate from './pages/Dashboard/ProfileUpdate.js';
import UserView from './pages/Dashboard/UserView.js';
import LogOut from './components/LogOut.js';
import EventRegistrationList from './pages/Dashboard/EventRegistrations.js';
import PrivacyPolicy from './pages/PrivacyPolicy.js';
import TermsAndConditions from './pages/TermsAndConditions.js';
import Roles from './pages/Dashboard/Roles.js';
import Role from './pages/Dashboard/Role.js';
import ProtectedRoute from './utils/ProtectedRoute.js';
import UnAuthorised from './pages/UnAuthorized.js';
import MissionFeedbackUpdate from './pages/Dashboard/MissionFeedbackUpdate.js';
import MissionFeedbackView from './pages/Dashboard/MissionFeedbackView.js';
import PrayerCenterBooking from './pages/PrayerCenterBooking.js';
import PrayerCenterBookingView from './pages/PrayerCenterBookingView.js';
import BookingList from './pages/Dashboard/BookingList.js';
import BookingDetails from './pages/Dashboard/BookingDetails.js';
import ForgotPassword from './pages/ForgotPassword.js';
import EmailConfirmation from './pages/EmailConfirmation.js';
import MoreUserDetails from './pages/MoreUserDetails.js';
import PasswordReset from './pages/PasswordReset.js';
import Error404 from './pages/Error404.js';
import SignUp from './pages/SignUp.js';
import DashPasswordReset from './pages/Dashboard/DashPasswordReset.js';

function App() {
  return (
    <Router>
      <Routes>
        {/* Unprotected routes */}
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/account/email-confirmation" element={<EmailConfirmation />} />
        <Route path="/account/forgot-password" element={<ForgotPassword />} />
        <Route path="/account/password-reset" element={<PasswordReset />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path='/signup/more-details' element={<MoreUserDetails />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/events" element={<PublicEvents />} />
        <Route path="/events/:id/:slug" element={<EventDetails />} />
        <Route path="/makuyu-prayer-center-booking" element={<PrayerCenterBooking />} />
        <Route path="/makuyu-prayer-center-booking/:bookingToken" element={<PrayerCenterBookingView />} />
        <Route path="/logout" element={<LogOut />} />
        <Route path="/un-authorized" element={<UnAuthorised />} />

        {/* Protected dashboard routes */}
        <Route path="/dashboard" element={<DashboardLayout />}>
          <Route
            path=""
            element={
              <ProtectedRoute allowedRoles={['user', 'admin', 'superadmin', 'chapters-coordinator', 'missions-coordinator', 'treasurer']}>
                <DashBoard />
              </ProtectedRoute>
            }
          />
          <Route
            path="members/:status"
            element={
              <ProtectedRoute allowedRoles={['admin', 'superadmin', 'chapters-coordinator', 'missions-coordinator', 'treasurer']}>
                <Users />
              </ProtectedRoute>
            }
          />
          <Route
            path="members/:id/details"
            element={
              <ProtectedRoute allowedRoles={['user', 'admin', 'superadmin', 'chapters-coordinator', 'missions-coordinator', 'treasurer']}>
                <UserView />
              </ProtectedRoute>
            }
          />
          <Route
            path="schools"
            element={
              <ProtectedRoute allowedRoles={['user', 'admin', 'superadmin', 'chapters-coordinator', 'missions-coordinator', 'treasurer']}>
                <Schools />
              </ProtectedRoute>
            }
          />
          <Route
            path="schools/add"
            element={
              <ProtectedRoute allowedRoles={['admin', 'superadmin', 'chapters-coordinator', 'missions-coordinator']}>
                <SchoolUpdate />
              </ProtectedRoute>
            }
          />
          <Route
            path="schools/:id/update"
            element={
              <ProtectedRoute allowedRoles={['admin', 'superadmin', 'chapters-coordinator', 'missions-coordinator']}>
                <SchoolUpdate />
              </ProtectedRoute>
            }
          />
          <Route
            path="schools/:id/details"
            element={
              <ProtectedRoute allowedRoles={['user', 'admin', 'superadmin', 'chapters-coordinator', 'missions-coordinator', 'treasurer']}>
                <SchoolView />
              </ProtectedRoute>
            }
          />
          <Route
            path="events"
            element={
              <ProtectedRoute allowedRoles={['user', 'admin', 'superadmin', 'chapters-coordinator', 'missions-coordinator', 'treasurer']}>
                <Events />
              </ProtectedRoute>
            }
          />
          <Route
            path="events/add"
            element={
              <ProtectedRoute allowedRoles={['admin', 'superadmin']}>
                <EventUpdate />
              </ProtectedRoute>
            }
          />
          <Route
            path="events/:id/update"
            element={
              <ProtectedRoute allowedRoles={['admin', 'superadmin']}>
                <EventUpdate />
              </ProtectedRoute>
            }
          />
          <Route
            path="events/:id/details"
            element={
              <ProtectedRoute allowedRoles={['user', 'admin', 'superadmin', 'chapters-coordinator', 'missions-coordinator', 'treasurer']}>
                <EventView />
              </ProtectedRoute>
            }
          />
          <Route
            path="events/:id/registration"
            element={
              <ProtectedRoute allowedRoles={['user', 'admin', 'superadmin', 'chapters-coordinator', 'missions-coordinator', 'treasurer']}>
                <EventRegistrationList />
              </ProtectedRoute>
            }
          />
          <Route
            path="missions"
            element={
              <ProtectedRoute allowedRoles={['user', 'admin', 'superadmin', 'chapters-coordinator', 'missions-coordinator', 'treasurer']}>
                <Missions />
              </ProtectedRoute>
            }
          />
          <Route
            path="missions/:id/update"
            element={
              <ProtectedRoute allowedRoles={['admin', 'missions-coordinator', 'superadmin']}>
                <MissionUpdate />
              </ProtectedRoute>
            }
          />
          <Route
            path="missions/add"
            element={
              <ProtectedRoute allowedRoles={['admin', 'missions-coordinator', 'superadmin']}>
                <MissionUpdate />
              </ProtectedRoute>
            }
          />
          <Route
            path="missions/:id/details"
            element={
              <ProtectedRoute allowedRoles={['user', 'admin', 'superadmin', 'chapters-coordinator', 'missions-coordinator', 'treasurer']}>
                <MissionView />
              </ProtectedRoute>
            }
          />
          <Route
            path="missions/:missionId/feedback"
            element={
              <ProtectedRoute allowedRoles={['user', 'admin', 'superadmin', 'chapters-coordinator', 'missions-coordinator', 'treasurer']}>
                <MissionFeedbackUpdate />
              </ProtectedRoute>
            } />
          <Route
            path="missions/:missionId/feedback/:feedbackId"
            element={
              <ProtectedRoute allowedRoles={['user', 'admin', 'superadmin', 'chapters-coordinator', 'missions-coordinator', 'treasurer']}>
                <MissionFeedbackUpdate />
              </ProtectedRoute>
            } />
          <Route
            path="missions/:missionId/feedback/details"
            element={
              <ProtectedRoute allowedRoles={['user', 'admin', 'superadmin', 'chapters-coordinator', 'missions-coordinator', 'treasurer']}>
                <MissionFeedbackView />
              </ProtectedRoute>
            } />
          <Route
            path="books"
            element={
              <ProtectedRoute allowedRoles={['user', 'admin', 'superadmin', 'chapters-coordinator', 'missions-coordinator', 'treasurer']}>
                <Books />
              </ProtectedRoute>
            }
          />
          <Route
            path="bookings"
            element={
              <ProtectedRoute allowedRoles={['admin', 'superadmin', 'chapters-coordinator', 'missions-coordinator', 'treasurer']}>
                <BookingList />
              </ProtectedRoute>
            }
          />

          <Route
            path="bookings/:id/details"
            element={
              <ProtectedRoute allowedRoles={['admin', 'superadmin', 'chapters-coordinator', 'missions-coordinator', 'treasurer']}>
                <BookingDetails />
              </ProtectedRoute>
            }
          />

          <Route
            path="zones"
            element={
              <ProtectedRoute allowedRoles={['user', 'admin', 'missions-coordinator', 'superadmin', 'chapters-coordinator']}>
                <Zones />
              </ProtectedRoute>
            }
          />
          <Route
            path="settings"
            element={
              <ProtectedRoute allowedRoles={['admin', 'superadmin']}>
                <Settings />
              </ProtectedRoute>
            }
          />
          <Route
            path="mission-types"
            element={
              <ProtectedRoute allowedRoles={['admin', 'missions-coordinator', 'superadmin']}>
                <MissionTypes />
              </ProtectedRoute>
            }
          />
          <Route
            path="chapters"
            element={
              <ProtectedRoute allowedRoles={['user', 'admin', 'missions-coordinator', 'superadmin', 'chapters-coordinator']}>
                <Chapters />
              </ProtectedRoute>
            }
          />
          <Route
            path="profile"
            element={
              <ProtectedRoute allowedRoles={['user', 'admin', 'superadmin', 'chapters-coordinator', 'missions-coordinator', 'treasurer']}>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="profile/update"
            element={
              <ProtectedRoute allowedRoles={['user', 'admin', 'superadmin', 'chapters-coordinator', 'missions-coordinator', 'treasurer']}>
                <ProfileUpdate />
              </ProtectedRoute>
            }
          />
          <Route
            path="profile/reset-password"
            element={
              <ProtectedRoute allowedRoles={['user', 'admin', 'superadmin', 'chapters-coordinator', 'missions-coordinator', 'treasurer']}>
                <DashPasswordReset />
              </ProtectedRoute>
            } />

          <Route
            path="roles"
            element={
              <ProtectedRoute allowedRoles={['superadmin']}>
                <Roles />
              </ProtectedRoute>
            }
          />
          <Route
            path="roles/:roleId"
            element={
              <ProtectedRoute allowedRoles={['superadmin']}>
                <Role />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="*" element={<Error404 />} />
      </Routes>
    </Router>
  );
}

export default App;
