import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Notification from "../../components/Notification";
import { API_URL } from "../../config";

function ProfileUpdate() {
    const [profile, setProfile] = useState({
        first_name: "",
        last_name: "",
        phone_number: "",
        chapter_id: "",
        zone_id: "",
        occupation: "",
        marital_status: "",
        born_again: "",
        year_born_again: "",
        place_of_worship: "",
        pastors_name: "",
        pastors_mobile_number: "",
        date_of_birth: "",
    });
    const [chapters, setChapters] = useState([]);
    const [zones, setZones] = useState([]);
    const [maritalStatuses, setMaritalStatuses] = useState([]);
    const [notification, setNotification] = useState({ type: "", message: "" });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const token = localStorage.getItem("access_token");

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch initial profile data
                const profileResponse = await fetch(`${API_URL}/profile`, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                if (profileResponse.ok) {
                    const profileData = await profileResponse.json();
                    setProfile(profileData);
                } else if (profileResponse.status === 401) {
                    navigate("/logout");
                } else {
                    throw new Error("Failed to fetch profile data.");
                }

                // Fetch chapters
                const fetchChapters = async () => {
                    try {
                        const response = await fetch(`${API_URL}/chapters`, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        });

                        if (response.ok) {
                            const data = await response.json();
                            setChapters(data || []);

                        } else if (response.status === 404) {
                            setNotification({ type: "danger", message: "Chapters not found." });
                        } else if (response.status === 401) {
                            navigate("/logout");
                        } else {
                            throw new Error("Failed to fetch chapters.");
                        }

                    } catch (error) {
                        console.error("Failed to fetch chapters", error);
                        setNotification({ type: "danger", message: "Failed to load chapters" });
                    }
                };

                // Fetch marital statuses
                const fetchMaritalStatuses = async () => {
                    try {
                        const response = await fetch(`${API_URL}/marital-statuses`, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        });
                        if (response.ok) {
                            const data = await response.json();
                            setMaritalStatuses(data.maritalStatuses || []);

                        } else if (response.status === 404) {
                            setNotification({ type: "danger", message: "Marital statuses not found." });
                        } else if (response.status === 401) {
                            navigate("/logout");
                        } else {
                            throw new Error("Failed to fetch marital statuses.");
                        }

                    } catch (error) {
                        console.error("Failed to fetch marital statuses", error);
                        setNotification({ type: "danger", message: "Failed to load marital statuses" });
                    }
                };

                fetchChapters();
                fetchMaritalStatuses();
            } catch (error) {
                setNotification({ type: "danger", message: error.message });
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [navigate, token]);

    useEffect(() => {
        const fetchZones = async () => {
            if (profile.chapter_id) {
                try {
                    const response = await fetch(`${API_URL}/chapters/${profile.chapter_id}/zones`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });

                    if (response.ok) {
                        const zonesData = await response.json();
                        setZones(zonesData);
                    } else {
                        throw new Error("Failed to fetch zones.");
                    }
                } catch (error) {
                    setNotification({ type: "danger", message: "Failed to load zones." });
                }
            }
        };

        fetchZones();
    }, [profile.chapter_id, token]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setProfile((prevProfile) => ({
            ...prevProfile,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrors({});

        if (profile.born_again === null || profile.born_again === 0) {
            profile.year_born_again = "";
        }

        try {
            // Use FormData to handle file upload and other fields
            const formData = new FormData();

            for (const key in profile) {
                if (profile[key] === null || profile[key] === "") {
                    formData.delete(key);
                } else {
                    formData.append(key, profile[key]);
                }
            }

            const response = await fetch(`${API_URL}/profile/update`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

            if (response.ok) {
                setNotification({ message: "Profile successfully saved.", type: "success" });
                navigate(`/dashboard/profile`);
            } else if (response.status === 401) {
                navigate("/logout");
            } else {
                const errorData = await response.json();
                setErrors(errorData || {});
                setNotification({ message: errorData.message || "An error occurred.", type: "danger" });
            }
            setLoading(false);
        } catch (error) {
            setNotification({ message: "An error occurred while saving the event.", type: "danger" });
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <main>
            <nav className="level">
                <div className="level-left">
                    <div className="level-item">
                        <p className="title">
                            Update Profile
                        </p>
                    </div>
                </div>
                <div className="level-right">
                    <p className="level-item">
                        <Link to="/dashboard/" className="button primary-bg">
                            <i className="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Back
                        </Link>
                        &nbsp;
                        &nbsp;
                        <a
                            href={`/dashboard/profile`}
                            className="button primary-bg"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <i className="fa fa-eye" aria-hidden="true"></i> &nbsp; View
                        </a>
                    </p>
                </div>
            </nav>
            <div className="container dash-section is-loading">
                {loading && (
                    <h1 className="title">Loading...</h1>
                )}
                {notification.message && (
                    <Notification type={notification.type} message={notification.message} onClose={() => setNotification({ type: "", message: "" })} />
                )}
                <form onSubmit={handleSubmit}>
                    <div className="columns">
                        <div className="column is-6">
                            <div className="field">
                                <label className="label">First Name</label>
                                <div className="control">
                                    <input className={`input ${errors.first_name ? "is-danger" : ""}`} type="text" name="first_name" value={profile.first_name} onChange={handleChange} />
                                </div>
                                {errors.first_name && <p className="help is-danger">{errors.first_name}</p>}
                            </div>

                            <div className="field">
                                <label className="label">Last Name</label>
                                <div className="control">
                                    <input className={`input ${errors.last_name ? "is-danger" : ""}`} type="text" name="last_name" value={profile.last_name} onChange={handleChange} />
                                </div>
                                {errors.last_name && <p className="help is-danger">{errors.last_name}</p>}
                            </div>

                            <div className="field">
                                <label className="label">Phone Number</label>
                                <div className="control">
                                    <input className={`input ${errors.phone_number ? "is-danger" : ""}`} type="text" name="phone_number" value={profile.phone_number || ""} onChange={handleChange} />
                                </div>
                                {errors.phone_number && <p className="help is-danger">{errors.phone_number}</p>}
                            </div>

                            <div className="field">
                                <label className="label">Chapter</label>
                                <div className="control">
                                    <div className="select is-fullwidth">
                                        <select name="chapter_id" value={profile.chapter_id || ""} onChange={handleChange}>
                                            <option value="">Select a Chapter</option>
                                            {chapters.map((chapter) => (
                                                <option key={chapter.id} value={chapter.id}>
                                                    {chapter.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                {errors.chapter_id && <p className="help is-danger">{errors.chapter_id}</p>}
                            </div>

                            <div className="field">
                                <label className="label">Zone</label>
                                <div className="control">
                                    <div className="select is-fullwidth">
                                        <select name="zone_id" value={profile.zone_id || ""} onChange={handleChange} disabled={!profile.chapter_id}>
                                            <option value="">Select a Zone</option>
                                            {zones.map((zone) => (
                                                <option key={zone.id} value={zone.id}>
                                                    {zone.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                {errors.zone_id && <p className="help is-danger">{errors.zone_id}</p>}
                            </div>

                            <div className="field">
                                <label className="label">Marital Status</label>
                                <div className="control ">
                                    <div className={`select is-fullwidth ${errors.marital_status ? "is-danger" : ""}`}>
                                        <select name="marital_status" value={profile.marital_status} onChange={handleChange}>
                                            <option value="">Select Marital Status</option>
                                            {maritalStatuses.map((status, index) => (
                                                <option key={index} value={status}>
                                                    {status}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                {errors.marital_status && <p className="help is-danger">{errors.marital_status}</p>}
                            </div>
                        </div>
                        <div className="column is-6">

                            <div className="columns">
                                <div className="column is-half">
                                    <div className="field">
                                        <label className="label">Born Again?</label>
                                        <div className="control">
                                            <div className="select is-fullwidth">
                                                <select
                                                    name="born_again"
                                                    value={profile.born_again ?? ""} // Use '??' to handle null or undefined values
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value="">Select an Option</option>
                                                    <option value="1">Yes</option>
                                                    <option value="0">No</option>
                                                </select>
                                            </div>
                                        </div>

                                        {errors.born_again && <p className="help is-danger">{errors.born_again}</p>}
                                    </div>
                                </div>
                                {/* Year Born Again */}
                                <div className="column is-half">
                                    {profile.born_again === 1 && (
                                        <div className="field">
                                            <label className="label">Year Born Again</label>
                                            <div className="control">
                                                <input className={`input ${errors.year_born_again ? "is-danger" : ""}`} type="number" min="1900" name="year_born_again" value={profile.year_born_again || ""} onChange={handleChange} />
                                            </div>
                                            {errors.year_born_again && <p className="help is-danger">{errors.year_born_again}</p>}
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Place of Worship */}
                            <div className="field">
                                <label className="label">Place of Worship</label>
                                <div className="control">
                                    <input className={`input ${errors.place_of_worship ? "is-danger" : ""}`} type="text" name="place_of_worship" value={profile.place_of_worship || ""} onChange={handleChange} />
                                </div>
                                {errors.place_of_worship && <p className="help is-danger">{errors.place_of_worship}</p>}
                            </div>

                            {/* Pastor's Name */}
                            <div className="field">
                                <label className="label">Pastor's Name</label>
                                <div className="control">
                                    <input className={`input ${errors.pastors_name ? "is-danger" : ""}`} type="text" name="pastors_name" value={profile.pastors_name || ""} onChange={handleChange} />
                                </div>
                                {errors.pastors_name && <p className="help is-danger">{errors.pastors_name}</p>}
                            </div>

                            {/* Pastor's Mobile Number */}
                            <div className="field">
                                <label className="label">Pastor's Mobile Number</label>
                                <div className="control">
                                    <input className={`input ${errors.pastors_mobile_number ? "is-danger" : ""}`} type="text" name="pastors_mobile_number" value={profile.pastors_mobile_number || ""} onChange={handleChange} />
                                </div>
                                {errors.pastors_mobile_number && <p className="help is-danger">{errors.pastors_mobile_number}</p>}
                            </div>

                            {/* Date of Birth */}
                            <div className="field">
                                <label className="label">Occupation</label>
                                <div className="control">
                                    <input className={`input ${errors.occupation ? "is-danger" : ""}`} type="text" name="occupation" value={profile.occupation || ""} onChange={handleChange} />
                                </div>
                                {errors.occupation && <p className="help is-danger">{errors.occupation}</p>}
                            </div>

                            {/* Date of Birth */}
                            <div className="field">
                                <label className="label">Date of Birth</label>
                                <div className="control">
                                    <input className={`input ${errors.date_of_birth ? "is-danger" : ""}`} type="date" name="date_of_birth" value={profile.date_of_birth || ""} onChange={handleChange} />
                                </div>
                                {errors.date_of_birth && <p className="help is-danger">{errors.date_of_birth}</p>}
                            </div>
                        </div>
                    </div>
                    <div className="columns mt-6 mb-5">
                        <div className="column is-half">

                        </div>
                        <div className="column is-half">
                            <button type="submit" className="button primary-bg is-fullwidth" disabled={loading}>
                                {loading ? "Loading..." : "Update Profile"}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </main>
    );
}

export default ProfileUpdate;
