import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';

function LogOut() {
    const navigate = useNavigate();

    useEffect(()=>{
        localStorage.removeItem("access_token");
        localStorage.removeItem("user");
        localStorage.removeItem("userRole");

        // Redirect the user to the login page
        navigate("/login");
    }, [navigate])
}

export default LogOut;
