import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Notification from "../components/Notification";
import '../styles/SignIn.css';
import { API_URL } from "../config";

function MoreUserDetails() {
    const [profile, setProfile] = useState({
        first_name: "",
        last_name: "",
        chapter_id: "",
        zone_id: "",
        occupation: "",
        marital_status: "",
        born_again: "",
        year_born_again: "",
        place_of_worship: "",
        phone_number: "",
        country_code: "254",
    });
    const [chapters, setChapters] = useState([]);
    const [zones, setZones] = useState([]);
    const [maritalStatuses, setMaritalStatuses] = useState([]);
    const [countries, setCountries] = useState([]);
    const [notification, setNotification] = useState({ type: "", message: "", isVisible: false });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const token = localStorage.getItem("temp_token");

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch initial profile data
                const profileResponse = await fetch(`${API_URL}/profile/more-details`, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                if (profileResponse.ok) {
                    const profileData = await profileResponse.json();
                    setProfile(profileData);
                } else if (profileResponse.status === 401) {
                    navigate("/logout");
                } else {
                    throw new Error("Failed to fetch profile data.");
                }

                // Fetch chapters
                const fetchChapters = async () => {
                    try {
                        const response = await fetch(`${API_URL}/chapters`, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        });

                        if (response.ok) {
                            const data = await response.json();
                            setChapters(data || []);

                        } else if (response.status === 404) {
                            setNotification({ type: "danger", message: "Chapters not found.", isVisible: true });
                        } else if (response.status === 401) {
                            navigate("/logout");
                        } else {
                            throw new Error("Failed to fetch chapters.");
                        }

                    } catch (error) {
                        console.error("Failed to fetch chapters", error);
                        setNotification({ type: "danger", message: "Failed to load chapters", isVisible: true });
                    }
                };

                // Fetch marital statuses
                const fetchMaritalStatuses = async () => {
                    try {
                        const response = await fetch(`${API_URL}/marital-statuses`, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        });
                        if (response.ok) {
                            const data = await response.json();
                            setMaritalStatuses(data.maritalStatuses || []);

                        } else if (response.status === 404) {
                            setNotification({ type: "danger", message: "Marital statuses not found.", isVisible: true });
                        } else if (response.status === 401) {
                            navigate("/logout");
                        } else {
                            throw new Error("Failed to fetch marital statuses.");
                        }

                    } catch (error) {
                        console.error("Failed to fetch marital statuses", error);
                        setNotification({ type: "danger", message: "Failed to load marital statuses", isVisible: true });
                    }
                };

                // Fetch county codes
                const fetchCountries = async () => {
                    try {
                        const response = await fetch(`${API_URL}/countries`, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        });
                        if (response.ok) {
                            const data = await response.json();
                            setCountries(data || []);

                            // Set default country code to "254" if not already set
                            if (!profile.country_code || profile.country_code === "") {
                                const defaultCountry = data.find((country) => country.code === "254");
                                if (defaultCountry) {
                                    setProfile((prevProfile) => ({
                                        ...prevProfile,
                                        country_code: defaultCountry.code,
                                        country_name: defaultCountry.name,
                                    }));
                                }
                            }

                        } else if (response.status === 404) {
                            setNotification({ type: "danger", message: "Countries not found.", isVisible: true });
                        } else if (response.status === 401) {
                            navigate("/logout");
                        } else {
                            throw new Error("Failed to fetch countries.");
                        }

                    } catch (error) {
                        console.error("Failed to fetch countries", error);
                        setNotification({ type: "danger", message: "Failed to load countries", isVisible: true });
                    }
                };

                fetchChapters();
                fetchMaritalStatuses();
                fetchCountries();
            } catch (error) {
                setNotification({ type: "danger", message: error.message, isVisible: true });
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [navigate, token]);

    useEffect(() => {
        const fetchZones = async () => {
            if (profile.chapter_id) {
                try {
                    const response = await fetch(`${API_URL}/chapters/${profile.chapter_id}/zones`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });

                    if (response.ok) {
                        const zonesData = await response.json();
                        setZones(zonesData);
                    } else {
                        throw new Error("Failed to fetch zones.");
                    }
                } catch (error) {
                    setNotification({ type: "danger", message: "Failed to load zones.", isVisible: true });
                }
            }
        };

        fetchZones();
    }, [profile.chapter_id, token]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setProfile((prevProfile) => ({
            ...prevProfile,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));
    };

    const handleCloseNotification = () => {
        setNotification({ ...notification, isVisible: false });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrors({});

        if (profile.born_again === null || profile.born_again === 0) {
            profile.year_born_again = "";
        }

        try {
            // Use FormData to handle file upload and other fields
            setErrors({});
            const formData = new FormData();

            for (const key in profile) {
                if (profile[key] === null || profile[key] === "") {
                    formData.delete(key);
                } else {
                    formData.append(key, profile[key]);
                }
            }

            const response = await fetch(`${API_URL}/profile/more-details`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

            if (response.ok) {

                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });

                setNotification({ message: "Profile successfully updated. Kindly wait for admin to approve your account.", type: "success", isVisible: true });
                setTimeout(() => {
                    navigate("/login");
                }, 30000);
            } else if (response.status === 401) {
                navigate("/logout");
            } else {
                const errorData = await response.json();
                setErrors(errorData || {});
                setNotification({ message: errorData.message || "An error occurred.", type: "danger", isVisible: true });
            }
            setLoading(false);
        } catch (error) {
            setNotification({ message: "An error occurred while saving the event.", type: "danger", isVisible: true });
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Header />
            <main>
                <div className="signin-page mb-4">
                    <div className="siginin-bg"></div>
                    <div className="signin-card">
                        <div className="signin-content">
                            <div className="media pb-4 mb-4 is-justify-content-space-between">
                                <div className="media-left">
                                    <Link to="/login"><i className="fa fa-arrow-left"></i> &nbsp; To Log In</Link>
                                </div>
                                <div className="media-right">
                                    <Link to="/"><i className="fa-solid fa-x"></i></Link>
                                </div>
                            </div>
                            <div className="content">
                                <h1 className="sign-in-title">More Details</h1>
                                <p className="notification is-danger">Fill the form below to assit the admin in the verification of your account. <br /> If you have fully filled the form, please wait for the admin to approve the account.</p>

                                {notification.isVisible && (
                                    <Notification
                                        type={notification.type}
                                        message={notification.message}
                                        onClose={handleCloseNotification}
                                    />
                                )}

                                <form onSubmit={handleSubmit}>
                                    {/* First Name */}
                                    <div className={`field ${errors.first_name ? 'is-danger' : ''}`}>
                                        <label className="label">First Name</label>
                                        <div className="control">
                                            <input
                                                className={`input ${errors.first_name ? 'is-danger' : ''}`}
                                                type="text"
                                                name="first_name"
                                                value={profile.first_name}
                                                onChange={handleChange}
                                                placeholder="Enter your first name"
                                            />
                                        </div>
                                        {errors.first_name && <p className="help is-danger">{errors.first_name}</p>}
                                    </div>

                                    {/* Last Name */}
                                    <div className={`field ${errors.last_name ? 'is-danger' : ''}`}>
                                        <label className="label">Last Name</label>
                                        <div className="control">
                                            <input
                                                className={`input ${errors.last_name ? 'is-danger' : ''}`}
                                                type="text"
                                                name="last_name"
                                                value={profile.last_name}
                                                onChange={handleChange}
                                                placeholder="Enter your last name"
                                            />
                                        </div>
                                        {errors.last_name && <p className="help is-danger">{errors.last_name}</p>}
                                    </div>

                                    {/* Phone Number with Country Code */}
                                    <div className={`field ${errors.phone_number ? "is-danger" : ""}`}>
                                        <label className="label">Phone Number</label>
                                        <div className="control phone-number-container">
                                            {/* Country Code Dropdown */}
                                            <div className="select country-code-dropdown">
                                                <select
                                                    name="country_code"
                                                    value={profile.country_code} // Value from the state or user data
                                                    onChange={(e) => {
                                                        const selectedCountry = countries.find(
                                                            (country) => country.code === e.target.value
                                                        );
                                                        setProfile({
                                                            ...profile,
                                                            country_code: selectedCountry.code,
                                                            country_name: selectedCountry.name, // Save the country name as well
                                                        });
                                                    }}
                                                    required
                                                >
                                                    <option value="" disabled>
                                                        Country Code
                                                    </option>
                                                    {countries.map((country) => (
                                                        <option key={country.iso} value={country.code}>
                                                            {country.name} (+{country.code})
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            {/* Phone Number Input */}
                                            <input
                                                className={`input phone-number-input ${errors.phone_number ? "is-danger" : ""}`}
                                                type="text"
                                                name="phone_number"
                                                value={profile.phone_number}
                                                onChange={(e) => setProfile({ ...profile, phone_number: e.target.value })}
                                                placeholder="Enter your phone number"
                                                required
                                            />
                                        </div>
                                        {errors.phone_number && <p className="help is-danger">{errors.phone_number}</p>}
                                    </div>

                                    {/* Chapter */}
                                    <div className={`field ${errors.chapter_id ? 'is-danger' : ''}`}>
                                        <label className="label">Chapter</label>
                                        <div className="control">
                                            <div className="select">
                                                <select
                                                    name="chapter_id"
                                                    value={profile.chapter_id}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">Select a chapter</option>
                                                    {chapters.map((chapter) => (
                                                        <option key={chapter.id} value={chapter.id}>
                                                            {chapter.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        {errors.chapter_id && <p className="help is-danger">{errors.chapter_id}</p>}
                                    </div>

                                    {/* Zone */}
                                    <div className={`field ${errors.zone_id ? 'is-danger' : ''}`}>
                                        <label className="label">Zone</label>
                                        <div className="control">
                                            <div className="select">
                                                <select
                                                    name="zone_id"
                                                    value={profile.zone_id}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">Select a zone</option>
                                                    {zones.map((zone) => (
                                                        <option key={zone.id} value={zone.id}>
                                                            {zone.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        {errors.zone_id && <p className="help is-danger">{errors.zone_id}</p>}
                                    </div>

                                    {/* Occupation */}
                                    <div className={`field ${errors.occupation ? 'is-danger' : ''}`}>
                                        <label className="label">Occupation</label>
                                        <div className="control">
                                            <input
                                                className={`input ${errors.occupation ? 'is-danger' : ''}`}
                                                type="text"
                                                name="occupation"
                                                value={profile.occupation}
                                                onChange={handleChange}
                                                placeholder="e.g Nurse"
                                            />
                                        </div>
                                        {errors.occupation && <p className="help is-danger">{errors.occupation}</p>}
                                    </div>

                                    {/* Marital Status */}
                                    <div className="field">
                                        <label className="label">Marital Status</label>
                                        <div className="control ">
                                            <div className={`select is-fullwidth ${errors.marital_status ? "is-danger" : ""}`}>
                                                <select name="marital_status" value={profile.marital_status} onChange={handleChange}>
                                                    <option value="">Select Marital Status</option>
                                                    {maritalStatuses.map((status, index) => (
                                                        <option key={index} value={status}>
                                                            {status}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        {errors.marital_status && <p className="help is-danger">{errors.marital_status}</p>}
                                    </div>

                                    <div className="column is-half">
                                        <div className={`field ${errors.born_again ? 'is-danger' : ''}`}>
                                            <label className="label">Born Again?</label>
                                            <div className="control">
                                                <div className="select is-fullwidth">
                                                    <select
                                                        name="born_again"
                                                        value={profile.born_again ?? ""} // Use '??' to handle null or undefined values
                                                        onChange={handleChange}
                                                        required
                                                    >
                                                        <option value="">Select an Option</option>
                                                        <option value="1">Yes</option>
                                                        <option value="0">No</option>
                                                    </select>
                                                </div>
                                            </div>

                                            {errors.born_again && <p className="help is-danger">{errors.born_again}</p>}
                                        </div>
                                    </div>

                                    {/* Year Born Again */}
                                    {profile.born_again === "1" && (
                                        <div className={`field ${errors.year_born_again ? 'is-danger' : ''}`}>
                                            <label className="label">Year Born Again</label>
                                            <div className="control">
                                                <input
                                                    className={`input ${errors.year_born_again ? 'is-danger' : ''}`}
                                                    type="number"
                                                    name="year_born_again"
                                                    value={profile.year_born_again}
                                                    onChange={handleChange}
                                                    placeholder="Enter the year"
                                                    min="1950"
                                                />
                                            </div>
                                            {errors.year_born_again && <p className="help is-danger">{errors.year_born_again}</p>}
                                        </div>
                                    )}

                                    {/* Place of Worship */}
                                    <div className={`field ${errors.place_of_worship ? 'is-danger' : ''}`}>
                                        <label className="label">Place of Worship</label>
                                        <div className="control">
                                            <input
                                                className={`input ${errors.place_of_worship ? 'is-danger' : ''}`}
                                                type="text"
                                                name="place_of_worship"
                                                value={profile.place_of_worship}
                                                onChange={handleChange}
                                                placeholder="e.g Deliverance Church, Eastern Bypass"
                                            />
                                        </div>
                                        {errors.place_of_worship && <p className="help is-danger">{errors.place_of_worship}</p>}
                                    </div>

                                    {/* Submit Button */}
                                    <div className="field mt-5">
                                        <div className="control ">
                                            <button
                                                type="submit"
                                                className="button primary-bg is-fullwidth"
                                                disabled={loading}
                                            >
                                                {loading ? "Submitting..." : "Save"}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default MoreUserDetails;
