import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Logo from '../../src/assets/images/logo600px.png';

function DashboardHeader() {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const [isMenuActive, setIsMenuActive] = useState(false);
    const [isDropdownActive, setIsDropdownActive] = useState(false);
    const toggleDropDownRef = useRef(null);

    useEffect(() => {
        // Check if user is logged in by fetching user data from localStorage or sessionStorage
        const loggedInUser = localStorage.getItem('user');
        if (loggedInUser) {
            setUser(JSON.parse(loggedInUser));
        } else {
            navigate('/login');
        }
    }, [navigate]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (

                toggleDropDownRef.current &&
                !toggleDropDownRef.current.contains(event.target) // Exclude toggle button
            ) {
                setIsDropdownActive(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    const toggleMenu = () => {
        setIsMenuActive(!isMenuActive);
    };

    const toggleDropdown = () => {
        setIsDropdownActive(!isDropdownActive);
    };

    return (
        <nav className="dash-navbar navbar" role="navigation" aria-label="dash navigation">
            <div className="navbar-brand">
                <Link className="logo-link" to="/dashboard" id="logoLink">
                    <img src={Logo} className="dash-logo" id="dashlogo" alt="CFCM MIS Logo" />
                </Link>

                <button
                    className={`hamburger ${isMenuActive ? 'is-active' : ''}`}
                    aria-label="menu"
                    aria-expanded={isMenuActive}
                    onClick={toggleMenu}
                >
                    <span className="hamburger-line"></span>
                    <span className="hamburger-line"></span>
                    <span className="hamburger-line"></span>
                </button>
            </div>
            <div className={`navbar-end ${isMenuActive ? 'is-active' : ''}`}>
                <div
                    className={`navbar-item has-dropdown ${isDropdownActive ? 'is-active' : ''}`}
                    onClick={toggleDropdown} ref={toggleDropDownRef}
                >
                    <Link className="navbar-link" to="#">
                        <figure className="image is-30x30 dash-profile-image">
                            <img className="is-rounded" src="https://bulma.io/assets/images/placeholders/128x128.png" alt="" />
                        </figure> {user?.first_name} {user?.last_name}
                    </Link>

                    <div className="navbar-dropdown">
                        <Link to="/dashboard/profile" className="navbar-item">
                            Profile
                        </Link>
                        <Link to="/" className="navbar-item">
                            Home
                        </Link>
                        <hr className="navbar-divider" />
                        <Link to="/dashboard/profile/reset-password" className="navbar-item">
                            Reset Password
                        </Link>
                        <Link to="/logout" className="navbar-item">
                            Log Out
                        </Link>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default DashboardHeader;
