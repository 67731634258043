import React, { useEffect, useState } from "react";
import UsersDropdownField from "../../components/UsersDropdownField";
import Notification from "../../components/Notification"; // Assuming you have a notification component
import { useParams, useNavigate } from "react-router-dom";
import { API_URL } from "../../config";
import SchoolsDropdownField from "../../components/SchoolDropdownField";
import { Link } from "react-router-dom";

function MissionUpdate() {
    const { id } = useParams();
    const [mission, setMission] = useState({
        name: "",
        mission_type_id: "",
        start_date: "",
        end_date: "",
        start_time: "",
        end_time: "",
        school_id: "",
        theme: "",
        prayer_items: "",
        missioners_required: "",
        need_disciple_books: "",
        mission_leader_id: "",
        description: "",
    });

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [notification, setNotification] = useState(null);
    const token = localStorage.getItem("access_token");
    const [allUsers, setAllUsers] = useState([]);
    const [allSchools, setAllSchools] = useState([]);
    const [missionTypes, setMissionTypes] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        // If editing, fetch the existing mission details
        const fetchMission = async () => {
            if (id) {
                try {
                    const response = await fetch(`${API_URL}/missions/${id}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    if (response.ok) {
                        const data = await response.json();
                        setMission(data);
                        setIsEditing(true);
                    } else if (response.status === 404) {
                        setNotification({ type: "danger", message: "Mission not found." });
                    } else if (response.status === 401) {
                        navigate("/logout");
                    } else {
                        throw new Error("Failed to fetch mission.");
                    }
                } catch (error) {
                    setNotification({ type: "danger", message: "An error occurred while fetching the mission." });
                }
            }
        };

        const fetchAllUsers = async () => {
            try {
                const response = await fetch(`${API_URL}/users`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setAllUsers(data);
                } else if (response.status === 404) {
                    setNotification({ type: "danger", message: "Users not found." });
                } else if (response.status === 401) {
                    navigate("/logout");
                } else {
                    throw new Error("Failed to fetch users.");
                }

            } catch (error) {
                setNotification({ type: "danger", message: "An error occured while fetching users" });
            }
        };

        const fetchSchools = async () => {
            try {
                const response = await fetch(`${API_URL}/schools`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setAllSchools(data);
                } else if (response.status === 404) {
                    setNotification({ type: "danger", message: "Schools not found." });
                } else if (response.status === 401) {
                    navigate("/logout");
                } else {
                    throw new Error("Failed to fetch schools.");
                }

            } catch (error) {
                setNotification({ type: "danger", message: "An error occured while fetching schools" });
            }
        };

        const fetchMissionTypes = async () => {
            try {
                const response = await fetch(`${API_URL}/mission-types`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setMissionTypes(data);
                } else if (response.status === 404) {
                    setNotification({ type: "danger", message: "Mission types not found." });
                } else if (response.status === 401) {
                    navigate("/logout");
                } else {
                    throw new Error("Failed to fetch schools.");
                }

            } catch (error) {
                setNotification({ type: "danger", message: "An error occured while fetching schools" });
            }
        };

        const fetchData = async () => {
            await fetchMission();
            await fetchAllUsers();
            await fetchSchools();
            await fetchMissionTypes();
            setLoading(false);
        };

        fetchData();
    }, [id, navigate, token]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setMission({ ...mission, [name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        // Clear previous errors before starting validation
        setErrors({});

        const method = "POST";
        const url = isEditing ? `${API_URL}/missions/${id}/update` : `${API_URL}/missions`;

        try {
            // Use FormData to handle file upload and other fields
            const formData = new FormData();

            for (const key in mission) {
                if (mission[key] === null || mission[key] === "") {
                    formData.delete(key);
                } else {
                    formData.append(key, mission[key]);
                }
            }

            const response = await fetch(url, {
                method: method,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
                setNotification({ message: "Mission successfully saved.", type: "success" });
                if (data && data.id) {
                    navigate(`/dashboard/missions/${data.id}/details`);
                }
            } else if (response.status === 401) {
                navigate("/logout");
            } else {
                const errorData = await response.json();
                setErrors(errorData || {});
                setNotification({ message: errorData.message || "An error occurred.", type: "danger" });
            }
            setLoading(false);
        } catch (error) {
            setNotification({ message: "An error occurred while saving the mission.", type: "danger" });
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <main className="container">
            <nav className="level">
                <div className="level-left">
                    <div className="level-item">
                        <p className="title">
                            {isEditing ? `Update Mission '${mission.name}'` : "Create Mission"}
                        </p>
                    </div>
                </div>
                <div className="level-right">
                    <p className="level-item">
                        <Link to="/dashboard/missions" className="button primary-bg">
                            <i className="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Back
                        </Link>
                        &nbsp;
                        &nbsp;
                        <a
                            href={`/dashboard/missions/${mission.id}/details`}
                            className="button primary-bg"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <i className="fa fa-eye" aria-hidden="true"></i> &nbsp; View
                        </a>
                    </p>
                </div>
            </nav>
            {notification && <Notification type={notification.type} message={notification.message} />}
            <div className="box">
                <form onSubmit={handleSubmit}>
                    <div className="columns">
                        <div className="column is-half">
                            <div className="field">
                                <div className="control">
                                    <SchoolsDropdownField
                                        label="School Name"
                                        name="school_id"
                                        value={mission.school_id}
                                        onChange={handleChange}
                                        options={allSchools}
                                        errors={errors.school_id}
                                        placeholder="Select or search a school"
                                    />
                                </div>
                            </div>

                            <div className="field">
                                <label className="label">Mission Type</label>
                                <div className="control">
                                    <select
                                        name="mission_type_id"
                                        value={mission.mission_type_id}
                                        onChange={handleChange}
                                        className={`select control is-fullwidth ${errors.mission_type_id ? "is-danger" : ""}`}
                                        required
                                    >
                                        <option value="" disabled>
                                            Select Mission Type
                                        </option>
                                        {missionTypes.map((type) => (
                                            <option key={type.id} value={type.id}>
                                                {type.name}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.mission_type_id && (
                                        <p className="help is-danger">{errors.mission_type_id.join(", ")}</p>
                                    )}
                                </div>
                            </div>

                            <div className="columns">
                                <div className="column is-half">
                                    <div className="field">
                                        <label className="label">Start Date</label>
                                        <div className="control">
                                            <input
                                                type="date"
                                                name="start_date"
                                                value={mission.start_date}
                                                onChange={handleChange}
                                                className={`input ${errors.start_date ? 'is-danger' : ''}`}
                                                required
                                            />
                                            {errors.start_date && <p className="help is-danger">{errors.start_date.join(', ')}</p>}
                                        </div>
                                    </div>

                                </div>
                                <div className="column is-half">
                                    <div className="field">
                                        <label className="label">Start Time</label>
                                        <div className="control">
                                            <input
                                                type="time"
                                                name="start_time"
                                                value={mission.start_time}
                                                onChange={handleChange}
                                                className={`input ${errors.start_time ? 'is-danger' : ''}`}
                                            />
                                            {errors.start_time && <p className="help is-danger">{errors.start_time.join(', ')}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="columns">
                                <div className="column is-half">
                                    <div className="field">
                                        <label className="label">End Date</label>
                                        <div className="control">
                                            <input
                                                type="date"
                                                name="end_date"
                                                value={mission.end_date}
                                                onChange={handleChange}
                                                className={`input ${errors.end_date ? 'is-danger' : ''}`}
                                            />
                                            {errors.end_date && <p className="help is-danger">{errors.end_date.join(', ')}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className="column is-half">
                                    <div className="field">
                                        <label className="label">End Time</label>
                                        <div className="control">
                                            <input
                                                type="time"
                                                name="end_time"
                                                value={mission.end_time}
                                                onChange={handleChange}
                                                className={`input ${errors.end_time ? 'is-danger' : ''}`}
                                            />
                                            {errors.end_time && <p className="help is-danger">{errors.end_time.join(', ')}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="field">
                                <label className="label">Missioners Required</label>
                                <div className="control">
                                    <input
                                        type="number"
                                        min="1"
                                        name="missioners_required"
                                        value={mission.missioners_required}
                                        onChange={handleChange}
                                        className={`input ${errors.missioners_required ? 'is-danger' : ''}`}
                                        placeholder="Enter number of missioners required"
                                    />
                                    {errors.missioners_required && <p className="help is-danger">{errors.missioners_required.join(', ')}</p>}
                                </div>
                            </div>

                            <div className="field">
                                <label className="label">Theme</label>
                                <div className="control">
                                    <textarea
                                        name="theme"
                                        value={mission.theme}
                                        onChange={handleChange}
                                        className={`textarea ${errors.theme ? 'is-danger' : ''}`}
                                        placeholder="Enter mission theme"
                                    />
                                    {errors.theme && <p className="help is-danger">{errors.theme.join(', ')}</p>}
                                </div>
                            </div>
                        </div>
                        <div className="column is-half">
                            <div className="field">
                                <label className="label">Prayer Items</label>
                                <div className="control">
                                    <textarea
                                        name="prayer_items"
                                        value={mission.prayer_items}
                                        onChange={handleChange}
                                        className={`textarea ${errors.prayer_items ? 'is-danger' : ''}`}
                                        placeholder="Enter prayer items"
                                    />
                                    {errors.prayer_items && <p className="help is-danger">{errors.prayer_items.join(', ')}</p>}
                                </div>
                            </div>

                            <div className="field">
                                <label className="label">Need Disciple Books</label>
                                <div className="control">
                                    <select
                                        name="need_disciple_books"
                                        value={mission.need_disciple_books}
                                        onChange={handleChange}
                                        className={`select ${errors.need_disciple_books ? 'is-danger' : ''}`}
                                    >
                                        <option value="">Select</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                        <option value="Not Sure">Not Sure</option>
                                    </select>
                                    {errors.need_disciple_books && <p className="help is-danger">{errors.need_disciple_books.join(', ')}</p>}
                                </div>
                            </div>

                            <div className="field">
                                <div className="control">
                                    <UsersDropdownField
                                        label="Mission Leader"
                                        name="mission_leader_id"
                                        value={mission.mission_leader_id}
                                        onChange={handleChange}
                                        options={allUsers}
                                        errors={errors.mission_leader_id}
                                        placeholder="Select or search a mission leader..."
                                    />
                                </div>
                                {errors.mission_leader_id && errors.mission_leader_id.map((error, index) => (
                                    <p key={index} className="help is-danger">{error}</p>
                                ))}
                            </div>

                            <div className="field">
                                <label className="label">Description</label>
                                <div className="control">
                                    <textarea
                                        name="description"
                                        value={mission.description}
                                        onChange={handleChange}
                                        className={`textarea ${errors.description ? 'is-danger' : ''}`}
                                        placeholder="Enter description"
                                    />
                                    {errors.description && <p className="help is-danger">{errors.description.join(', ')}</p>}
                                </div>
                            </div>

                            <div className="field">
                                <div className="control mt-6 mb-6">
                                    <button className={`button primary-bg is-fullwidth ${loading ? 'is-loading' : ''}`} type="submit">
                                        {isEditing ? "Update Mission" : "Create Mission"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>




                </form>
            </div>
        </main>
    );
}

export default MissionUpdate;
