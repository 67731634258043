import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { API_URL } from "../../config.js";
import Notification from "../../components/Notification.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEnvelope, faPhone, faRing, faPrayingHands, faChurch, faUserTie,
    faMap,
    faKey,
    faRightFromBracket
} from '@fortawesome/free-solid-svg-icons';

function Profile() {
    const [user, setUser] = useState({
        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
        occupation: "",
        marital_status: "",
        born_again: null,
        year_born_again: "",
        place_of_worship: "",
        pastors_name: "",
        pastors_mobile_number: "",
        date_of_birth: "",
        status: "",
        role: { name: "" },
    });
    const [loading, setLoading] = useState(true);
    const [notification, setNotification] = useState({ type: "", message: "" });
    const navigate = useNavigate();
    const userRole = localStorage.getItem('userRole');

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await fetch(`${API_URL}/profile`, {  // Change URL as needed
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setUser(data);
                } else if (response.status === 404) {
                    setNotification({ type: "danger", message: "User profile not found." });
                } else if (response.status === 401) {
                    navigate("/logout");
                } else {
                    throw new Error("Failed to fetch user profile.");
                }
            } catch (error) {
                setNotification({ type: "danger", message: "An error occurred while fetching the user profile." });
            }
            setLoading(false);
        };

        fetchUserProfile();
    }, [navigate]);

    const closeNotification = () => {
        setNotification({ type: "", message: "" });
    };

    if (loading) return <p>Loading...</p>;

    return (
        <main>
            <nav className="level">
                <div className="level-left">
                    <div className="level-item">
                        <p className="title is-4">My Profile</p>
                    </div>
                </div>
                <div className="level-right">
                    <p className="level-item">
                        <Link to="/dashboard" className="button primary-bg">
                            <i className="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Back
                        </Link>
                        &nbsp; &nbsp;
                        <Link to={`/dashboard/profile/update`} className="button primary-bg">
                            <i className="fa fa-pencil" aria-hidden="true"></i> &nbsp; Edit
                        </Link>
                    </p>
                </div>
            </nav>

            {notification.message && (
                <Notification
                    type={notification.type}
                    message={notification.message}
                    onClose={closeNotification}
                />
            )}

            <div className="columns is-multiline mb-6">
                {/* User Info Section */}
                <div className="column is-12">
                    <div className="box has-text-centered">
                        <p className="title is-3">
                            {user.first_name} {user.last_name}
                        </p>
                        <p>
                            {userRole && (
                                <span className="tag is-info">{userRole.toUpperCase()}</span>
                            )}
                            &nbsp; &nbsp;
                            {user.status && (
                                <span className={`tag is-${user.status === "active" ? "success" : "danger"}`}>
                                    {user?.status?.toUpperCase()}
                                </span>
                            )}
                        </p>
                    </div>
                </div>

                {/* Contact Information */}
                <div className="column is-6">
                    <div className="box">
                        <p className="title is-5">
                            <FontAwesomeIcon icon={faEnvelope} /> &nbsp; Email
                        </p>
                        <p>{user.email || "No email available."}</p>

                        <p className="title is-5 mt-4">
                            <FontAwesomeIcon icon={faPhone} /> &nbsp; Phone
                        </p>
                        <p>{user.phone_number || "No phone number available."}</p>
                    </div>
                </div>

                {/* Personal Information */}
                <div className="column is-6">
                    <div className="box">
                        <p className="title is-5 mb-2">
                            <FontAwesomeIcon icon={faUserTie} /> &nbsp; Occupation
                        </p>
                        <p>{user.occupation || "Occupation not provided."}</p>

                        <p className="title is-5 mt-4 mb-2">
                            <FontAwesomeIcon icon={faRing} /> &nbsp; Marital Status
                        </p>
                        <p>{user.marital_status || "Not provided."}</p>
                    </div>
                </div>

                {/* Spiritual Information */}
                <div className="column is-6">
                    <div className="box">
                        <p className="title is-5 mb-2">
                            <FontAwesomeIcon icon={faChurch} /> &nbsp; Place of Worship
                        </p>
                        <p>{user.place_of_worship || "Not provided."}</p>

                        <p className="title is-5 mt-4 mb-2">
                            Pastor's Name
                        </p>
                        <p>{user.pastors_name || "Not provided."}</p>

                        <p className="title is-5 mt-4 mb-2">
                            Pastor's Phone Number
                        </p>
                        <p>{user.pastors_mobile_number || "Not provided."}</p>
                    </div>
                </div>

                <div className="column is-6">
                    <div className="box">
                        <p className="title is-5">
                            <FontAwesomeIcon icon={faPrayingHands} /> &nbsp; Born Again? {user.born_again ? "Yes" : "Unspecified"}
                            &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;
                            {!user.born_again && (
                                <small><Link to="/dashboard/profile/update"><i className="fas fa-pencil"></i> Edit</Link></small>
                            )}
                        </p>
                        {user.year_born_again && (
                            <p>Year Born Again: {user.year_born_again}</p>
                        )}
                    </div>
                    <div className="box">
                        <p className="title is-5">Date of Birth</p>
                        <p>{user.date_of_birth || "Not provided."}</p>
                    </div>
                </div>

                {/* Additional Information */}
                <div className="column is-6">
                    <div className="box">
                        <p className="title is-5 mb-2">
                            <FontAwesomeIcon icon={faMap} /> &nbsp; Chapter
                        </p>
                        <p>{user?.chapter?.name || "Not provided."}</p>

                        <p className="title is-5 mt-5 mb-2">
                            Zone
                        </p>
                        <p>{user?.zone?.name || "Not provided."}</p>
                    </div>
                </div>

                <div className="column is-6">
                    <div className="box">

                        <Link className="button is-outline" to='/dashboard/profile/reset-password'>
                            <FontAwesomeIcon icon={faKey} /> &nbsp; Reset Password
                        </Link>

                        <br /> <br />
                        <Link className="button is-outline" to="/logout">
                            <FontAwesomeIcon icon={faRightFromBracket} />  &nbsp; Log Out </Link>

                    </div>
                </div>
            </div>
        </main>
    );
}

export default Profile;
