import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";

function Error404() {
    const navigate = useNavigate();
    return (
        <div>
            <Header />
            <section className="hero is-fullheight has-background-light">
                <div className="hero-body has-text-centered">
                    <div className="container is-flex is-justify-content-center is-align-items-center" style={{ flexDirection: "column" }}>
                        <h1 className="title has-text-danger">
                            404 - Page Not Found
                        </h1>
                        <h2 className="subtitle">
                            Oops! The page you are looking for does not exist
                        </h2>
                        <p className="mt-4">
                            <img
                                src="https://images.unsplash.com/photo-1589533610925-4b5e9d2d9c20"
                                alt="404 Error"
                                style={{ width: "300px", borderRadius: "10px" }}
                            />

                        </p>
                        <p className="mt-5">
                            <button className="button primary-outline-button" onClick={() => window.history.go(-3)}>
                                🔙 Go Back
                            </button>

                            &nbsp; &nbsp;
                            OR

                            &nbsp; &nbsp;
                            <button className="button primary-outline-button" onClick={() => navigate('/')}>
                                🏠 Go Home
                            </button>
                        </p>
                        <p className="mt-4">
                            If you believe this is a mistake, please contact support.
                        </p>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Error404;
