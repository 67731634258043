import React from "react";
import DashboardHeader from "./DashboardHeader";
import Footer from "./Footer"
import Sidebar from "./Sidebar";
import { Outlet } from "react-router-dom";

function DashboardLayout() {
    return (
        <>

            <div className="columns">
                <div className="column is-2">
                    <Sidebar />
                </div>
                <div className="column is-10">
                    <DashboardHeader />
                    <main className="main dash-main">
                        <Outlet />
                    </main>

                </div>
            </div>
            <Footer />
        </>
    );
}

export default DashboardLayout;
