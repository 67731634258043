import {
    faCalendarAlt, faClock,
    faInfoCircle,
    faMapMarkerAlt,
    faPalette, faPrayingHands,
    faQuestionCircle,
    faUserFriends,
    faUserTie,
    faUsers,
    faPray,
    faComments
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ConfirmDialog from "../../components/ConfirmDialog";
import Notification from "../../components/Notification";
import { API_URL } from "../../config";
import { faCheck, faTimes, faBan, faPenFancy, faComment } from '@fortawesome/free-solid-svg-icons';
import { allowedToView } from "../../utils/PermissionUtils.js";

function MissionView() {
    const [mission, setMission] = useState({
        name: "",
        start_date: "",
        start_time: "",
        end_date: null,
        end_time: null,
        venue: "",
        google_map_pin: "",
        description: "",
        status: "",
        post_publicly: false,
        contact_person: null,
        created_by: { first_name: "", last_name: "", email: "", phone_number: "" },
        postponed_at: null,
        cancelled_at: null,
        reason_for_postponement: null,
        reason_for_cancel: null,
        feedback: {
            id: null,
            mission_id: null,
            no_of_converts: null,
            feedback_comments: null,
            prayer_items: null,
            feedback_by_id: null
        }
    });
    const [loading, setLoading] = useState(true);
    const [notification, setNotification] = useState({ type: "", message: "" });
    const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [hasRequestedToAttend, setHasRequestedToAttend] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(null);
    const [allRequests, setAllRequests] = useState([]);
    const [confirmAction, setConfirmAction] = useState(""); // Track the action type for the confirmation dialog
    const [selectedRequest, setSelectedRequest] = useState(null); // Track the selected request for the action
    const [confirmDialogTitle, setConfirmDialogTitle] = useState("");
    const [confirmDialogMessage, setConfirmDialogMessage] = useState("");
    const [myRequest, setMyRequest] = useState(null);
    const navigate = useNavigate();
    const { id } = useParams();
    const sessionUser = JSON.parse(localStorage.getItem("user"));
    const statusTagClasses = {
        requested: "tag is-info",
        approved: "tag is-success",
        cancelled: "tag is-dark",
        rejected: "tag is-danger"
    };

    const userRole = localStorage.getItem("userRole");
    const canEdit = (userRole) => {
        return (allowedToView(userRole, ['admin', 'superadmin', 'missions-coordinator']));
    };

    const canViewRequest = (request) => {
        return (
            request?.status?.toLowerCase() === 'Approved'.toLowerCase() || // Approved requests can be viewed by anyone
            request?.requested_by?.id === sessionUser?.id || // Users can view their own requests
            mission?.missionLeader?.id === sessionUser?.id || // Mission leader can view all requests
            ['missions-coordinator', 'admin', 'superadmin'].includes(userRole) // Certain roles can view all requests
        );
    };

    const canViewMissioner = (request) => {
        return (request?.requested_by?.id === sessionUser?.id || // Users can view themselves
            mission?.missionLeader?.id === sessionUser?.id || // Mission leader can view all users that requested
            ['missions-coordinator', 'admin', 'superadmin'].includes(userRole));
    }

    const canApproveRequest = (request) => {
        return (mission?.missionLeader?.id === sessionUser?.id || // Mission leader can view all users that requested
            ['missions-coordinator', 'admin', 'superadmin'].includes(userRole))
    }
    // Function to count the number of approved requests
    const countApprovedRequests = () => {
        return allRequests?.filter(request => request?.status?.toLowerCase() === 'approved')?.length;
    };

    const canEditFeedback = (sessionUser) => {
        return mission?.feedback?.feedback_by_id === sessionUser?.id
    }

    const checkIfRequestToAttendIsSent = useCallback(async () => {
        const attendanceResponse = await fetch(`${API_URL}/missions/${id}/attendance-requests/check`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        });

        if (attendanceResponse.ok) {
            const attendanceData = await attendanceResponse.json();
            setHasRequestedToAttend(attendanceData.has_requested);
            setAllRequests(attendanceData.all_requests);
            setMyRequest(attendanceData.my_request);
        } else if (attendanceResponse.status === 404) {
            setNotification({ type: "danger", message: "Mission not found" });
        } else {
            setNotification({ type: "danger", message: "Could not load attendance requests" });
        }
    }, [id]);

    const fetchMission = useCallback(async () => {
        if (id) {
            try {
                const response = await fetch(`${API_URL}/missions/${id}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setMission(data);

                    await checkIfRequestToAttendIsSent();

                } else if (response.status === 404) {
                    setNotification({ type: "danger", message: "Mission not found." });
                } else if (response.status === 401) {
                    navigate("/logout");
                } else {
                    throw new Error("Failed to fetch mission.");
                }
            } catch (error) {
                setNotification({ type: "danger", message: "An error occurred while fetching the mission." });
            }
        }
    }, [id, navigate, checkIfRequestToAttendIsSent]);

    useEffect(() => {
        const fetchData = async () => {
            if (loading) { // Ensure fetch is only triggered when loading is true
                await fetchMission();
                setLoading(false); // Prevent unnecessary re-renders
            }
        };

        fetchData();

        const handleClickOutside = (event) => {
            if (isDropdownOpen !== null) {
                const dropdown = document.querySelector(`.dropdown.is-active`);
                if (dropdown && !dropdown.contains(event.target)) {
                    setIsDropdownOpen(null);
                }
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [fetchMission, isDropdownOpen, loading]); // Add loading dependency

    const handleRequestToAttend = (actionType, request) => {
        setConfirmAction(actionType); // Set the action type (Approve, Reject, etc.)
        setSelectedRequest(request); // Track the request associated with the action

        let title = "Confirm Action";
        let message = `Are you sure you want to ${actionType?.toUpperCase()} this request by ${request?.requested_by?.first_name} ${request?.requested_by?.last_name}?`;

        // Customize the title based on the actionType
        switch (actionType) {
            case "approve":
                title = "Approve Request";
                break;
            case "reject":
                title = "Reject Request";
                break;
            case "cancel":
                title = "Cancel Request";
                break;
            case "request":
                title = "Request to Serve";
                message = `Are you sure you want to request to serve in this mission?`;
                break;
            default:
                break;
        }

        setConfirmDialogTitle(title);
        setConfirmDialogMessage(message);
        setConfirmDialogOpen(true); // Open the confirmation dialog
    };

    const closeNotification = () => {
        setNotification({ type: "", message: "" });
    };

    const toggleDropdown = (index) => {
        if (isDropdownOpen === index) {
            setIsDropdownOpen(null); // Close dropdown if the same one is clicked
        } else {
            setIsDropdownOpen(index); // Open the clicked dropdown
        }
    };

    const confirmRequestToAttend = async () => {
        let endpoint = `${API_URL}/missions/${id}/attendance-requests`;

        if (confirmAction !== "request") {
            endpoint += `/${selectedRequest.id}`;
        }

        switch (confirmAction) {
            case "approve":
                endpoint += "/approve";
                break;
            case "reject":
                endpoint += "/reject";
                break;
            case "cancel":
                endpoint += "/cancel";
                break;
            default:
                endpoint += "";
        }

        try {
            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            });

            if (response.ok) {
                setNotification({ type: "success", message: `Request ${confirmAction}ed successfully.` });
                await checkIfRequestToAttendIsSent(); // Refresh the requests list
            } else {
                setNotification({ type: "danger", message: `Failed to ${confirmAction} the request.` });
            }
        } catch (error) {
            setNotification({ type: "danger", message: "An error occurred while processing the request." });
        } finally {
            setConfirmDialogOpen(false);
        }
    };

    if (loading) return <p>Loading...</p>;

    return (
        <main>
            <nav className="level">
                <div className="level-left">
                    <div className="level-item"></div>
                </div>
                <div className="level-right">
                    <p className="level-item">
                        <Link to="/dashboard/missions" className="button primary-bg">
                            <i className="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Back
                        </Link>
                        {canEdit(userRole) && (
                            <>
                                &nbsp; &nbsp;
                                <Link to={`/dashboard/missions/${mission?.id}/update`} className="button primary-bg">
                                    <i className="fa fa-pencil" aria-hidden="true"></i> &nbsp; Edit
                                </Link>
                            </>
                        )}
                    </p>
                </div>
            </nav>

            {notification.message && (
                <Notification type={notification.type} message={notification.message} onClose={closeNotification} />
            )}

            {/* Confirm Dialog */}
            <ConfirmDialog
                isOpen={isConfirmDialogOpen}
                theme="is-primary"
                title={confirmDialogTitle}
                message={confirmDialogMessage}
                onConfirm={confirmRequestToAttend}
                onCancel={() => setConfirmDialogOpen(false)}
            />

            <div className="columns is-multiline mb-6">
                {/* Mission Header */}
                <div className="column is-12">
                    <div className="box has-text-centered">
                        <p className="title is-3">{mission?.school?.name} - {mission?.mission_type?.name} </p>
                        {mission?.status && (
                            <p className={`tag is-${mission?.status === "active" ? "success" : "danger"}`}>
                                {mission?.status?.toUpperCase()}
                            </p>
                        )}
                        <p>
                            Posted by: {mission?.created_by?.first_name} {mission?.created_by?.last_name} on{" "}
                            {new Date(mission?.created_at)?.toLocaleString()}
                        </p>
                    </div>
                </div>

                {/* Mission School Details */}
                <div className="column is-6">
                    <div className="box">
                        <h3 className="title is-4">{mission?.school?.name || "School not available"}</h3>
                        <hr />
                        <div className="content">
                            {/* School Location and County */}
                            <p>
                                <strong>County:</strong> {mission?.school?.county?.name || "N/A"}
                            </p>
                            <p>
                                <strong>Location:</strong> {mission?.school?.location_description || "N/A"}
                            </p>
                            {mission?.school?.google_map_pin && (
                                <p>
                                    <a
                                        href={mission?.school.google_map_pin}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="button is-primary is-light is-small"
                                    >
                                        <FontAwesomeIcon icon={faMapMarkerAlt} /> &nbsp; View on Google Maps
                                    </a>
                                </p>
                            )}
                            <p>
                                <strong>The School:</strong>{" "}
                                {mission?.school?.description?.length > 200
                                    ? `${mission?.school.description.substring(0, 200)}...`
                                    : mission?.school?.description || "N/A"}
                            </p>
                            <div className="buttons">
                                <Link
                                    to={`/dashboard/schools/${mission?.school?.id}/details`}
                                    className="button is-link is-small"
                                >
                                    View school details
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Date & Time */}
                <div className="column is-6">
                    <div className="box">
                        <p className="title is-5">{mission?.mission_type?.name}</p>
                    </div>

                    <div className="box">
                        <p className="title is-5">
                            <FontAwesomeIcon icon={faCalendarAlt} /> &nbsp; Date
                        </p>
                        <p>
                            <FontAwesomeIcon icon={faClock} /> &nbsp;
                            <strong>
                                {mission?.start_date} &nbsp; {mission?.start_time}
                            </strong>
                            {mission?.end_date ? (
                                <span>
                                    &nbsp; To &nbsp;
                                    <strong>
                                        {mission?.start_date === mission?.end_date
                                            ? mission?.end_time || ""
                                            : `${mission?.end_date || ""} - ${mission?.end_time || ""}`
                                        }
                                    </strong>
                                </span>
                            ) : <span>
                                &nbsp; To &nbsp;
                                <strong>{mission?.end_time || ""}</strong>
                            </span>}
                        </p>

                    </div>

                    <div className="box">
                        <p className="title is-5"> <FontAwesomeIcon icon={faUserTie} /> &nbsp; Mission Leader</p>

                        {/* Display Mission Leader Information or Become Leader Button */}
                        {mission?.mission_leader ? (
                            <div className="">
                                <p className="has-text-weight-semibold">

                                    {(allowedToView(userRole, ['admin', 'superadmin', 'missions-coordinator']) ||
                                        mission?.missionLeader?.id === sessionUser?.id) ? (
                                        <Link to={`/dashboard/members/${mission?.mission_leader?.id}/details`} className="primary-text-color">
                                            {`${mission?.mission_leader?.first_name} ${mission?.mission_leader?.last_name}`}
                                        </Link>
                                    ) : (
                                        <> {`${mission?.mission_leader?.first_name} ${mission?.mission_leader?.last_name}`}</>
                                    )}

                                </p>

                            </div>
                        ) : (
                            <div className="has-text-left">
                                <p className="has-text-grey">The mission leader has not been assigned yet.</p>
                                {/* <Link to={`/dashboard/missions/${mission?.id}/details`} className="button mt-4 primary-bg">
                                    Become a Mission Leader
                                </Link> */}
                            </div>
                        )}
                    </div>
                </div>

                <div className="column is-12">
                    <div className="box">
                        <p className="title is-5">
                            <FontAwesomeIcon icon={faUserFriends} /> &nbsp; Missioners
                        </p>

                        <p>
                            {mission?.missioners_required > 0 && (
                                <span className="has-text-weight-bold">{mission?.missioners_required} Missioners Required
                                    &nbsp; &nbsp; |  &nbsp; &nbsp;
                                </span>
                            )}
                            <span className="primary-text-color has-text-weight-semibold">{allRequests?.length} Missioners Requested To Serve

                                {countApprovedRequests() > 0 && (
                                    <> &nbsp; &nbsp; | &nbsp; &nbsp; <span className=''>     {countApprovedRequests()} Requests Approved</span></>
                                )} </span>
                            &nbsp; &nbsp;

                            {myRequest && (
                                <span className="light-primary-text-color">
                                    |
                                    &nbsp; &nbsp;
                                    My Request Status: &nbsp;
                                    <span className={statusTagClasses[myRequest?.status] || "tag"}>
                                        {myRequest?.status?.toUpperCase()}
                                    </span> </span>

                            )}
                        </p>

                        <div className="mt-4">
                            {!hasRequestedToAttend ? (
                                <button
                                    onClick={() => handleRequestToAttend("request", null)}
                                    className="button primary-outline-button mt-2"
                                    style={{ borderRadius: '5px' }}
                                >
                                    <span className="icon">
                                        <i className="fas fa-hand-paper"></i>
                                    </span>
                                    <span>Request to Serve</span>
                                </button>
                            ) : (
                                <span></span>
                            )}
                        </div>

                        <div>
                            {allRequests?.length > 0 ? (
                                <table className="table is-striped is-fullwidth mt-4">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Phone</th>
                                            <th>Email</th>
                                            <th>Status</th>
                                            <th>Options</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allRequests
                                            .filter(request => canViewRequest(request)) // Filter requests based on the canViewRequest function
                                            .map((request, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        {(
                                                            canViewMissioner(request)
                                                        ) ? (
                                                            <Link to={`/dashboard/members/${request?.requested_by?.id}/details`} className="primary-text-color">
                                                                {request?.requested_by?.first_name} {request?.requested_by?.last_name}
                                                            </Link>
                                                        ) : (
                                                            <>{request?.requested_by?.first_name} {request?.requested_by?.last_name}</>
                                                        )}
                                                    </td>
                                                    <td>{request?.requested_by?.phone_number}</td>
                                                    <td>{request?.requested_by?.email}</td>
                                                    <td>
                                                        <span className={statusTagClasses[request?.status] || "tag"}>
                                                            {request?.status?.toUpperCase()}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <div className={`dropdown is-right ${isDropdownOpen === index ? 'is-active' : ''}`}>
                                                            <div className="dropdown-trigger">
                                                                <button
                                                                    className="button is-white"
                                                                    aria-haspopup="true"
                                                                    aria-controls="dropdown-menu"
                                                                    onClick={() => toggleDropdown(index)}
                                                                >
                                                                    <span className="icon">
                                                                        <i className="fas fa-ellipsis-v"></i>
                                                                    </span>
                                                                </button>
                                                            </div>
                                                            <div className="dropdown-menu" id="dropdown-menu" role="menu">
                                                                <div className="dropdown-content">
                                                                    {canViewMissioner(request) && (
                                                                        <Link to={`/dashboard/members/${request?.requested_by?.id}/details`} className="dropdown-item">
                                                                            <span className="icon">
                                                                                <i className="fas fa-eye"></i>
                                                                            </span>
                                                                            <span>View Missioner</span>
                                                                        </Link>
                                                                    )}

                                                                    {(request.status?.toLowerCase() !== "approved" && canApproveRequest(request)) && (
                                                                        <Link onClick={() => handleRequestToAttend("approve", request)} className="dropdown-item">
                                                                            <span className="icon">
                                                                                <FontAwesomeIcon icon={faCheck} />
                                                                            </span>
                                                                            <span>Approve Request</span>
                                                                        </Link>
                                                                    )}

                                                                    {(request.status?.toLowerCase() !== "rejected" && canApproveRequest(request)) && (
                                                                        <Link onClick={() => handleRequestToAttend("reject", request)} className="dropdown-item">
                                                                            <span className="icon">
                                                                                <FontAwesomeIcon icon={faTimes} />
                                                                            </span>
                                                                            <span>Reject Request</span>
                                                                        </Link>
                                                                    )}

                                                                    {(request.status?.toLowerCase() !== "cancelled" && request?.requested_by?.id === sessionUser?.id) && (
                                                                        <Link onClick={() => handleRequestToAttend("cancel", request)} className="dropdown-item">
                                                                            <span className="icon">
                                                                                <FontAwesomeIcon icon={faBan} />
                                                                            </span>
                                                                            <span>Cancel Request</span>
                                                                        </Link>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            ) : (
                                <span></span>
                            )}
                        </div>

                    </div>
                </div>

                {/* Feedback */}
                <div className="column is-12">
                    <div className="box">
                        <p className="title is-5">
                            <FontAwesomeIcon icon={faComment} /> &nbsp; Mission Feedback Report
                        </p>

                        {mission?.feedback ? (
                            <div>
                                <div className="column is-full mb-0 pb-0">
                                    <div className="notification mb-0 ">
                                        <span className="icon-text">
                                            <span className="icon">
                                                <FontAwesomeIcon icon={faUsers} />
                                            </span>
                                            <span><strong>No of Converts:</strong> </span>
                                            <span className="is-size-4 has-text-weight-bold ml-2">
                                                {mission?.feedback?.no_of_converts}
                                            </span>
                                        </span>


                                        <Link to={`/dashboard/missions/${id}/feedback/details`}

                                            className="button is-outline is-pulled-right is-rounded has-text-weight-bold"
                                        >
                                            View the full mission feedback
                                        </Link>
                                    </div>
                                </div>

                                {/* Feedback Comments */}
                                <div className="column is-full mb-0 pb-0">
                                    <div className="notification mb-0">
                                        <span className="icon-text">
                                            <span className="icon">
                                                <FontAwesomeIcon icon={faComments} />
                                            </span>
                                            <span>
                                                <strong>Comments:</strong>
                                                <p className="mt-2" style={{ whiteSpace: "pre-wrap" }}>
                                                    {mission?.feedback.feedback_comments}
                                                </p>
                                            </span>
                                        </span>
                                    </div>
                                </div>

                                {/* Prayer Items */}
                                <div className="column is-full">
                                    <div className="notification">
                                        <span className="icon-text">
                                            <span className="icon">
                                                <FontAwesomeIcon icon={faPray} />
                                            </span>
                                            <span>
                                                <strong>Prayer Items:</strong>
                                                <p className="mt-2" style={{ whiteSpace: "pre-wrap" }}>
                                                    {mission?.feedback?.prayer_items}
                                                </p>
                                            </span>
                                        </span>
                                    </div>
                                </div>

                                <div className="has-text-centered">
                                    {/* 
                                    <Link
                                        
                                        className="button is-outline  is-rounded has-text-weight-bold"
                                    >
                                        View the full feedback
                                    </Link> */}
                                </div>
                            </div>
                        ) : (
                            <p className="is-left">🙁 Feedback Not Available.</p>
                        )}
                        {/* Feedback button */}
                        {!mission?.feedback && mission?.start_date && new Date(mission?.start_date) <= new Date() && (
                            <div className="has-text-centered mt-3">
                                <Link
                                    to={`/dashboard/missions/${id}/feedback`}
                                    className="button primary-outline-button"
                                >
                                    <span className="icon">
                                        <FontAwesomeIcon icon={faPenFancy} />
                                    </span>
                                    <span>Give Mission Feedback
                                    </span>
                                </Link>
                            </div>
                        )}

                        {mission?.feedback && canEditFeedback(sessionUser) && (
                            <div className="has-text-centered mt-3">
                                <Link
                                    to={`/dashboard/missions/${id}/feedback/${mission?.feedback?.id}`}
                                    className="button primary-outline-button"
                                >
                                    <span className="icon">
                                        <FontAwesomeIcon icon={faPenFancy} />
                                    </span>
                                    <span>Update Mission Feedback
                                    </span>
                                </Link>
                            </div>
                        )}

                    </div>
                </div>

                {/* Description */}
                <div className="column is-12">
                    <div className="box">
                        <p className="title is-5">
                            <FontAwesomeIcon icon={faPalette} /> &nbsp; Theme
                        </p>
                        <p>{mission?.theme || "Theme Not available."}</p>
                    </div>
                </div>

                {/* Description */}
                <div className="column is-12">
                    <div className="box">
                        <p className="title is-5">
                            <FontAwesomeIcon icon={faInfoCircle} /> &nbsp; Description
                        </p>
                        <p>{mission?.description || "No description available."}</p>
                    </div>
                </div>

                {/* Description */}
                <div className="column is-12">
                    <div className="box">
                        <p className="title is-5">
                            <FontAwesomeIcon icon={faPrayingHands} /> &nbsp; Prayer Items
                        </p>
                        <p>{mission?.prayer_items || "Prayer items not available."}</p>
                    </div>
                </div>

                {/* Contact Information */}
                <div className="column is-6">
                    <div className="box">
                        <p className="title is-5">
                            <FontAwesomeIcon icon={faQuestionCircle} /> &nbsp; Have a question?
                        </p>
                        <hr />
                        <p className="subtitle">
                            Contact: &nbsp;
                            {mission?.missionLeader ? (
                                <span className="has-text-weight-semibold">
                                    {mission?.missionLeader?.first_name} {mission?.missionLeader?.last_name} &nbsp; contact p
                                    {mission?.missionLeader?.email} &nbsp; {mission?.missionLeader?.phone_number}
                                </span>
                            ) : (
                                <span className="has-text-weight-semibold">
                                    {mission?.created_by?.first_name} {mission?.created_by?.last_name} &nbsp;
                                    {mission?.created_by?.email} &nbsp; {mission?.created_by?.phone_number}
                                </span>
                            )}
                        </p>
                    </div>
                </div>

            </div>
        </main >
    );
}

export default MissionView;
