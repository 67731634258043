import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

function PrivacyPolicy() {

    return (
        <div>
            <Header />
             <div className="container with-min-height">
                <h1>Privacy Policy</h1>
             </div>
            <Footer />
        </div>
    )
}
export default PrivacyPolicy;
