import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from '../../src/assets/images/logo600px.png';

function Header() {
    const [user, setUser] = useState(null);
    const [isMenuActive, setIsMenuActive] = useState(false);

    useEffect(() => {
        const loggedInUser = localStorage.getItem('user');
        if (loggedInUser) {
            setUser(JSON.parse(loggedInUser));
        }
    }, []);

    const toggleMenu = () => {
        setIsMenuActive(!isMenuActive);
    };

    return (
        <nav className="navbar main-navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <Link className="logo-link" to="/" id="logoLink">
                    <img src={Logo} className="main-logo" id="mainLogo" alt="CFCM MIS Logo" />
                </Link>

                <button
                    className={`hamburger ${isMenuActive ? 'is-active' : ''}`}
                    aria-label="menu"
                    aria-expanded={isMenuActive}
                    onClick={toggleMenu}
                >
                    <span className="hamburger-line"></span>
                    <span className="hamburger-line"></span>
                    <span className="hamburger-line"></span>
                </button>
            </div>

            <div className={`navbar-menu ${isMenuActive ? 'is-active' : ''}`}>
                <div className="navbar-center">
                    <Link to="/" className="navbar-item">
                        Home
                    </Link>
                    <Link to="/about-us" className="navbar-item">
                        About Us
                    </Link>
                    <Link to="/events" className="navbar-item">
                        Events
                    </Link>
                    <Link to="/makuyu-prayer-center-booking" className="navbar-item">
                        Prayer Center Booking
                    </Link>
                </div>

                <div className="navbar-end">
                    <div className="navbar-item">
                        <div className="buttons">
                            {user ? (
                                <div className="dropdown is-right is-hoverable">
                                    <div className="dropdown-trigger">
                                        <button className="button is-outline has-text-bold" aria-haspopup="true" aria-controls="dropdown-menu">
                                            <span><Link to="/dashboard" className="dropdown-item"> {user?.first_name} {user?.last_name}</Link> </span>
                                            <span className="icon is-small">
                                                <i className="fas fa-angle-down" aria-hidden="true"></i>
                                            </span>
                                        </button>
                                    </div>
                                    <div className="dropdown-menu" id="dropdown-menu" role="menu">
                                        <div className="dropdown-content">
                                            <Link to="/dashboard" className="dropdown-item">
                                                Dashboard
                                            </Link>
                                            <Link to="/dashboard/profile" className="dropdown-item">
                                                Profile
                                            </Link>
                                            <hr className="dropdown-divider" />
                                            <Link className="dropdown-item" to="/logout">
                                                Logout
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <Link to="/logout" className="button is-outline">
                                    <strong>Sign In</strong>
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Header;
