import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Notification from "../components/Notification";
import { API_URL } from "../config";
import '../styles/SignIn.css';

function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [notification, setNotification] = useState({ type: "", message: "", isVisible: false });
    // Validation states
    const [errors, setErrors] = useState({
        email: ""
    });
    const [loading, setLoading] = useState(false);
    const [successful, setSuccessful] = useState(false);

    const validate = () => {
        let isValid = true;
        const errors = {};

        if (!email) {
            errors.email = "Email is required.";
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = "Email is invalid.";
            isValid = false;
        }

        setErrors(errors);
        return isValid;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        if (validate()) {
            try {
                const response = await fetch(API_URL + "/auth/i-forgot-password", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        email: email
                    }),
                });

                const data = await response.json();

                if (response.ok) {

                    setNotification({ type: "success", message: "Request sent. Check your email.", isVisible: true });
                    setSuccessful(true);
                    setEmail("");
                } else {
                    setNotification({ type: "danger", message: data.error || "Request Failed.", isVisible: true });
                    if ( data.email) {
                        setErrors((prevErrors) => ({
                            ...prevErrors,
                            email: data.email.join(", "), // Combine all email error messages
                        }));
                    }
                }
                setLoading(false);

            } catch (error) {
                console.log(error)
                setNotification({ type: "danger", message: "An error occurred. Please try again later.", isVisible: true });
            }

        } else {
            setNotification({ type: "danger", message: "Please fix the errors in the form.", isVisible: true });
        }
    };

    const handleCloseNotification = () => {
        setNotification({ ...notification, isVisible: false });
    };

    return (
        <div>
            <Header />
            <main>
                <div className="signin-page mb-4">
                    <div className="siginin-bg"></div>
                    <div className="signin-card">
                        <div className="signin-content">
                            <div className="media pb-4 mb-4 is-justify-content-space-between">
                                <div className="media-left">
                                    <Link to="/login"><i className="fa fa-arrow-left"></i> &nbsp; To Log In</Link>
                                </div>
                                <div className="media-right">
                                    <Link to="/"><i className="fa-solid fa-x"></i></Link>
                                </div>
                            </div>
                            <div className="content">
                                <h1 className="sign-in-title">I Forgot Password</h1>

                                <p>Enter your email to request a password reset link. The link will be sent to your email.</p>

                                {notification.isVisible && (
                                    <Notification
                                        type={notification.type}
                                        message={notification.message}
                                        onClose={handleCloseNotification}
                                    />
                                )}

                                <form onSubmit={handleSubmit}>
                                    <div className={`field ${errors.email ? 'is-danger' : ''}`}>
                                        <label className="label">Email</label>
                                        <div className="control has-icons-left has-icons-right">
                                            <input
                                                className={`input ${errors.email ? 'is-danger' : ''}`}
                                                type="email"
                                                placeholder="Email input"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            <span className="icon is-small is-left">
                                                <i className="fas fa-envelope"></i>
                                            </span>
                                            {errors.email && (
                                                <span className="icon is-small is-right">
                                                    <i className="fas fa-exclamation-triangle"></i>
                                                </span>
                                            )}
                                        </div>
                                        {errors.email && <p className="help is-danger">{errors.email}</p>}
                                    </div>

                                    <div className="field">
                                        <div className="control">
                                            <button type="submit" className="button is-fullwidth primary-bg"disabled={ loading || successful}>
                                            {loading ? "Loading..." : "Request Password Reset Link"}</button>
                                        </div>
                                    </div>
                                </form>
                                <div className="field mb-5 mt-4">
                                    <div className="control">
                                        <label>
                                            Don't have an account? &nbsp;
                                            <Link to="/signup">Sign Up Here.</Link>
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default ForgotPassword;
