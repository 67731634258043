import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ConfirmDialog from "../../components/ConfirmDialog";
import Notification from "../../components/Notification";
import { API_URL } from "../../config";
import { allowedToView } from "../../utils/PermissionUtils.js";

function Books() {
    const [books, setBooks] = useState([]);
    const [newBook, setNewBook] = useState({ title: "", author: "", available: true });
    const [editBook, setEditBook] = useState(null);
    const [notification, setNotification] = useState({ type: "", message: "" });
    const [errors, setErrors] = useState({});
    const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false); // State to control dialog
    const [selectedBook, setSelectedBook] = useState(null); // State to store book to delete
    const navigate = useNavigate();
    const token = localStorage.getItem("access_token");
    const userRole = localStorage.getItem("userRole");
    const canEdit = (userRole) => {
        return (allowedToView(userRole, ['admin', 'superadmin', 'missions-coordinator']));
    };

    // Fetch Books
    const fetchBooks = useCallback(async () => {
        try {
            const response = await fetch(`${API_URL}/mission-books`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                setBooks(data);
            } else if (response.status === 401) {
                navigate("/logout");
            } else {
                throw new Error("Failed to fetch books");
            }
        } catch (error) {
            console.error("There was an error fetching the books!", error);
            setNotification({ type: "danger", message: "Failed to fetch books." });
        }
    }, [token, navigate]);

    useEffect(() => {
        if (!token) {
            navigate("/logout");
        }

        fetchBooks();
    }, [fetchBooks, token, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({}); // Clear previous errors

        if (!newBook.title.trim()) {
            setErrors({ title: ["Book title is required"] });
            return;
        }

        try {
            const response = editBook
                ? await updateBook(editBook.id, newBook)
                : await createBook(newBook);

            if (!response.ok) {
                const errorData = await response.json();
                setErrors(errorData || {});
                return;
            }

            const message = editBook
                ? "Book updated successfully."
                : "Book added successfully.";

            setNotification({ type: "success", message });
            setNewBook({ title: "", author: "", available: true });
            setEditBook(null);
            fetchBooks();
        } catch (error) {
            console.error("There was an error submitting the form!", error);
            setNotification({ type: "danger", message: "Failed to submit the book." });
        }
    };

    const createBook = async (book) => {
        const response = await fetch(`${API_URL}/mission-books`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify(book),
        });
        return response;
    };

    const updateBook = async (id, book) => {
        const response = await fetch(`${API_URL}/mission-books/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify(book),
        });
        return response;
    };

    const handleEdit = (book) => {
        setNewBook({ title: book.title, author: book.author, available: book.available });
        setEditBook(book);
    };

    // Handle opening confirmation dialog
    const handleDeleteClick = (book) => {
        setSelectedBook(book);
        setConfirmDialogOpen(true);
    };

    // Confirm deletion of the book
    const handleConfirmDelete = async () => {
        if (selectedBook) {
            try {
                const response = await fetch(`${API_URL}/mission-books/${selectedBook.id}`, {
                    method: 'DELETE',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    setNotification({ type: "success", message: 'Book deleted successfully' });
                    fetchBooks(); // Update the books list
                } else if (response.status === 401) {
                    navigate("/logout");
                } else if (response.status === 404) {
                    setNotification({ type: "danger", message: 'Book not found.' });
                } else {
                    setNotification({ type: "danger", message: 'There was an error.' });
                }
            } catch (error) {
                setNotification({ type: "danger", message: 'An error occurred while deleting the book.' });
                console.error("Error:", error);
            } finally {
                setConfirmDialogOpen(false); // Close the dialog
                setSelectedBook(null); // Reset selected book
            }
        }
    };

    // Cancel deletion
    const handleCancelDelete = () => {
        setConfirmDialogOpen(false);
        setSelectedBook(null);
    };

    const closeNotification = () => {
        setNotification({ type: "", message: "" });
    };

    return (
        <main>
            <nav className="level">
                <div className="level-left">
                    <div className="level-item">
                        <p className="title">Mission Books</p>
                    </div>
                </div>
                <div className="level-right">
                    {canEdit(userRole) && (
                        <p className="level-item">
                            <Link to="/new-book" className="button primary-bg">New</Link>
                        </p>
                    )}
                </div>
            </nav>

            <div className="colum">
                {notification.message && (
                    <Notification
                        type={notification.type}
                        message={notification.message}
                        onClose={closeNotification}
                    />
                )}
            </div>
            <div className="columns">

                <div className={`column  ${canEdit(userRole) ? 'is-half' : 'is-fullwidth'} dash-section vertical-separator`}>
                    <h1 className="section-title">Books</h1>
                    <table className="table is-striped is-fullwidth">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Title</th>
                                <th>Author</th>
                                <th>Available</th>
                                {canEdit(userRole) && (
                                    <th>Options</th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {books.map((book, index) => (
                                <tr key={book.id}>
                                    <td>{index + 1}</td>
                                    <td>{book.title}</td>
                                    <td>{book.author}</td>
                                    <td>{book.available ? "Yes" : "No"}</td>
                                    {canEdit(userRole) && (
                                        <td>

                                            <div className="dropdown is-right">
                                                <div className="dropdown-trigger">
                                                    <button
                                                        className="button is-white"
                                                        onClick={() => handleEdit(book)}
                                                    >
                                                        <span className="icon">
                                                            <i className="fas fa-edit"></i>
                                                        </span>
                                                    </button>
                                                    <button
                                                        className="button is-white"
                                                        onClick={() => handleDeleteClick(book)}
                                                    >
                                                        <span className="icon">
                                                            <i className="fas fa-trash-alt"></i>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>

                                        </td>)}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {canEdit(userRole) && (
                    <div className="column is-half dash-section">
                        <h1 className="section-title">{editBook ? "Edit Book" : "Add New Book"}</h1>
                        <form onSubmit={handleSubmit}>
                            <div className="field">
                                <label className="label">Title</label>
                                <div className="control has-icons-left has-icons-right">
                                    <input
                                        className={`input ${errors.title ? "is-danger" : ""}`}
                                        type="text"
                                        placeholder="Book Title"
                                        value={newBook.title}
                                        onChange={(e) => setNewBook({ ...newBook, title: e.target.value })}
                                        required
                                    />
                                    <span className="icon is-small is-left">
                                        <i className="fas fa-book"></i>
                                    </span>
                                </div>
                                {errors.title && errors.title.map((error, index) => (
                                    <p key={index} className="help is-danger">{error}</p>
                                ))}
                            </div>

                            <div className="field">
                                <label className="label">Author</label>
                                <div className="control has-icons-left has-icons-right">
                                    <input
                                        className={`input ${errors.author ? "is-danger" : ""}`}
                                        type="text"
                                        placeholder="Book Author"
                                        value={newBook.author}
                                        onChange={(e) => setNewBook({ ...newBook, author: e.target.value })}
                                        required
                                    />
                                    <span className="icon is-small is-left">
                                        <i className="fas fa-book"></i>
                                    </span>
                                </div>
                                {errors.author && errors.author.map((error, index) => (
                                    <p key={index} className="help is-danger">{error}</p>
                                ))}
                            </div>

                            <div className="field">
                                <label className="label">Available</label>
                                <div className="control">
                                    <label className="checkbox">
                                        <input
                                            type="checkbox"
                                            checked={newBook.available}
                                            onChange={(e) => setNewBook({ ...newBook, available: e.target.checked })}
                                        /> &nbsp; &nbsp;
                                        Available
                                    </label>
                                </div>
                            </div>

                            <div className="field">
                                <div className="control">
                                    <button type="submit" className="button primary-bg">{editBook ? "Update" : "Add New"}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                )}
            </div>

            {/* ConfirmDialog Component */}
            < ConfirmDialog
                isOpen={isConfirmDialogOpen}
                theme="is-danger" // You can make this dynamic (e.g., based on action type)
                title="Delete Confirmation"
                message={`Are you sure you want to delete '${selectedBook?.title}' by ${selectedBook?.author}?`}
                onConfirm={handleConfirmDelete}
                onCancel={handleCancelDelete}
            />
        </main>
    );
}

export default Books;
