import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

function AboutUs() {
    return (
        <div>
            <Header />
            <main className="section">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-8">
                            <h1 className="title is-3 has-text-centered">About Us</h1>
                            <p className="content is-size-5 has-text-justified">
                                Commissioners for Christ Ministry (CFCM) is a Christian inter-denominational organization made up of Christian professionals from different disciplines who have zeal and a passion for evangelistic work. The ministry was started in 1980 by a team of passionate young brothers and sisters who felt compelled to reach out to young people and evangelize the good news of our Lord Jesus Christ. In 1982, the ministry experienced exponential growth after more young preachers joined to form a much bigger team.
                                <br />
                                Though the main area of operation was Nairobi, the growth necessitated the formation of a branch also referred to us Chapter in Nyeri. The team met monthly on a rotational basis where the members would visit each other over a weekend to plan and pray over ministry activities and initiatives. Later, the team would start meeting more frequently at a central point for example the Nairobi Chapter would meet at Ufungamano house every Thursday for a time of prayer and sharing the word.
                                <br />
                                In addition to the two initial chapters of Nyeri and Nairobi, the ministry expanded to have four additional chapters including Embu- Kerugoya, Mombasa, Nakuru and Diaspora chapter which consists of members outside the boundaries of Kenya but committed to the great commission of reaching out to the lost souls. Currently the ministry has a membership of more than 100 active members and more than 1000 associated with the work of the ministry.
                                <br />
                                CFCM continues to enjoy God’s favor in the mission field with hundreds of students and other members of the society receiving Christ every year as we preach the Gospel. We must admit the harvest is plenty and the work is increasing and thus the need to partner and reach out to more people with the gospel of our Lord Jesus Christ.
                                <br />
                                The approach of ministry at CFCM has been reaching out to young people in schools. For more than 30 years since its inception, the team has traversed many high schools with one common message of repentance. Thousands of young people have come to accept Jesus as Lord and Savior over their lives. The other aspects of ministry include annual missions, annual conferences dubbed the Gold conference, and annual reflections where the team get together and have time to have personal connection and intimate time with the Lord Jesus
                            </p>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default AboutUs;
