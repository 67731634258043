import {
    faCalendarAlt, faUserTie, faComments, faPrayingHands, faUsers, faBook, faHandHoldingUsd, faPhone,
    faPencil,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Notification from "../../components/Notification";
import { API_URL } from "../../config";
import { allowedToView } from "../../utils/PermissionUtils.js";
import { formatTimestamp } from "../../utils/TextUtils.js";

function MissionFeedbackView() {
    const { missionId } = useParams();
    const [notification, setNotification] = useState(null);
    const token = localStorage.getItem("access_token");
    const [feedback, setFeedback] = useState(null);
    const navigate = useNavigate();
    const userRole = localStorage.getItem("userRole");
    const sessionUser = JSON.parse(localStorage.getItem("user"));

    useEffect(() => {
        const fetchFeedback = async () => {
            if (missionId) {
                try {
                    const response = await fetch(`${API_URL}/missions/${missionId}/feedback`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    if (response.ok) {
                        const data = await response.json();
                        setFeedback(data[0]); // Assuming the API returns an array and we need the first item
                    } else if (response.status === 404) {
                        setNotification({ type: "danger", message: "Mission feedback not found." });
                    } else if (response.status === 401) {
                        navigate("/logout");
                    } else {
                        throw new Error("Failed to fetch feedback.");
                    }
                } catch (error) {
                    setNotification({ type: "danger", message: "An error occurred while fetching the feedback." });
                }
            }
        };

        fetchFeedback();
    }, [missionId, navigate, token]);

    const closeNotification = () => {
        setNotification({ type: "", message: "" });
    };

    return (
        <main>
            <nav className="level mb-5">
                <div className="level-left">
                    <h1 className="title">Mission Feedback</h1>
                </div>
                <div className="level-right">
                    <Link to={`/dashboard/missions/${missionId}/details`} className="button primary-bg">
                        <i className="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Back
                    </Link>
                    &nbsp;
                    {sessionUser?.id === feedback?.feedback_by?.id && (
                        <Link to={`/dashboard/missions/${missionId}/feedback/${feedback?.id}`} className="button primary-bg">
                            <FontAwesomeIcon icon={faPencil} /> &nbsp; Edit Feedback
                        </Link>
                    )}
                </div>
            </nav>

            {notification?.message && (
                <Notification type={notification.type} message={notification?.message} onClose={closeNotification} />
            )}

            {feedback && (
                <div className="box">
                    <section className="content">
                        <h2 className="subtitle"> <Link to={`/dashboard/missions/${feedback?.mission?.id}/details`} className='no_deco_link'>{feedback.mission.name}</Link> </h2>
                        <div className="tags">
                            <span className="tag is-info">
                                <strong>
                                    Mission Time: {feedback?.mission?.start_date} &nbsp; {feedback?.mission?.start_time}
                                </strong>

                                {feedback?.mission?.end_date ? (
                                    <strong>
                                        {feedback?.mission?.start_date === feedback?.mission?.end_date
                                            ? feedback?.mission?.end_time || ""
                                            : `${feedback?.mission?.end_date || ""} &nbsp; To &nbsp; ${feedback?.mission?.end_time || ""}`}
                                    </strong>
                                ) : feedback?.mission?.end_time ? (
                                    <>
                                        &nbsp; To &nbsp;
                                        <strong>{feedback?.mission?.end_time || ""}</strong>
                                    </>
                                ) : null}
                            </span>

                            <span className="tag is-success">
                                <FontAwesomeIcon icon={faUserTie} /> &nbsp; School: &nbsp; <Link
                                    to={`/dashboard/schools/${feedback?.mission?.school_id}/details`} className="primary-text-color"> {feedback.mission.school_name}</Link>
                            </span>
                            <span className="tag is-info">
                                <FontAwesomeIcon icon={faUserTie} /> &nbsp; Feeback Posted By: &nbsp; <strong>
                                    {(allowedToView(userRole, ['admin', 'superadmin', 'missions-coordinator']) ||
                                        feedback?.mission?.mission_leader_id === sessionUser?.id) ? (
                                        <Link to={`/dashboard/members/${feedback?.feedback_by?.id}/details`} className="primary-text-color">
                                            {`${feedback?.feedback_by?.full_name}`}
                                        </Link>
                                    ) : (
                                        <> {`${feedback?.feedback_by?.full_name}`} </>
                                    )}
                                    &nbsp; On  {`${formatTimestamp(feedback?.created_at)}`}
                                </strong>

                            </span>
                        </div>
                    </section>

                    <hr />

                    <section className="columns">
                        <div className="column is-half">
                            <div className="box">
                                <h3 className="title is-6">Mission Overview</h3>
                                <p><FontAwesomeIcon icon={faUsers} /> &nbsp; <strong>No. of Converts:</strong> &nbsp; {feedback.no_of_converts}</p>
                                <p><FontAwesomeIcon icon={faBook} /> &nbsp;  <strong>Books Carried:</strong> &nbsp; {feedback.books_carried}</p>
                                <p><FontAwesomeIcon icon={faHandHoldingUsd} /> &nbsp;  <strong>Cost of Mission:</strong> &nbsp; Ksh. {feedback.cost_of_mission}</p>
                                <p><FontAwesomeIcon icon={faCalendarAlt} />  &nbsp; <strong>Start Date: </strong> &nbsp; {feedback.mission.start_date}</p>
                                <p><FontAwesomeIcon icon={faCalendarAlt} /> &nbsp; <strong>End Date: </strong> &nbsp; {feedback.mission.end_date}</p>
                            </div>
                        </div>

                        <div className="column is-half">
                            <div className="box">
                                <h3 className="title is-6">Host and Attendance</h3>
                                <p><FontAwesomeIcon icon={faUserTie} /> &nbsp; <strong>Host Person:</strong> {feedback.host_person_name}</p>
                                <p><FontAwesomeIcon icon={faPhone} /> &nbsp; <strong>Contact:</strong> {feedback.host_person_phone}</p>
                                <p><FontAwesomeIcon icon={faUsers} /> &nbsp; <strong>Missioners Present:</strong> {feedback.no_of_missioners_present}</p>
                                <p><FontAwesomeIcon icon={faUsers} /> &nbsp; <strong>Names of Missionsers:</strong></p>
                                <p className="is-italic">{feedback.names_of_missioners_present.split("\r\n").join(", ")}</p>
                            </div>
                        </div>
                    </section>

                    <section className="box mt-5">
                        <h3 className="title is-5">Additional Feedback</h3>
                        <p><FontAwesomeIcon icon={faComments} /> <strong>Feedback Comments:</strong></p>
                        <p className="is-italic">{feedback.feedback_comments}</p>
                        <p className='mt-5'><FontAwesomeIcon icon={faPrayingHands} /> <strong>Prayer Items:</strong></p>
                        <p className="is-italic">{feedback.prayer_items}</p>
                        <p className='mt-5'><FontAwesomeIcon icon={faComments} /> <strong>School Needs:</strong></p>
                        <p className="is-italic">{feedback.school_needs}</p>
                    </section>
                </div>
            )}
        </main>
    );
}

export default MissionFeedbackView;
