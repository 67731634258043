import {
    faChurch,
    faEnvelope,
    faMap,
    faPhone,
    faPrayingHands,
    faRing,
    faUserTie
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Notification from "../../components/Notification.js";
import { API_URL } from "../../config.js";
import ConfirmDialog from '../../components/ConfirmDialog.js';

function UserView() {
    const [user, setUser] = useState({
        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
        occupation: "",
        marital_status: "",
        born_again: null,
        year_born_again: "",
        place_of_worship: "",
        pastors_name: "",
        pastors_mobile_number: "",
        date_of_birth: "",
        status: "",
        role: { name: "" }
    });
    const [loading, setLoading] = useState(true);
    const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [notification, setNotification] = useState({ type: "", message: "" });
    const navigate = useNavigate();
    const { id } = useParams();  // Optional: Use if you're fetching user profile by ID
    const token = localStorage.getItem("access_token");

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await fetch(`${API_URL}/users/${id}`, {  // Change URL as needed
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setUser(data);
                } else if (response.status === 404) {
                    setNotification({ type: "danger", message: "User profile not found." });
                } else if (response.status === 401) {
                    navigate("/logout");
                } else {
                    throw new Error("Failed to fetch user profile.");
                }
            } catch (error) {
                setNotification({ type: "danger", message: "An error occurred while fetching the user profile." });
            }
            setLoading(false);
        };

        fetchUserProfile();
    }, [navigate, id, token]);

    const closeNotification = () => {
        setNotification({ type: "", message: "" });
    };

    // Handle opening confirmation dialog
    const confirmApprove = () => {
        setConfirmDialogOpen(true);
    };

    const handleConfirmApprove = async () => {
        try {
            setConfirmDialogOpen(false);
            setLoading(true);
            const response = await fetch(`${API_URL}/users/${id}/approve`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                setUser(data.user);
                setNotification({ type: "success", message: data.message });
            } else {
                const errorData = await response.json();
                throw new Error(errorData.errors ? errorData.errors : "Failed to reset user role.");
            }
        } catch (error) {
            console.error("Error removing user", error);
            setNotification({ type: "danger", message: error.message });
        } finally {
            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }
    }

    const dismissConfirmDialogue = () => {
        setConfirmDialogOpen(false);
    };

    return (
        <main>
            <nav className="level">
                <div className="level-left">
                    <div className="level-item">
                        <p className="title is-4">Member</p>
                    </div>
                </div>
                <div className="level-right">
                    <p className="level-item">
                        <Link to="/dashboard" className="button primary-bg">
                            <i className="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Back
                        </Link>
                        &nbsp; &nbsp;
                        <Link to={`/dashboard/profile/update`} className="button primary-bg">
                            <i className="fa fa-pencil" aria-hidden="true"></i> &nbsp; Edit
                        </Link>
                    </p>
                </div>
            </nav>

            {notification.message && (
                <Notification
                    type={notification.type}
                    message={notification.message}
                    onClose={closeNotification}
                />
            )}

            <div className="columns is-multiline mb-6">
                {/* User Info Section */}
                <div className="column is-12">
                    <div className="box has-text-centered">
                        <p className="title is-3">
                            {user?.first_name} {user?.last_name}
                        </p>
                        <p>
                            {user?.role && (
                                <span> <span className="tag is-info">{user?.role?.name.toUpperCase()}</span>
                                    &nbsp; &nbsp;
                                </span>
                            )}

                            {user?.status && (
                                <span className={`tag is-${user?.status === "active" ? "success" : "danger"}`}>
                                    {user?.status?.toUpperCase()}
                                </span>
                            )}
                        </p>
                    </div>
                </div>

                {/* Contact Information */}
                <div className="column is-6">
                    <div className="box">
                        <p className="title is-5">
                            <FontAwesomeIcon icon={faEnvelope} /> &nbsp; Email
                        </p>
                        <p>{user?.email || "No email available."}</p>

                        <p className="title is-5 mt-4">
                            <FontAwesomeIcon icon={faPhone} /> &nbsp; Phone
                        </p>
                        <p>{user?.phone_number || "No phone number available."}</p>
                    </div>
                </div>

                {/* Personal Information */}
                <div className="column is-6">
                    <div className="box">
                        <p className="title is-5 mb-2">
                            <FontAwesomeIcon icon={faUserTie} /> &nbsp; Occupation
                        </p>
                        <p>{user?.occupation || "Occupation not provided."}</p>

                        <p className="title is-5 mt-4 mb-2">
                            <FontAwesomeIcon icon={faRing} /> &nbsp; Marital Status
                        </p>
                        <p>{user?.marital_status || "Not provided."}</p>
                    </div>
                </div>

                {/* Spiritual Information */}
                <div className="column is-6">
                    <div className="box">
                        <p className="title is-5 mb-2">
                            <FontAwesomeIcon icon={faChurch} /> &nbsp; Place of Worship
                        </p>
                        <p>{user?.place_of_worship || "Not provided."}</p>

                        <p className="title is-5 mt-4 mb-2">
                            Pastor's Name
                        </p>
                        <p>{user?.pastors_name || "Not provided."}</p>

                        <p className="title is-5 mt-4 mb-2">
                            Pastor's Phone Number
                        </p>
                        <p>{user?.pastors_mobile_number || "Not provided."}</p>
                    </div>
                </div>

                <div className="column is-6">
                    <div className="box">
                        <p className="title is-5">
                            <FontAwesomeIcon icon={faPrayingHands} /> &nbsp; Born Again? {user?.born_again ? "Yes" : "Unspecified"}
                        </p>
                        {user?.year_born_again && (
                            <p>Year Born Again: {user?.year_born_again}</p>
                        )}
                    </div>
                    <div className="box">
                        <p className="title is-5">Date of Birth</p>
                        <p>{user?.date_of_birth || "Not provided."}</p>
                    </div>
                </div>

                {/* Additional Information */}
                <div className="column is-6">
                    <div className="box">
                        <p className="title is-5 mb-2">
                            <FontAwesomeIcon icon={faMap} /> &nbsp; Chapter
                        </p>
                        <p>{user?.chapter?.name || "Not provided."}</p>

                        <p className="title is-5 mt-5 mb-2">
                            Zone
                        </p>
                        <p>{user?.zone?.name || "Not provided."}</p>
                    </div>
                </div>

                <div className="column is-6">
                    <div className="box">
                        {user?.status === 'UnApproved' && (
                            <button className="button primary-bg" onClick={() => confirmApprove()} disabled={loading}>Approve User</button>
                        )}

                    </div>
                </div>
            </div>
            {/* ConfirmDialog Component */}
            < ConfirmDialog
                isOpen={isConfirmDialogOpen}
                theme="is-danger" // You can make this dynamic (e.g., based on action type)
                title="Approve Account"
                message={`Are you sure you want to allow '${user?.first_name}'  ${user?.last_name} to be able to access the dashboard?`}
                onConfirm={handleConfirmApprove}
                onCancel={dismissConfirmDialogue}
            />
        </main>
    );
}

export default UserView;
