import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";

function UnAuthorised() {
    const navigate = useNavigate();
    return (
        <div>
            <Header />
            <section className="hero is-fullheight has-background-light">
                <div className="hero-body has-text-centered">
                    <div className="container is-flex is-justify-content-center is-align-items-center" style={{ flexDirection: "column" }}>
                        <h1 className="title has-text-danger">
                            🚫 Access Denied! 🚫
                        </h1>
                        <h2 className="subtitle">
                            Oops! You don’t have permission to view this page.
                        </h2>
                        <p className="mt-4">
                            <img
                                src="https://media.giphy.com/media/Ju7l5y9y2r6f1h4M7A/giphy.gif"
                                alt="Access Denied GIF"
                                style={{ width: "300px", borderRadius: "10px" }}
                            />
                        </p>
                        <p className="mt-5">
                            <button className="button primary-outline-button" onClick={() => window.history.go(-3)}>
                                🔙 Go Back
                            </button>

                            &nbsp; &nbsp;
                            OR

                            &nbsp; &nbsp;
                            <button className="button primary-outline-button" onClick={() => navigate('/')}>
                                🏠 Go Home
                            </button>
                        </p>
                        <p className="mt-4">
                            If you believe this is a mistake, please contact support.
                        </p>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default UnAuthorised;
