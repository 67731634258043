import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../../config.js";
import Notification from "../../components/Notification.js";
import ConfirmDialog from "../../components/ConfirmDialog";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock,faCalendarAlt,faUsers,  faInfoCircle,  } from "@fortawesome/free-solid-svg-icons";

function BookingDetails() {
    const [booking, setBooking] = useState({
        id: "",
        customer_name: "",
        booking_date: "",
        status: "",
        total_amount: 0,
        details: [],
    });
    const [loading, setLoading] = useState(true);
    const [notification, setNotification] = useState({ type: "", message: "" });
    const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [confirmAction, setConfirmAction] = useState("");
    const [confirmDialogTitle, setConfirmDialogTitle] = useState("");
    const [confirmDialogMessage, setConfirmDialogMessage] = useState("");
    const navigate = useNavigate();
    const { id } = useParams();

    const fetchBooking = useCallback(async () => {
        try {
            const response = await fetch(`${API_URL}/bookings/id/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                setBooking(data);
            } else if (response.status === 404) {
                setNotification({ type: "danger", message: "Booking not found." });
            } else if (response.status === 401) {
                navigate("/logout");
            } else {
                throw new Error("Failed to fetch booking.");
            }
        } catch (error) {
            setNotification({ type: "danger", message: "An error occurred while fetching the booking." });
        } finally {
            setLoading(false);
        }
    }, [id, navigate]);

    useEffect(() => {
        fetchBooking();
    }, [fetchBooking]);

    const closeNotification = () => {
        setNotification({ type: "", message: "" });
    };

    const handleAction = (actionType) => {
        setConfirmAction(actionType);

        let title = "Confirm Action";
        let message = `Are you sure you want to ${actionType} this booking?`;

        if (actionType === "cancel") {
            title = "Cancel Booking";
            message = "Are you sure you want to cancel this booking?";
        }

        setConfirmDialogTitle(title);
        setConfirmDialogMessage(message);
        setConfirmDialogOpen(true);
    };

    const handleConfirmation = async () => {
    }
    const confirmActionHandler = async () => {
        let endpoint = `${API_URL}/bookings/${id}`;

        if (confirmAction === "cancel") {
            endpoint += "/cancel";
        }

        try {
            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            });

            if (response.ok) {
                setNotification({ type: "success", message: `Booking ${confirmAction}ed successfully.` });
                await fetchBooking(); // Refresh the booking details
            } else {
                setNotification({ type: "danger", message: `Failed to ${confirmAction} the booking.` });
            }
        } catch (error) {
            setNotification({ type: "danger", message: "An error occurred while processing the request." });
        } finally {
            setConfirmDialogOpen(false);
        }
    };

    if (loading) return <p>Loading...</p>;

    return (
        <main>
            <nav className="level">
                <div className="level-left">
                    <div className="level-item">
                        <p className="title">Booking Details</p>
                    </div>
                </div>
                <div className="level-right">
                    <p className="level-item">
                        <Link to="/dashboard/bookings" className="button primary-bg">
                            <i className="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Back
                        </Link>
                        &nbsp; &nbsp;
                        <Link to={`/dashboard/bookings/${booking.id}/update`} className="button primary-bg">
                            <i className="fa fa-pencil" aria-hidden="true"></i> &nbsp; Edit
                        </Link>
                    </p>
                </div>
            </nav>

            {notification.message && (
                <Notification
                    type={notification.type}
                    message={notification.message}
                    onClose={closeNotification}
                />
            )}

            <ConfirmDialog
                isOpen={isConfirmDialogOpen}
                theme="is-primary"
                title={confirmDialogTitle}
                message={confirmDialogMessage}
                onConfirm={handleConfirmation}
                onCancel={() => setConfirmDialogOpen(false)}
            />

            <div className="columns is-multiline mb-6">
                {/* Booking Header */}
                <div className="column is-12">
                    <div className="box has-text-centered">
                        <p className="title is-3">{booking.name}</p>
                        {booking.status && (
                            <p className={`tag has-text-weight-bold is-${booking.status === "confirmed" ? "success" : "danger"}`}>
                                {booking.status.toUpperCase()}
                            </p>
                        )}
                        <p>
                            Booked by: {booking.email} ({booking.phone_number})
                        </p>
                    </div>
                </div>

                {/* Dates and People */}
                <div className="column is-6">
                    <div className="box">
                        <p className="title is-5">
                            <FontAwesomeIcon icon={faCalendarAlt} /> &nbsp; Dates
                        </p>
                        <p>
                            <FontAwesomeIcon icon={faClock} /> &nbsp;
                            From <strong>{booking.start_date}</strong> to <strong>{booking.end_date}</strong>
                        </p>
                    </div>
                </div>
                <div className="column is-6">
                    <div className="box">
                        <p className="title is-5">
                            <FontAwesomeIcon icon={faUsers} /> &nbsp; Number of People
                        </p>
                        <p><strong>{booking.number_of_people}</strong></p>
                    </div>
                </div>

                {/* Special Requests */}
                <div className="column is-12">
                    <div className="box">
                        <p className="title is-5">
                            <FontAwesomeIcon icon={faInfoCircle} /> &nbsp; Special Requests
                        </p>
                        <p>{booking.special_requests || "No special requests."}</p>
                    </div>
                </div>

                {/* Payment Details */}
                <div className="column is-12">
                    <div className="box">
                        <p className="title is-5">
                            <FontAwesomeIcon icon={faInfoCircle} /> &nbsp; Payment Details
                        </p>
                        <p>
                            <strong>Amount Paid:</strong> {booking.amount_paid ? `KSh ${booking.amount_paid}` : "Not Paid"} <br />
                            <strong>Payment Reference:</strong> {booking.payment_reference || "N/A"}
                        </p>
                    </div>
                </div>

                {/* Reason for Rejection */}
                {booking.status === "rejected" && (
                    <div className="column is-12">
                        <div className="box">
                            <p className="title is-5">
                                <FontAwesomeIcon icon={faInfoCircle} /> &nbsp; Reason for Rejection
                            </p>
                            <p>{booking.reason_for_rejection || "No reason provided."}</p>
                        </div>
                    </div>
                )}
            </div>
        </main>
    );
}

export default BookingDetails;
