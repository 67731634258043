import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { API_URL } from "../../config.js";
import Notification from "../../components/Notification.js";
import UsersDropdownField from "../../components/UsersDropdownField.js";

function EventUpdate() {
    const [event, setEvent] = useState({
        name: '',
        start_date: '',
        start_time: '',
        end_date: '',
        end_time: '',
        venue: '',
        google_map_pin: '',
        status: 'active',
        image: null,
        require_registration: null,
        payment_option: null,
        mpesa_phone_number: null,
        mpesa_display_name: null,
        payment_description: null,
        registration_fee: 0,
        post_publicly: 0,
        contact_person_id: ''
    });

    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [notification, setNotification] = useState({ type: "", message: "" });
    const [isEditing, setIsEditing] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const token = localStorage.getItem("access_token");
    const [allUsers, setAllUsers] = useState([]);
    const [paymentOptions, setPaymentOptions] = useState([]);

    useEffect(() => {
        const fetchEvent = async () => {
            if (id) {
                try {
                    const response = await fetch(`${API_URL}/events/${id}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    if (response.ok) {
                        const data = await response.json();
                        setEvent(data);
                        setIsEditing(true);
                    } else if (response.status === 404) {
                        setNotification({ type: "danger", message: "Event not found." });
                    } else if (response.status === 401) {
                        navigate("/logout");
                    } else {
                        throw new Error("Failed to fetch event.");
                    }
                } catch (error) {
                    setNotification({ type: "danger", message: "An error occurred while fetching the event." });
                }
            }
        };

        const fetchAllUsers = async () => {
            try {
                const response = await fetch(`${API_URL}/users`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setAllUsers(data);
                } else if (response.status === 404) {
                    setNotification({ type: "danger", message: "Users not found." });
                } else if (response.status === 401) {
                    navigate("/logout");
                } else {
                    throw new Error("Failed to fetch users.");
                }

            } catch (error) {
                setNotification({ type: "danger", message: "An error occured while fetching users" });
            }
        };

        // Fetch payment options
        const fetchPaymentOptions = async () => {
            try {
                const response = await fetch(`${API_URL}/events/payment-options`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setPaymentOptions(data.payment_options || []);
                } else if (response.status === 404) {
                    setNotification({ type: "danger", message: "Payment options not found." });
                } else if (response.status === 401) {
                    navigate("/logout");
                } else {
                    throw new Error("Failed to fetch payment options.");
                }

            } catch (error) {
                console.error("Failed to fetch payment options", error);
                setNotification({ type: "danger", message: "Failed to load payment options" });
            }
        };

        const fetchData = async () => {
            await fetchEvent();
            await fetchAllUsers();
            await fetchPaymentOptions();
            setLoading(false);
        };

        fetchData();
    }, [id, navigate, token]);

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setEvent((prevEvent) => ({
            ...prevEvent,
            [name]: type === "checkbox" ? checked : value || "",
        }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "", }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];  // Get the actual file object
        setEvent((prevEvent) => ({
            ...prevEvent,
            image: file,  // Store the file object in event state
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();  // Prevent default form submission (GET request)
        setLoading(true);
        // Clear previous errors before starting validation
        setErrors({});
        // Google Map Pin validation
        if (event.google_map_pin) {
            const googleMapPinRegex = /^https:\/\/maps\.app\.goo\.gl\/[A-Za-z0-9]+$/;
            if (!googleMapPinRegex.test(event.google_map_pin)) {
                setErrors((prevErrors) => ({ ...prevErrors, google_map_pin: ["Invalid Google Map Pin format."] }));
                setLoading(false);
                console.log(errors)
                return;
            }
        }

        if (event.registration_fee < 0) {
            setErrors((prevErrors) => ({ ...prevErrors, registration_fee: ["Invalid Registration Fee."] }));
            console.log(errors)
            setLoading(false);
            return;
        }

        if (event.require_registration === null) {
            setErrors((prevErrors) => ({ ...prevErrors, require_registration: ["This cannot be null."] }));
            console.log(errors)
            setLoading(false);
            return;
        }

        if (!event.require_registration) {
            event.registration_fee = null;
        }
        
        if (event.payment_option !== "Mpesa Send Money") {
            event.mpesa_display_name = "";
            event.mpesa_phone_number = "";
        }

        const method = isEditing ? "POST" : "POST";
        const url = isEditing ? `${API_URL}/events/${id}/update` : `${API_URL}/events`;

        try {
            // Use FormData to handle file upload and other fields
            const formData = new FormData();

            for (const key in event) {
                if (event[key] === null || event[key] === "") {
                    formData.delete(key);
                } else {
                    formData.append(key, event[key]);
                }
            }

            // if (event.payment_option !== "Mpesa Send Money") {
            //     formData["mpesa_display_name"] = "";
            //     formData["mpesa_phone_number"] = "";
            // }

            // Check if an image file exists
            if (event.image && event.image instanceof File) {
                formData.append('image', event.image);
            } else {
                formData.delete('image');  // Remove the image field if no file is selected
            }


            const response = await fetch(url, {
                method: method,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
                setNotification({ message: "Event successfully saved.", type: "success" });
                if (data && data.id) {
                    navigate(`/dashboard/events/${data.id}/details`);
                }
            } else if (response.status === 401) {
                navigate("/logout");
            } else {
                const errorData = await response.json();
                setErrors(errorData || {});
                setNotification({ message: errorData.message || "An error occurred.", type: "danger" });
            }
            setLoading(false);
        } catch (error) {
            setNotification({ message: "An error occurred while saving the event.", type: "danger" });
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    };

    const closeNotification = () => {
        setNotification({ type: "", message: "" });
    };

    if (loading) return <p>Loading...</p>;

    return (
        <main>
            <nav className="level">
                <div className="level-left">
                    <div className="level-item">
                        <p className="title">
                            {isEditing ? "Update Event" : "Add Event"}
                        </p>
                    </div>
                </div>
                <div className="level-right">
                    <p className="level-item">
                        <Link to="/dashboard/events" className="button primary-bg">
                            <i className="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Back
                        </Link>
                        &nbsp;
                        &nbsp;
                        <a
                            href={`/dashboard/events/${event.id}/details`}
                            className="button primary-bg"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <i className="fa fa-eye" aria-hidden="true"></i> &nbsp; View
                        </a>
                    </p>
                </div>
            </nav>
            <div className="column dash-section is-loading">
                {notification.message && (
                    <Notification
                        type={notification.type}
                        message={notification.message}
                        onClose={closeNotification}
                    />
                )}

                <form onSubmit={handleSubmit}>
                    <div className="columns">
                        <div className="column is-half">
                            {/* Name Field */}
                            <div className="field">
                                <label className="label">Event Name</label>
                                <div className="control">
                                    <input
                                        className={`input ${errors.name ? "is-danger" : ""}`}
                                        type="text"
                                        name="name"
                                        value={event.name}
                                        onChange={handleChange}
                                        placeholder="Event Name"
                                        required
                                    />
                                </div>
                                {errors.name && errors.name.map((error, index) => (
                                    <p key={index} className="help is-danger">{error}</p>
                                ))}
                            </div>

                            {/* Start Date Field */}
                            <div className="columns">
                                <div className="column is-half">
                                    <div className="field">
                                        <label className="label">Start Date</label>
                                        <div className="control">
                                            <input
                                                className={`input ${errors.start_date ? "is-danger" : ""}`}
                                                type="date"
                                                name="start_date"
                                                value={event.start_date}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        {errors.start_date && errors.start_date.map((error, index) => (
                                            <p key={index} className="help is-danger">{error}</p>
                                        ))}
                                    </div>
                                </div>
                                <div className="column is-half">
                                    {/* Start Time Field */}
                                    <div className="field">
                                        <label className="label">Start Time (24hr System)</label>
                                        <div className="control">
                                            <input
                                                className={`input ${errors.start_time ? "is-danger" : ""}`}
                                                type="time"
                                                name="start_time"
                                                value={event.start_time}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        {errors.start_time && errors.start_time.map((error, index) => (
                                            <p key={index} className="help is-danger">{error}</p>
                                        ))}
                                    </div>

                                </div>
                            </div>


                            <div className="columns">
                                <div className="column is-half">
                                    {/* End Date Field */}
                                    <div className="field">
                                        <label className="label">End Date</label>
                                        <div className="control">
                                            <input
                                                className={`input ${errors.end_date ? "is-danger" : ""}`}
                                                type="date"
                                                name="end_date"
                                                value={event.end_date}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        {errors.end_date && errors.end_date.map((error, index) => (
                                            <p key={index} className="help is-danger">{error}</p>
                                        ))}
                                    </div>
                                </div>
                                <div className="column is-half">
                                    {/* End Time Field */}
                                    <div className="field">
                                        <label className="label">End Time (24hr System)</label>
                                        <div className="control">
                                            <input
                                                className={`input ${errors.end_time ? "is-danger" : ""}`}
                                                type="time"
                                                name="end_time"
                                                value={event.end_time}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        {errors.end_time && errors.end_time.map((error, index) => (
                                            <p key={index} className="help is-danger">{error}</p>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            {/* Venue Field */}
                            <div className="field">
                                <label className="label">Venue</label>
                                <div className="control">
                                    <input
                                        className={`input ${errors.venue ? "is-danger" : ""}`}
                                        type="text"
                                        name="venue"
                                        value={event.venue}
                                        onChange={handleChange}
                                        placeholder="Event Venue"
                                    />
                                </div>
                                {errors.venue && errors.venue.map((error, index) => (
                                    <p key={index} className="help is-danger">{error}</p>
                                ))}
                            </div>

                            {/* Google Map Pin Field */}
                            <div className="field">
                                <label className="label">Google Map Pin</label>
                                <div className="control">
                                    <input
                                        className={`input ${errors.google_map_pin ? "is-danger" : ""}`}
                                        type="text"
                                        name="google_map_pin"
                                        value={event.google_map_pin}
                                        onChange={handleChange}
                                        placeholder="Google Map Pin URL"
                                    />
                                </div>
                                {errors.google_map_pin && errors.google_map_pin.map((error, index) => (
                                    <p key={index} className="help is-danger">{error}</p>
                                ))}
                            </div>

                            {/* Contact Person Field */}
                            <div className="field">
                                <div className="control">
                                    <UsersDropdownField
                                        label="Contact Person"
                                        name="contact_person_id"
                                        value={event.contact_person_id}
                                        onChange={handleChange}
                                        options={allUsers}
                                        errors={errors.contact_person_id}
                                        placeholder="Select a contact person"
                                    />
                                </div>
                                {errors.contact_person_id && errors.contact_person_id.map((error, index) => (
                                    <p key={index} className="help is-danger">{error}</p>
                                ))}
                            </div>
                        </div>

                        <div className="column is-half">
                            {/* Description Textarea */}
                            <div className="field">
                                <label className="label">Description</label>
                                <div className="control">
                                    <textarea
                                        className={`textarea ${errors.description ? "is-danger" : ""}`}
                                        name="description"
                                        value={event.description}
                                        onChange={handleChange}
                                        placeholder="Description"
                                    />
                                </div>
                                {errors.description && errors.description.map((error, index) => (
                                    <p key={index} className="help is-danger">{error}</p>
                                ))}
                            </div>

                            {/* Image Field */}
                            <div className="field">
                                <label className="label">Image</label>
                                <div className="control">

                                    <input className={`input ${errors.image ? "is-danger" : ""}`} type="file" name="image" onChange={handleFileChange} placeholder="Event Image URL" />
                                </div>
                                {errors.image && errors.image.map((error, index) => (
                                    <p key={index} className="help is-danger">{error}</p>
                                ))}
                            </div> {/* Image Field */}

                            {/* Require Registration Field */}
                            <div className="field">
                                <label className="label">Require Registration?</label>
                                <div className="control">
                                    <label className="radio">
                                        <input
                                            id="registration-yes"
                                            type="radio"
                                            name="require_registration"
                                            value="1"
                                            checked={event.require_registration === true}
                                            onChange={() => setEvent((prevEvent) => ({ ...prevEvent, require_registration: true }))}
                                            className={`${errors.require_registration ? "is-danger" : ""}`}
                                        /> Yes
                                    </label>
                                    &nbsp; &nbsp;
                                    <label className="radio">
                                        <input
                                            id="registration-no"
                                            type="radio"
                                            name="require_registration"
                                            value="0"
                                            checked={event.require_registration === false}
                                            onChange={() => setEvent((prevEvent) => ({ ...prevEvent, require_registration: false }))}
                                            className={`${errors.require_registration ? "is-danger" : ""}`}
                                        /> No
                                    </label>
                                </div>
                                {errors.require_registration && errors.require_registration.map((error, index) => (
                                    <p key={index} className="help is-danger">{error}</p>
                                ))}
                            </div>

                            {/* Registration Fee Field */}
                            {event.require_registration && (
                                <div className="field">
                                    <label className="label"> Registration Fee (KSh.)</label>
                                    <div className="control">
                                        <input
                                            className={`input ${errors.registration_fee ? "is-danger" : ""}`}
                                            type="number"
                                            min="0"
                                            name="registration_fee"
                                            value={event.registration_fee}
                                            onChange={handleChange}
                                            placeholder="e.g 200"
                                        />
                                    </div>
                                    {errors.registration_fee && errors.registration_fee.map((error, index) => (
                                        <p key={index} className="help is-danger">{error}</p>
                                    ))}
                                </div>
                            )}

                            {/* Payment option */}
                            {(event.registration_fee > 0 && event.require_registration) && (
                                <div className="field">
                                    <label className="label"> Payment Option</label>
                                    <div className="control">
                                        <div className={`select is-fullwidth ${errors.payment_option ? "is-danger" : ""}`}>
                                            <select
                                                name="payment_option"
                                                value={event.payment_option}
                                                onChange={handleChange}
                                            >
                                                <option value="" disabled>Select a Payment Option </option>
                                                {paymentOptions.map((paymentOption, index) => (
                                                    <option key={index} value={paymentOption}>
                                                        {paymentOption}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    {errors.payment_option && errors.payment_option.map((error, index) => (
                                        <p key={index} className="help is-danger">{error}</p>
                                    ))}
                                </div>
                            )}

                            {/* Mpesa phone number */}
                            {(event.registration_fee > 0 && event.require_registration && event.payment_option === "Mpesa Send Money") && (
                                <div className="field">
                                    <label className="label"> Mpesa Phone Number</label>
                                    <div className="control">
                                        <input
                                            className={`input ${errors.mpesa_phone_number ? "is-danger" : ""}`}
                                            type="text"
                                            min="0"
                                            name="mpesa_phone_number"
                                            value={event.mpesa_phone_number}
                                            onChange={handleChange}
                                            placeholder="e.g 0712345678"
                                        />
                                    </div>
                                    {errors.mpesa_phone_number && errors.mpesa_phone_number.map((error, index) => (
                                        <p key={index} className="help is-danger">{error}</p>
                                    ))}
                                </div>

                            )}

                            {/* Mpesa Display Name */}
                            {(event.registration_fee > 0 && event.require_registration && event.payment_option === "Mpesa Send Money") && (
                                <div className="field">
                                    <label className="label"> Mpesa Name</label>
                                    <div className="control">
                                        <input
                                            className={`input ${errors.mpesa_display_name ? "is-danger" : ""}`}
                                            type="text"
                                            min="0"
                                            name="mpesa_display_name"
                                            value={event.mpesa_display_name}
                                            onChange={handleChange}
                                            placeholder="e.g John Doe"
                                        />
                                    </div>
                                    {errors.mpesa_display_name && errors.mpesa_display_name.map((error, index) => (
                                        <p key={index} className="help is-danger">{error}</p>
                                    ))}
                                </div>

                            )}


                            {/* Additional Payment Details text area */}
                            {(event.registration_fee > 0 && event.require_registration) && (
                                <div className="field">
                                    <label className="label">Additional Payment Details</label>
                                    <div className="control">
                                        <textarea
                                            className={`textarea ${errors.payment_description ? "is-danger" : ""}`}
                                            name="payment_description"
                                            value={event.payment_description}
                                            onChange={handleChange}
                                            placeholder="Enter additional details about payment"
                                            rows="2"
                                        />
                                    </div>
                                    {errors.payment_description && errors.payment_description.map((error, index) => (
                                        <p key={index} className="help is-danger">{error}</p>
                                    ))}
                                </div>
                            )}

                            {/* Post Publicly Field */}
                            <div className="field">
                                <label className="label">Post Publicly?</label>
                                <div className="control">
                                    <label className="radio">
                                        <input
                                            type="radio"
                                            name="post_publicly"
                                            value="1"
                                            checked={event.post_publicly === true || event.post_publicly === 1}
                                            onChange={() => setEvent((prevEvent) => ({ ...prevEvent, post_publicly: 1 }))}
                                        /> Yes
                                    </label>
                                    &nbsp; &nbsp;
                                    <label className="radio">
                                        <input
                                            type="radio"
                                            name="post_publicly"
                                            value="0"
                                            checked={event.post_publicly === false || event.post_publicly === 0}
                                            onChange={() => setEvent((prevEvent) => ({ ...prevEvent, post_publicly: 0 }))}
                                        /> No
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="columns">
                        <div className="column is-half">
                        </div>
                        <div className="column is-half">
                            <div className="field">
                                <div className="control">
                                    <button type="submit" className="button is-primary is-pulled-right is-fullwidth" disabled={loading}>
                                        {isEditing ? "UPDATE EVENT" : "CREATE EVENT"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </main>
    );
}

export default EventUpdate;
