import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../../config.js";
import ConfirmDialog from "../../components/ConfirmDialog.js";
import Notification from "../../components/Notification.js";
import { formatTimestamp } from "../../utils/TextUtils.js";

function EventRegistrationList() {
    const [registrations, setRegistrations] = useState([]);
    const [filteredRegistrations, setFilteredRegistrations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [isDropdownOpen, setIsDropdownOpen] = useState(null);
    const [event, setEvent] = useState(null);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [selectedRegistration, setSelectedRegistration] = useState(null);
    const [notification, setNotification] = useState({ type: "", message: "" });
    const statusTagClasses = {
        Registered: "tag is-info",
        cancelled: "tag is-dark",
        rejected: "tag is-danger",
        'Pending Payment': "tag is-danger"
    };

    const navigate = useNavigate();

    const { id } = useParams();  // eventId

    const fetchRegistrations = useCallback(async () => {
        const token = localStorage.getItem("access_token");

        try {
            const response = await fetch(`${API_URL}/events/${id}/registrations`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                setRegistrations(data.registrations);
                setFilteredRegistrations(data.registrations);
                setEvent(data.event);
            } else if (response.status === 401) {
                navigate("/logout");
            } else {
                throw new Error("Failed to fetch registrations");
            }
        } catch (error) {
            setError("An error occurred while fetching registrations.");
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    }, [navigate, id]);

    const toggleDropdown = (index) => {
        if (isDropdownOpen === index) {
            setIsDropdownOpen(null); // Close dropdown if the same one is clicked
        } else {
            setIsDropdownOpen(index); // Open the clicked dropdown
        }
    };

    const handleDeleteClick = (registration) => {
        setSelectedRegistration(registration);
        setIsConfirmDialogOpen(true);
    };

    const confirmDeleteEvent = async () => {
        if (selectedRegistration) {
            try {
                const token = localStorage.getItem("access_token");
                const response = await fetch(`${API_URL}/event-registrations/${selectedRegistration.id}`, {
                    method: 'DELETE',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    setNotification({ type: "success", message: 'Event deleted successfully' });
                    fetchRegistrations(); // Refresh the list
                } else if (response.status === 401) {
                    navigate("/logout");
                } else if (response.status === 404) {
                    setNotification({ type: "danger", message: 'Event not found.' });
                } else {
                    setNotification({ type: "danger", message: 'There was an error.' });
                    throw new Error('Failed to delete registration');
                }
            } catch (error) {
                setNotification({ message: 'An error occurred while deleting the registration.', type: 'danger' });
                console.error("Error:", error);
            } finally {
                setIsConfirmDialogOpen(false);
                setSelectedRegistration(null);
            }
        }
    };

    const cancelDeleteEvent = () => {
        setIsConfirmDialogOpen(false);
        setSelectedRegistration(null);
    };

    const closeNotification = () => {
        setNotification({ type: "", message: "" });
    };

    useEffect(() => {
        fetchRegistrations();
        const handleClickOutside = (e) => {
            if (isDropdownOpen !== null) {
                const dropdown = document.querySelector(`.dropdown.is-active`);
                if (dropdown && !dropdown.contains(e.target)) {
                    setIsDropdownOpen(null);
                }
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [isDropdownOpen, fetchRegistrations]);

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchTerm(value);

        const filtered = registrations.filter(e =>
            e.user.first_name.toLowerCase().includes(value) ||
            (e.user.last_name || "").toLowerCase().includes(value) ||
            (e.user.email || "").toLowerCase().includes(value) ||
            (e.user.phone || "").toLowerCase().includes(value)
        );
        setFilteredRegistrations(filtered);
    };

    return (
        <div>
            <main>
                <nav className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <p className="title">{event?.name} Registration</p>
                        </div>
                    </div>
                    <div className="level-right">
                        <p className="level-item">
    
                            <Link to={`/dashboard/events/${id}/details`} className="button primary-bg">
                                <i className="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Back
                            </Link>
                        </p>
                    </div>
                </nav>

                <h1>Event Registration List</h1>

                {notification.message && (
                    <Notification
                        type={notification.type}
                        message={notification.message}
                        onClose={closeNotification}
                    />
                )}

                <div className="field mt-2">
                    <div className="control">
                        <input
                            className="input"
                            type="text"
                            placeholder="Search by event name or location"
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                    </div>
                </div>

                {loading ? (
                    <p>Loading registrations...</p>
                ) : error ? (
                    <p className="has-text-danger">{error}</p>
                ) : (
                    <table className="table is-striped is-fullwidth">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Phone</th>
                                <th>Email</th>
                                <th>Status</th>
                                <th>Time</th>
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredRegistrations.length > 0 ? (
                                filteredRegistrations.map((registration, index) => (
                                    <tr key={registration.id}>
                                        <td>{index + 1}</td>
                                        <td>{registration.user.first_name} {registration.user.last_name}</td>
                                        <td>{registration.user.phone} </td>
                                        <td>{registration.user.email} </td>
                                        <td> <span className={`has-text-weight-bold ${statusTagClasses[registration?.status]}` || "tag"}>
                                            {registration?.status?.toUpperCase()}</span>
                                        </td>
                                        <td>{registration.created_at && (formatTimestamp(registration.created_at) || " ")}</td>
                                        <td>
                                            <div className={`dropdown is-right ${isDropdownOpen === index ? 'is-active' : ''}`}>
                                                <div className="dropdown-trigger">
                                                    <button
                                                        className="button is-white"
                                                        aria-haspopup="true"
                                                        aria-controls="dropdown-menu"
                                                        onClick={() => toggleDropdown(index)}
                                                    >
                                                        <span className="icon">
                                                            <i className="fas fa-ellipsis-v"></i>
                                                        </span>
                                                    </button>
                                                </div>
                                                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                                                    <div className="dropdown-content">

                                                        {event.require_registration && (
                                                            <Link to="" className="dropdown-item">
                                                                <span className="icon">
                                                                    <i className="fas fa-list"></i>
                                                                </span>
                                                                <span>Register</span>
                                                            </Link>
                                                        )}
                                                        <Link to={`${registration.id}/update`} className="dropdown-item">
                                                            <span className="icon">
                                                                <i className="fas fa-edit"></i>
                                                            </span>
                                                            <span>Edit</span>
                                                        </Link>
                                                        <Link className="dropdown-item" onClick={() => handleDeleteClick(registration)}>
                                                            <span className="icon">
                                                                <i className="fas fa-trash-alt"></i>
                                                            </span>
                                                            <span>Remove</span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>

                                        {/* Confirmation Dialog */}
                                        <ConfirmDialog
                                            isOpen={isConfirmDialogOpen}
                                            theme="is-danger"
                                            title="Delete Confirmation"
                                            message={`Are you sure you want to delete ${selectedRegistration?.name}?`}
                                            onConfirm={confirmDeleteEvent}
                                            onCancel={cancelDeleteEvent}
                                        />
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="7" className="has-text-centered">No registrations found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                )}
            </main>
        </div>
    );
}

export default EventRegistrationList;
