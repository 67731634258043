import React from 'react';
import { Navigate } from 'react-router-dom';

// This function checks if the user's role is allowed
const checkPermission = (userRole, allowedRoles) => {
    const isAllowed = allowedRoles.includes(userRole);
    return isAllowed;
};

const ProtectedRoute = ({ children, allowedRoles }) => {
  const token = localStorage.getItem("access_token");
  if (!token) {
    return <Navigate to="/login" />;
  }
  
  const userRole = localStorage.getItem('userRole')?.trim().replace(/^"|"$/g, '').toLowerCase(); // Remove surrounding quotes if any

  if (!userRole || !checkPermission(userRole, allowedRoles)) {
    return <Navigate to="/un-authorized" />;
  }

  return children;
};

export default ProtectedRoute;
