import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Notification from "../components/Notification";
import '../styles/SignIn.css';
import { API_URL } from "../config";

function SignUp() {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [notification, setNotification] = useState({ type: "", message: "", isVisible: false });
    // const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [successful, setSuccessful] = useState(false);

    // Validation states
    const [errors, setErrors] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        repeatPassword: ""
    });

    const validate = () => {
        let isValid = true;
        const errors = {};

        if (!firstName) {
            errors.firstName = "First name is required.";
            isValid = false;
        }

        if (!lastName) {
            errors.lastName = "Last name is required.";
            isValid = false;
        }

        if (!email) {
            errors.email = "Email is required.";
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = "Email is invalid.";
            isValid = false;
        }

        if (!password) {
            errors.password = "Password is required.";
            isValid = false;
        } else if (password.length < 8) {
            errors.password = "Password must be at least 8 characters.";
            isValid = false;
        }

        if (password !== repeatPassword) {
            errors.repeatPassword = "Passwords do not match.";
            isValid = false;
        }

        setErrors(errors);
        return isValid;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setErrors({});

        if (!termsAccepted) {
            setNotification({ type: "warning", message: "Please accept the Terms and Conditions.", isVisible: true });
            return;
        }

        if (validate()) {
            try {
                const response = await fetch(API_URL + "/auth/register", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        first_name: firstName,
                        last_name: lastName,
                        email: email,
                        password: password,
                        password_confirmation: repeatPassword
                    }),
                });

                const data = await response.json();

                if (response.ok) {
                    setNotification({ type: "success", message: data.message, isVisible: true });
                    setSuccessful(true);
                    setFirstName("");
                    setLastName("");
                    setEmail("");
                    setPassword("");
                    setRepeatPassword("");
                    /* setTimeout(() => {
                        navigate("/login"); // Redirect to login page
                    }, 5000); // Optional delay for the notification to be visible */
                } else {
                    setNotification({ type: "danger", message: data.message || "Registration failed.", isVisible: true });
                    // Handle error mapping
                    if (data.email) {
                        setErrors(prevErrors => ({
                            ...prevErrors,
                            email: data.email[0], 
                        }));
                    }
                    setLoading(false)
                }

            } catch (error) {
                console.log(error);
                setNotification({ type: "danger", message: "An error occurred. Please try again later.", isVisible: true });
            } finally {
                setLoading(false);
            }

        } else {
            setNotification({ type: "danger", message: "Please fix the errors in the form.", isVisible: true });
            setLoading(false)
        }
    };

    const handleTermsAccepted = () => {
        setTermsAccepted(!termsAccepted);
    };

    const handleCloseNotification = () => {
        setNotification({ ...notification, isVisible: false });
    };

    return (
        <div>
            <Header />
            <main>
                <div className="signin-page mb-4">
                    <div className="siginin-bg"></div>
                    <div className="signin-card">
                        <div className="signin-content">
                            <div className="media pb-4 mb-4 is-justify-content-space-between">
                                <div className="media-left">
                                    <Link to="/login"><i className="fa fa-arrow-left"></i> &nbsp; To Log In</Link>
                                </div>
                                <div className="media-right">
                                    <Link to="/"><i className="fa-solid fa-x"></i></Link>
                                </div>
                            </div>
                            <div className="content">
                                <h1 className="sign-in-title">Register</h1>
                                <p>Register for CFCM</p>
                                {notification.isVisible && (
                                    <Notification
                                        type={notification.type}
                                        message={notification.message}
                                        onClose={handleCloseNotification}
                                    />
                                )}
                                <form onSubmit={handleSubmit}>
                                    <div className={`field ${errors.firstName ? 'is-danger' : ''}`}>
                                        <label className="label">First Name</label>
                                        <div className="control has-icons-left has-icons-right">
                                            <input
                                                className={`input ${errors.firstName ? 'is-danger' : ''}`}
                                                type="text"
                                                placeholder="Enter your first name"
                                                value={firstName}
                                                onChange={(e) => setFirstName(e.target.value)}
                                            />
                                            <span className="icon is-small is-left">
                                                <i className="fas fa-user"></i>
                                            </span>
                                        </div>
                                        {errors.firstName && <p className="help is-danger">{errors.firstName}</p>}
                                    </div>

                                    <div className={`field ${errors.lastName ? 'is-danger' : ''}`}>
                                        <label className="label">Last Name</label>
                                        <div className="control has-icons-left has-icons-right">
                                            <input
                                                className={`input ${errors.lastName ? 'is-danger' : ''}`}
                                                type="text"
                                                placeholder="Enter your last name"
                                                value={lastName}
                                                onChange={(e) => setLastName(e.target.value)}
                                            />
                                            <span className="icon is-small is-left">
                                                <i className="fas fa-user"></i>
                                            </span>
                                        </div>
                                        {errors.lastName && <p className="help is-danger">{errors.lastName}</p>}
                                    </div>

                                    <div className={`field ${errors.email ? 'is-danger' : ''}`}>
                                        <label className="label">Email</label>
                                        <div className="control has-icons-left has-icons-right">
                                            <input
                                                className={`input ${errors.email ? 'is-danger' : ''}`}
                                                type="email"
                                                placeholder="Email input"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            <span className="icon is-small is-left">
                                                <i className="fas fa-envelope"></i>
                                            </span>
                                            {errors.email && (
                                                <span className="icon is-small is-right">
                                                    <i className="fas fa-exclamation-triangle"></i>
                                                </span>
                                            )}
                                        </div>
                                        {errors.email && <p className="help is-danger">{errors.email}</p>}
                                    </div>

                                    <div className={`field ${errors.password ? 'is-danger' : ''}`}>
                                        <label className="label">Password</label>
                                        <div className="control has-icons-left has-icons-right">
                                            <input
                                                className={`input ${errors.password ? 'is-danger' : ''}`}
                                                type="password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                placeholder="Enter password"
                                            />
                                            <span className="icon is-small is-left">
                                                <i className="fas fa-lock"></i>
                                            </span>
                                        </div>
                                        {errors.password && <p className="help is-danger">{errors.password}</p>}
                                    </div>

                                    <div className={`field ${errors.repeatPassword ? 'is-danger' : ''}`}>
                                        <label className="label">Repeat Password</label>
                                        <div className="control has-icons-left has-icons-right">
                                            <input
                                                className={`input ${errors.repeatPassword ? 'is-danger' : ''}`}
                                                type="password"
                                                value={repeatPassword}
                                                onChange={(e) => setRepeatPassword(e.target.value)}
                                                placeholder="Re-enter password"
                                            />
                                            <span className="icon is-small is-left">
                                                <i className="fas fa-lock"></i>
                                            </span>
                                        </div>
                                        {errors.repeatPassword && <p className="help is-danger">{errors.repeatPassword}</p>}
                                    </div>

                                    <div className="field mb-5">
                                        <div className="control">
                                            <label className="checkbox">
                                                <input type="checkbox" checked={termsAccepted} onChange={handleTermsAccepted} />
                                                &nbsp; I agree to the <Link to="#">Terms and Conditions</Link>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="field">
                                        <div className="control">
                                            <button type="submit" className="button is-fullwidth primary-bg" disabled={!termsAccepted || loading || successful}>
                                                {loading ? "Loading..." : "Register"}</button>
                                        </div>
                                    </div>
                                </form>
                                <div className="field mb-5 mt-4">
                                    <div className="control">
                                        <label>
                                            Already have an account? &nbsp;
                                            <Link to="/login">Log In Here.</Link>
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default SignUp;
