import React, { useCallback, useEffect, useState } from "react";
import { API_URL } from "../../config";
import Notification from "../../components/Notification";
import { useNavigate, Link } from "react-router-dom";
import UsersDropdownField from "../../components/UsersDropdownField.js";
import ConfirmDialog from "../../components/ConfirmDialog";

function Roles() {
    const [roles, setRoles] = useState([]);
    const [notification, setNotification] = useState({ type: "", message: "" });
    const token = localStorage.getItem("access_token");
    const [allUsers, setAllUsers] = useState([]);
    const [user, setUser] = useState(null);
    const [userRole, setUserRole] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const navigate = useNavigate();
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedRole, setSelectedRole] = useState(null);


    // Fetch Roles
    const fetchRoles = useCallback(async () => {
        try {
            const response = await fetch(`${API_URL}/roles`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                setRoles(data);
            } else if (response.status === 401) {
                navigate("/logout");
            } else {
                throw new Error("Failed to fetch roles");
            }
        } catch (error) {
            console.error("There was an error fetching the roles!", error);
            setNotification({ type: "danger", message: "Failed to fetch roles." });
        }
    }, [token]);

    const closeNotification = () => {
        setNotification({ type: "", message: "" });
    };

    const fetchAllUsers = async () => {
        try {
            const response = await fetch(`${API_URL}/users`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                setAllUsers(data);
            } else if (response.status === 404) {
                setNotification({ type: "danger", message: "Users not found." });
            } else if (response.status === 401) {
                navigate("/logout");
            } else {
                throw new Error("Failed to fetch users.");
            }

        } catch (error) {
            setNotification({ type: "danger", message: "An error occurred while fetching users" });
        }
    };

    useEffect(() => {
        fetchRoles();
        fetchAllUsers();
    }, [fetchRoles]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors({});

        if (!user || !userRole) {
            setErrors({
                user_id: user ? null : ["Please select a user"],
                role_id: userRole ? null : ["Please select a role"]
            });
            return;
        }

        const selectedUserObject = allUsers.find((u) => u.id === parseInt(user));
        const selectedRoleObject = roles.find((r) => r.id === parseInt(userRole));

        // Set the selected user and role to be used in the confirmation dialog
        setSelectedUser(selectedUserObject);
        setSelectedRole(selectedRoleObject)

        setConfirmDialogOpen(true);
    };

    // Handle confirmation
    const handleConfirmSubmit = async () => {
        setLoading(true);
        setConfirmDialogOpen(false); // Close the dialog once confirmed

        try {
            const response = await fetch(`${API_URL}/users/${user}/role`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ role_id: userRole, user_id: user })
            });

            if (response.ok) {
                setNotification({ type: "success", message: "User role updated successfully!" });
                setUser(null);
                setUserRole(null);
                fetchRoles()
            } else if (response.status === 401) {
                navigate("/logout");
            } else {
                const errorData = await response.json();
                setErrors(errorData.errors || {});
                setNotification({ type: "danger", message: "Failed to update user role." });
            }
        } catch (error) {
            console.error("There was an error updating the role!", error);
            setNotification({ type: "danger", message: "An error occurred while updating the role." });
        } finally {
            setLoading(false);
        }
    };

    const handleCancelSubmit = () => {
        setConfirmDialogOpen(false); // Close dialog if user cancels
    };

    // Handle changes in the form (both for user and role)
    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === "user_id") {
            setUser(value); // Update the selected user
        } else if (name === "role_id") {
            setUserRole(value); // Update the selected role
        }
    };

    return (
        <main>
            <nav className="level">
                <div className="level-left">
                    <div className="level-item">
                        <p className="title">Roles</p>
                    </div>
                </div>
            </nav>
            <div className="column">
                {notification.message && (
                    <Notification
                        type={notification.type}
                        message={notification.message}
                        onClose={closeNotification}
                    />
                )}
            </div>
            <div className="columns">
                <div className="column is-half dash-section">
                    <table className="table is-striped is-fullwidth">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Role</th>
                                <th># of Users</th>
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {roles.map((role, index) => (
                                <tr key={role.id}>
                                    <td> {index + 1}</td>
                                    <td><Link to={`/dashboard/roles/${role.id}`} className="primary-text-color has-text-weight-bold">{role.name?.toUpperCase()}</Link></td>
                                    <td>{role.users_count}</td>
                                    <td><Link to={`/dashboard/roles/${role.id}`} className="primary-text-color has-text-weight-bold"> View </Link></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="column is-half dash-section">
                    <h1 className="subtitle"><strong>Change User Role</strong></h1>
                    <hr />
                    <form onSubmit={handleSubmit}>
                        <div>
                            <div className="field">
                                <div className="control">
                                    <UsersDropdownField
                                        label="User"
                                        name="user_id"
                                        onChange={handleChange}
                                        options={allUsers}
                                        value={user || ""} // Ensure the user value is set correctly
                                        errors={errors.user_id}
                                        placeholder="Select a user"
                                    />
                                </div>
                                {errors.user_id && errors.user_id.map((error, index) => (
                                    <p key={index} className="help is-danger">{error}</p>
                                ))}
                            </div>
                            <div className="field">
                                <label className="label">Role</label>
                                <div className="control">
                                    <div className={`select is-fullwidth ${errors.role_id ? "is-danger" : ""}`}>
                                        <select
                                            name="role_id"
                                            value={userRole || ""} // Ensure role value is set correctly
                                            onChange={handleChange}
                                        >
                                            <option value="">Select a Role</option>
                                            {roles.filter((role) => role.name !== "superadmin" && role.name !== "guest").map((role) => (
                                                <option key={role.id} value={role.id}>
                                                    {role.name}
                                                </option>
                                            ))}
                                        </select>
                                        {errors.role_id && errors.role_id.map((error, index) => (
                                            <p key={index} className="help is-danger">{error}</p>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="field mt-4 pt-4">
                                <div className="control">
                                    <button type="submit" className="button primary-bg is-pulled-right is-fullwidth" disabled={loading}>
                                        {loading ? "Updating..." : "UPDATE USER ROLE"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            {/* ConfirmDialog Component */}
            < ConfirmDialog
                isOpen={isConfirmDialogOpen}
                theme="is-danger"
                title="User Role Change"
                message={`Are you sure you want to set ${selectedUser?.first_name?.toUpperCase()} ${selectedUser?.last_name?.toUpperCase()} as ${selectedRole?.name?.toUpperCase()}?`}
                onConfirm={handleConfirmSubmit}
                onCancel={handleCancelSubmit}
            />
        </main>
    );
}

export default Roles;
