export const  checkPermission = (userRole, allowedRoles) => {
    console.log(`Allowed Roles: ${JSON.stringify(allowedRoles)}`);
    const hasPermission = allowedRoles.some(role => role?.trim().toLowerCase() === userRole?.trim().toLowerCase());
    console.log(`User role: ${userRole}, Has permission: ${hasPermission}`);
    return hasPermission;
};

export const  allowedToView = (userRole, allowedRoles) => {
    return allowedRoles.some(role => role?.trim().toLowerCase() === userRole?.trim().toLowerCase());
};
