import React from "react";

function Footer() {    
    const currentYear = new Date().getFullYear(); 

    return (
        <footer className="main-footer primary-bg">
            <div className="container">
                <div className="columns is-vcentered">
                    {/* Left Column: Social Media Icons */}
                    <div className="column is-one-third has-text-left">
                        <div className="social-icons">
                            <a href="https://www.facebook.com/cfcmministry/" className="icon-circle" aria-label="Facebook" target="_blank" rel="noreferrer">
                                <i className="fab fa-facebook"></i>
                            </a>
                            <a href="https://instagram.com" className="icon-circle" aria-label="Instagram" target="_blank" rel="noreferrer">
                                <i className="fab fa-instagram"></i>
                            </a>
                            <a href="https://x.com/commissionersm" className="icon-circle" aria-label="Twitter" target="_blank" rel="noreferrer">
                                <i className="fab fa-twitter"></i>
                            </a>
                            <a href="https://www.youtube.com/@commissionersforchristmini3727" className="icon-circle" aria-label="Twitter" target="_blank" rel="noreferrer">
                                <i className="fab fa-youtube"></i>
                            </a>
                        </div>
                    </div>

                    {/* Center Column: Copyright Text */}
                    <div className="column is-one-third has-text-centered has-text-white">
                        <p>&copy; {currentYear} Commissioners For Christ Ministry. <br/> All rights reserved.</p>
                    </div>

                    {/* Right Column: Privacy Policy and Terms & Conditions */}
                    <div className="column is-one-third has-text-right">
                        <a href="/privacy-policy" className="has-text-white">Privacy Policy</a> &nbsp;|&nbsp;
                        <a href="/terms-and-conditions" className="has-text-white">Terms & Conditions</a>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
