import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { API_URL } from "../../config.js";
import Notification from "../../components/Notification.js";
import { allowedToView } from "../../utils/PermissionUtils.js";

function SchoolView() {
    const [school, setSchool] = useState({
        name: "",
        location_description: "",
        patron_name: "",
        patron_number: "",
        google_map_pin: "",
        description: "",
        population: "",
        school_type: "",
        boarding_type: "",
        bible_study_classes: false,
    });
    const [loading, setLoading] = useState(true);
    const [notification, setNotification] = useState({ type: "", message: "" });
    const navigate = useNavigate();
    const { id } = useParams();
    const userRole = localStorage.getItem("userRole");
    const canEdit = (userRole) => {
        return (allowedToView(userRole, ['admin', 'superadmin', 'missions-coordinator']));
    };

    useEffect(() => {
        const fetchSchool = async () => {
            if (id) {
                try {
                    const response = await fetch(`${API_URL}/schools/${id}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                        },
                    });
                    if (response.ok) {
                        const data = await response.json();
                        setSchool(data);
                    } else if (response.status === 404) {
                        setNotification({ type: "danger", message: "School not found." });
                    } else if (response.status === 401) {
                        navigate("/logout");
                    } else {
                        throw new Error("Failed to fetch school.");
                    }
                } catch (error) {
                    setNotification({ type: "danger", message: "An error occurred while fetching the school." });
                }
            }
        };

        const fetchData = async () => {
            await fetchSchool();
            setLoading(false);
        };

        fetchData();
    }, [id, navigate]);

    const closeNotification = () => {
        setNotification({ type: "", message: "" });
    };

    if (loading) return <p>Loading...</p>;

    return (
        <main>
            <nav className="level">
                <div className="level-left">
                    <div className="level-item">
                        <p className="title">{school.name} </p>
                    </div>
                </div>
                <div className="level-right">
                    <p className="level-item">
                        <Link to="/dashboard/schools" className="button primary-bg">
                            <i className="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Back
                        </Link>
                        {canEdit(userRole) && (
                            <>
                                &nbsp;
                                &nbsp;
                                <Link to={`/dashboard/schools/${school.id}/update`} className="button primary-bg">
                                    <i className="fa fa-pencil" aria-hidden="true"></i> &nbsp; Edit
                                </Link>
                            </>
                        )}
                    </p>
                </div>
            </nav>
            <div className="column dash-section is-loading">
                {notification.message && (
                    <Notification
                        type={notification.type}
                        message={notification.message}
                        onClose={closeNotification}
                    />
                )}

                <div className="columns">
                    <div className="column is-half">
                        {/* Name Field */}
                        <div className="field">
                            <label className="label">Name</label>
                            <div className="control">
                                <div className="box">{school.name}</div>
                            </div>
                        </div>

                        {/* County Field */}
                        <div className="field">
                            <label className="label">County</label>
                            <div className="control">
                                <div className="box">
                                    {school.county?.name || "N/A"}
                                </div>
                            </div>
                        </div>

                        {/* School Type Field */}
                        <div className="field">
                            <label className="label">School Type</label>
                            <div className="control">
                                <div className="box">
                                    {school.school_type || "N/A"}
                                </div>
                            </div>
                        </div>

                        {/* Boarding Type Field */}
                        <div className="field">
                            <label className="label">Boarding Type</label>
                            <div className="control">
                                <div className="box">
                                    {school.boarding_type || "N/A"}
                                </div>
                            </div>
                        </div>

                        {/* Patron Name Field */}
                        <div className="field">
                            <label className="label">Patron Name</label>
                            <div className="control">
                                <div className="box">{school.patron_name}</div>
                            </div>
                        </div>

                        {/* Patron Phone Field */}
                        <div className="field">
                            <label className="label">Patron Phone Number</label>
                            <div className="control">
                                <div className="box">{school.patron_number}</div>
                            </div>
                        </div>

                        {/* Description Field */}
                        <div className="field">
                            <label className="label">School Description</label>
                            <div className="control">
                                <div className="box">{school.description}</div>
                            </div>
                        </div>
                    </div>

                    <div className="column is-half">

                        {/* Population Field */}
                        <div className="field">
                            <label className="label">Population</label>
                            <div className="control">
                                <div className="box">{school.population}</div>
                            </div>
                        </div>

                        {/* Google Map Pin Field */}
                        <div className="field">
                            <label className="label">Google Map Pin</label>
                            <div className="control">
                                <div className="box">
                                    <a href={school.google_map_pin} target="_blank" rel="noopener noreferrer">
                                        {school.google_map_pin}
                                    </a>
                                </div>
                            </div>
                        </div>

                        {/* Location Description Field */}
                        <div className="field">
                            <label className="label">Location Description</label>
                            <div className="control">
                                <div className="box">{school.location_description}</div>
                            </div>
                        </div>

                        {/* Bible Study Classes Field */}
                        <div className="field">
                            <label className="label">Does The School Have Bible Study Classes?</label>
                            <div className="control">
                                <div className="box">
                                    {school.bible_study_classes ? "Yes" : "No"}
                                </div>
                            </div>
                        </div>

                        {/* New Believers Classes Field */}
                        <div className="field">
                            <label className="label">Does The School Have New Believers Classes?</label>
                            <div className="control">
                                <div className="box">
                                    {school.new_believers_classes ? "Yes" : "No"}
                                </div>
                            </div>
                        </div>

                        {/* Prayer Meetings Field */}
                        <div className="field">
                            <label className="label">Does The School Have Prayer Meetings?</label>
                            <div className="control">
                                <div className="box">
                                    {school.prayer_meetings ? "Yes" : "No"}
                                </div>
                            </div>
                        </div>

                        {/* Prayer Meetings Field */}
                        <div className="field">
                            <label className="label">Needs of the school (Prayer Items)</label>
                            <div className="control">
                                <div className="box">
                                    <pre>{school.needs}</pre>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </main>
    );
}

export default SchoolView;
