import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { API_URL, STORAGE_URL } from "../../config.js";
import Notification from "../../components/Notification.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faClock, faMapMarkerAlt, faMoneyBill, faInfoCircle, faArrowRight, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { formatTimestamp } from "../../utils/TextUtils.js";
import ConfirmDialog from "../../components/ConfirmDialog";
import { allowedToView } from "../../utils/PermissionUtils.js";

function EventView() {
    const [event, setEvent] = useState({
        name: "",
        start_date: "",
        start_time: "",
        end_date: null,
        end_time: null,
        venue: "",
        google_map_pin: "",
        description: "",
        status: "",
        post_publicly: false,
        require_registration: false,
        registration_fee: 0,
        contact_person_id: "",
        postponed_at: null,
        cancelled_at: null,
        reason_for_postponement: null,
        reason_for_cancel: null,
    });
    const [loading, setLoading] = useState(true);
    const [notification, setNotification] = useState({ type: "", message: "" });
    const navigate = useNavigate();
    const token = localStorage.getItem("access_token");
    const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [hasRegistered, setHasRegistered] = useState(false);
    const [myRegistration, setMyRegistration] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(null);
    const [allRegistrations, setAllRegistrations] = useState([]);
    const [confirmAction, setConfirmAction] = useState(""); // Track the action type for the confirmation dialog
    const [selectedRegistration, setSelectedRegistration] = useState(null); // Track the selected registration for the action
    const [confirmDialogTitle, setConfirmDialogTitle] = useState("");
    const [confirmDialogMessage, setConfirmDialogMessage] = useState("");
    const statusTagClasses = {
        Registered: "tag is-info",
        cancelled: "tag is-dark",
        rejected: "tag is-danger",
        PendingPayment: "tag is-danger"
    };
    const { id } = useParams();

    const userRole = localStorage.getItem("userRole");
    const sessionUser = JSON.parse(localStorage.getItem("user"));
    const canEdit = (userRole) => {
        return (allowedToView(userRole, ['admin', 'superadmin', 'missions-coordinator']));
    };

    const checkIfRegistered = useCallback(async () => {
        const registrationResponse = await fetch(`${API_URL}/events/${id}/registrations/check`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (registrationResponse.ok) {
            const registrationData = await registrationResponse.json();
            setHasRegistered(registrationData.has_registered);
            setAllRegistrations(registrationData.all_registrations);
            setMyRegistration(registrationData.my_registration);
        } else if (registrationResponse.status === 404) {
            setNotification({ type: "danger", message: "Event not found" });
        } else {
            setNotification({ type: "danger", message: "Could not load registrations" });
        }
    }, [id, token]);

    useEffect(() => {
        
        if (!token) {
            navigate("/logout");
            return;
        }

        const fetchEvent = async () => {
            if (id) {
                try {
                    const response = await fetch(`${API_URL}/events/${id}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    if (response.ok) {
                        const data = await response.json();
                        setEvent(data);
                        await checkIfRegistered();
                    } else if (response.status === 404) {
                        setNotification({ type: "danger", message: "Event not found." });
                    } else if (response.status === 401) {
                        navigate("/logout");
                    } else {
                        throw new Error("Failed to fetch event.");
                    }
                } catch (error) {
                    setNotification({ type: "danger", message: "An error occurred while fetching the event." });
                }
            }
        };

        const fetchData = async () => {
            await fetchEvent();
            setLoading(false);
        };

        fetchData();
        const handleClickOutside = (event) => {
            if (isDropdownOpen !== null) {
                const dropdown = document.querySelector(`.dropdown.is-active`);
                if (dropdown && !dropdown.contains(event.target)) {
                    setIsDropdownOpen(null);
                }
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [id, navigate, checkIfRegistered, isDropdownOpen, token]);

    const handleRegistration = (actionType, registration) => {
        setConfirmAction(actionType); // Set the action type (Approve, Reject, etc.)
        setSelectedRegistration(registration); // Track the registration associated with the action

        let title = "Confirm Action";
        let message = `Are you sure you want to ${actionType?.toUpperCase()} this registartion by ${registration?.user?.first_name} ${registration?.user?.last_name}?`;

        // Customize the title based on the actionType
        switch (actionType) {
            case "register":
                message = `Are you sure you want to register for ${event.name}?`;
                title = "Register";
                break;
            case "reject":
                title = "Reject Registration";
                break;
            case "cancel":
                title = "Cancel Registration";
                break;
            case "request":
                title = "Request to Serve";
                message = `Are you sure you want to request to serve in this mission?`;
                break;
            default:
                break;
        }

        setConfirmDialogTitle(title);
        setConfirmDialogMessage(message);
        setConfirmDialogOpen(true); // Open the confirmation dialog
    };

    const closeNotification = () => {
        setNotification({ type: "", message: "" });
    };

    const toggleDropdown = (index) => {
        if (isDropdownOpen === index) {
            setIsDropdownOpen(null); // Close dropdown if the same one is clicked
        } else {
            setIsDropdownOpen(index); // Open the clicked dropdown
        }
    };

    const confirmRegistration = async () => {
        let endpoint = `${API_URL}/events/${id}/registrations`;

        if (confirmAction !== "register") {
            endpoint += `/${selectedRegistration.id}`;
        }

        switch (confirmAction) {
            case "cancel":
                endpoint += "/cancel";
                break;
            case "reject":
                endpoint += "/reject";
                break;
            default:
                endpoint += "/"
        }

        try {
            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            });

            if (response.ok) {
                setNotification({ type: "success", message: `Request ${confirmAction}ed successfully.` });
                await checkIfRegistered(); // Refresh the requests list
            } else {
                setNotification({ type: "danger", message: `Failed to ${confirmAction} the request.` });
            }
        } catch (error) {
            setNotification({ type: "danger", message: "An error occurred while processing the request." });
        } finally {
            setConfirmDialogOpen(false);
        }
    };

    if (loading) return <p>Loading...</p>;

    return (
        <main>
            <nav className="level">
                <div className="level-left">
                    <div className="level-item">
                        <p className="title">Event Details</p>
                    </div>
                </div>
                <div className="level-right">
                    <p className="level-item">
                        <Link to="/dashboard/events" className="button primary-bg">
                            <i className="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Back
                        </Link>
                        {canEdit(userRole) && (
                            <>
                                &nbsp;
                                &nbsp;
                                <Link to={`/dashboard/events/${event.id}/update`} className="button primary-bg">
                                    <i className="fa fa-pencil" aria-hidden="true"></i> &nbsp; Edit
                                </Link>
                            </>
                        )}
                    </p>
                </div>
            </nav>

            {notification.message && (
                <Notification
                    type={notification.type}
                    message={notification.message}
                    onClose={closeNotification}
                />
            )}

            {/* Confirm Dialog */}
            <ConfirmDialog
                isOpen={isConfirmDialogOpen}
                theme="is-primary"
                title={confirmDialogTitle}
                message={confirmDialogMessage}
                onConfirm={confirmRegistration}
                onCancel={() => setConfirmDialogOpen(false)}
            />

            <div className="columns is-multiline mb-6">
                {/* Event Header */}
                <div className="column is-12">
                    <div className="box has-text-centered">
                        <p className="title is-3">{event.name}</p>
                        {event.status && (
                            <p className={`tag has-text-weight-bold is-${event.status === "active" ? "success" : "danger"}`}>
                                {event.status.toUpperCase()}
                            </p>
                        )}
                        <p> Posted by: {event.created_by.first_name} {event.created_by.last_name} on {formatTimestamp(event.created_at)}</p>
                    </div>
                </div>

                {/* Event Image */}
                <div className="column is-6">
                    <figure className="image is-16by9 box">
                        <img src={STORAGE_URL + '/events/' + event.image || 'https://via.placeholder.com/1280x720'} alt={event.name} className="contain-image" />
                    </figure>
                </div>

                {/* Date & Time */}
                <div className="column is-6">
                    <div className="box">
                        <p className="title is-5">
                            <FontAwesomeIcon icon={faCalendarAlt} /> &nbsp; Date
                        </p>
                        <p>
                            <FontAwesomeIcon icon={faClock} /> <strong> {event.start_date} &nbsp; {event.start_time}</strong>
                            {event.end_date && (
                                <span>
                                    &nbsp;  To &nbsp; <strong> {event.end_date || ""} - {event.end_time || ""} </strong>
                                </span>
                            )}
                        </p>

                    </div>
                    <div className="box">
                        <p className="title is-5">
                            <FontAwesomeIcon icon={faMapMarkerAlt} /> &nbsp; Venue
                        </p>
                        <p> <strong>{event.venue || "To Be Decided"}</strong> </p>
                        {event.google_map_pin && (
                            <a
                                href={event.google_map_pin}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="button is-link is-light">
                                View on Google Maps
                            </a>
                        )}
                    </div>
                </div>

                {/* Registration */}
                {event.require_registration && (
                    <div className="column is-12">
                        <div className="box pb-6">
                            <p className="title is-5">
                                <FontAwesomeIcon icon={faMoneyBill} /> &nbsp; Event Registration
                            </p>
                            <hr />
                            <p className="subtitle">
                                <strong>Registration Fee:</strong> KSh{" "}
                                {event.registration_fee > 0 ? (
                                    <span className="has-text-weight-semibold">{event.registration_fee}</span>
                                ) : (
                                    "0"
                                )}
                                {event.payment_option && (
                                    <> &nbsp; &nbsp;|
                                        &nbsp;
                                        Pay Via: <span className="has-text-weight-semibold">{event.payment_option}</span>
                                    </>
                                )}
                                {event.mpesa_phone_number && (
                                    <>
                                        &nbsp;
                                        to &nbsp; <span className="has-text-weight-semibold">{event.mpesa_phone_number}</span>
                                    </>
                                )}
                                {event.mpesa_display_name && (
                                    <>
                                        &nbsp; &nbsp;
                                        Name: <span className="has-text-weight-semibold">{event.mpesa_display_name}</span>
                                    </>
                                )}
                            </p>
                            <p className="mt-0 mb-5 pt-0">
                                {event.payment_description && (
                                    <span> <small>{event.payment_description}</small> </span>
                                )}
                            </p>

                            <div className="buttons">
                                {!hasRegistered ? (
                                    <Link onClick={() => handleRegistration("register", null)} className="button is-outline mt-4">
                                        <span className="icon">
                                            <FontAwesomeIcon icon={faArrowRight} />
                                        </span>
                                        <span>Proceed to Register</span>
                                    </Link>
                                ) : (
                                    <span></span>
                                )}
                                <span className="has-text-weight-bold"> &nbsp;
                                    {allRegistrations.length > 0 && (
                                        <span>{allRegistrations.length} Members Registered
                                            &nbsp; &nbsp;
                                        </span>
                                    )}

                                    {myRegistration && (
                                        <span className="light-primary-text-color">
                                            |
                                            &nbsp; &nbsp;
                                            My Registration Status: &nbsp;
                                            <span className={statusTagClasses[myRegistration?.status] || " tag"}>
                                                {myRegistration?.status?.toUpperCase()}
                                            </span>
                                        </span>
                                    )}

                                </span>
                            </div>


                            <div>
                                {allRegistrations.length > 0 ? (
                                    <>
                                        <table className="table is-striped is-fullwidth mt-4">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    {(allowedToView(userRole, ['admin', 'superadmin', 'missions-coordinator']) || event?.contactPerson?.id === sessionUser?.id) && (
                                                        <>
                                                            <th>Phone</th>
                                                            <th>Email</th>
                                                        </>
                                                    )}
                                                    <th>Status</th>
                                                    <th>Options</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {allRegistrations.slice(0, 5).map((registration, index) => (
                                                    <tr key={index}>
                                                        <td>

                                                            {(allowedToView(userRole, ['admin', 'superadmin', 'missions-coordinator']) || event?.contactPerson?.id === sessionUser?.id) ? (
                                                                <Link to={`/dashboard/members/${registration?.user?.id}/details`} className="primary-text-color"> {registration?.user?.first_name} {registration?.user?.last_name} </Link>
                                                            ) : (
                                                                <>{registration?.user?.first_name} {registration?.user?.last_name}</>
                                                            )}

                                                        </td>

                                                        {(allowedToView(userRole, ['admin', 'superadmin', 'missions-coordinator']) || event?.contactPerson?.id === sessionUser?.id) && (
                                                            <><td>{registration?.user?.phone_number}</td>
                                                                <td>{registration?.user?.email}</td></>
                                                        )}
                                                        <td>
                                                            <span className={` ${statusTagClasses[registration?.status]}` || "tag"}>
                                                                {registration?.status?.toUpperCase()}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <div className={`dropdown is-right ${isDropdownOpen === index ? 'is-active' : ''}`}>
                                                                <div className="dropdown-trigger">
                                                                    <button
                                                                        className="button is-white"
                                                                        aria-haspopup="true"
                                                                        aria-controls="dropdown-menu"
                                                                        onClick={() => toggleDropdown(index)}
                                                                    >
                                                                        <span className="icon">
                                                                            <i className="fas fa-ellipsis-v"></i>
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                                                                    <div className="dropdown-content">
                                                                        {(allowedToView(userRole, ['admin', 'superadmin', 'missions-coordinator']) || event?.contactPerson?.id === sessionUser?.id) && (
                                                                            <Link to={`/dashboard/members/${registration?.user?.id}/details`} className="dropdown-item">
                                                                                <span className="icon">
                                                                                    <i className="fas fa-eye"></i>
                                                                                </span>
                                                                                <span>View Member</span>
                                                                            </Link>
                                                                        )}

                                                                        {/* {registration.status !== "approved" && (
                                                                            <Link onClick={() => handleRegistration("approve", registration)} className="dropdown-item">
                                                                                <span className="icon">
                                                                                    <FontAwesomeIcon icon={faCheck} />
                                                                                </span>
                                                                                <span>Approve Registration</span>
                                                                            </Link>
                                                                        )}

                                                                        {registration.status !== "rejected" && (
                                                                            <Link onClick={() => handleRegistration("reject", registration)} className="dropdown-item">
                                                                                <span className="icon">
                                                                                    <FontAwesomeIcon icon={faTimes} />
                                                                                </span>
                                                                                <span>Reject Registration</span>
                                                                            </Link>
                                                                        )}

                                                                        {registration.status !== "cancelled" && (
                                                                            <Link onClick={() => handleRegistration("cancel", registration)} className="dropdown-item">
                                                                                <span className="icon">
                                                                                    <FontAwesomeIcon icon={faBan} />
                                                                                </span>
                                                                                <span>Cancel registration</span>
                                                                            </Link>
                                                                        )} */}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {
                                            allRegistrations.length > 5 && (

                                                <div className="has-text-centered">

                                                    <Link
                                                        to={`/dashboard/events/${id}/registration`}
                                                        className="button is-outline is-fullwidth is-rounded has-text-weight-bold"
                                                    >
                                                        View All The {allRegistrations.length} Registered Members
                                                    </Link>
                                                </div>
                                            )
                                        }
                                    </>
                                ) : (
                                    <span></span>
                                )}
                            </div>

                        </div>
                    </div>
                )}

                {/* Description */}
                <div className="column is-12">
                    <div className="box">
                        <p className="title is-5">
                            <FontAwesomeIcon icon={faInfoCircle} /> &nbsp; Description
                        </p>
                        <p>{event.description || "No description available."}</p>
                    </div>
                </div>

                <div className="column is-6">
                    <div className="box">
                        <p className="title is-5">
                            <FontAwesomeIcon icon={faQuestionCircle} /> &nbsp; Have a question?
                        </p>
                        <hr />
                        <p className="subtitle">
                            Contact: &nbsp;
                            {event.contactPerson ? (
                                <span className="has-text-weight-semibold">{event.contact_person.first_name} {event.contact_person.last_name}  {event.contact_person.email}  {event.contact_person.phone_number}  </span>
                            ) : (
                                <span className="has-text-weight-semibold">{event.created_by.first_name} {event.created_by.last_name}   {event.created_by.email} {event.created_by.phone_number} </span>
                            )}
                        </p>

                    </div>
                </div>

            </div>
        </main>
    );
}

export default EventView;
