import React from "react";

function Settings(){
    return(
        <main>

        </main>
    );
}

export default Settings;
