import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

function TermsAndConditions() {

    return (
        <div>
            <Header />
            <section className="hero with-min-height">
                <div className="hero-body">
                    <div>
                       
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}
export default TermsAndConditions;
