import React from "react";
import Select from "react-select";

function SchoolsDropdownField({ label, name, value, onChange, options, errors, placeholder }) {
    // Convert the options into a format that `react-select` can recognize.
    const formattedOptions = options.map((school) => ({
        value: school.id,
        label: `${school.name}`,
    }));

    // Handle the change event from react-select
    const handleSelectChange = (selectedOption) => {
        onChange({
            target: {
                name: name,
                value: selectedOption ? selectedOption.value : "", // Use empty string when the option is cleared
            },
        });
    };

    return (
        <div className="field">
            {label && <label className="label">{label}</label>}
            <div className="control">
                <Select
                    name={name}
                    value={formattedOptions.find(option => option.value === value) || null} // Ensure the selected value is mapped correctly
                    onChange={handleSelectChange}
                    options={formattedOptions}
                    placeholder={placeholder}
                    isClearable
                    isSearchable
                    className={`react-select-container ${errors ? 'is-danger' : ''}`}
                    classNamePrefix="react-select"
                />
                {errors && <p className="help is-danger">{errors.join(", ")}</p>}
            </div>
        </div>
    );
}

export default SchoolsDropdownField;
