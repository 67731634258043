import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from "../../config.js";
import ConfirmDialog from "../../components/ConfirmDialog.js";
import Notification from "../../components/Notification.js";
import { formatToDayMonthYear } from "../../utils/TextUtils.js";
import { allowedToView } from "../../utils/PermissionUtils.js";

function Events() {
    const [events, setEvents] = useState([]);
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [isDropdownOpen, setIsDropdownOpen] = useState(null);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [notification, setNotification] = useState({ type: "", message: "" });
    const userRole = localStorage.getItem("userRole");
    const canEdit = (userRole) => {
        return (allowedToView(userRole, ['admin', 'superadmin', 'missions-coordinator']));
    };

    const navigate = useNavigate();

    const fetchEvents = useCallback(async () => {
        const token = localStorage.getItem("access_token");

        try {
            const response = await fetch(`${API_URL}/events`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                setEvents(data);
                setFilteredEvents(data);
            } else if (response.status === 401) {
                navigate("/logout");
            } else {
                throw new Error("Failed to fetch events");
            }
        } catch (error) {
            setError("An error occurred while fetching events.");
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    }, [navigate]);

    const toggleDropdown = (index) => {
        if (isDropdownOpen === index) {
            setIsDropdownOpen(null); // Close dropdown if the same one is clicked
        } else {
            setIsDropdownOpen(index); // Open the clicked dropdown
        }
    };

    const handleDeleteClick = (event) => {
        setSelectedEvent(event);
        setIsConfirmDialogOpen(true);
    };

    const confirmDeleteEvent = async () => {
        if (selectedEvent) {
            try {
                const token = localStorage.getItem("access_token");
                const response = await fetch(`${API_URL}/events/${selectedEvent.id}`, {
                    method: 'DELETE',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    setNotification({ type: "success", message: 'Event deleted successfully' });
                    fetchEvents(); // Refresh the list
                } else if (response.status === 401) {
                    navigate("/logout");
                } else if (response.status === 404) {
                    setNotification({ type: "danger", message: 'Event not found.' });
                } else {
                    setNotification({ type: "danger", message: 'There was an error.' });
                    throw new Error('Failed to delete event');
                }
            } catch (error) {
                setNotification({ message: 'An error occurred while deleting the event.', type: 'danger' });
                console.error("Error:", error);
            } finally {
                setIsConfirmDialogOpen(false);
                setSelectedEvent(null);
            }
        }
    };

    const cancelDeleteEvent = () => {
        setIsConfirmDialogOpen(false);
        setSelectedEvent(null);
    };

    const closeNotification = () => {
        setNotification({ type: "", message: "" });
    };

    useEffect(() => {
        fetchEvents();
        const handleClickOutside = (event) => {
            if (isDropdownOpen !== null) {
                const dropdown = document.querySelector(`.dropdown.is-active`);
                if (dropdown && !dropdown.contains(event.target)) {
                    setIsDropdownOpen(null);
                }
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [isDropdownOpen, fetchEvents]);

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);

        const filtered = events.filter(event =>
            event.name.toLowerCase().includes(value) ||
            (event.venue || "").toLowerCase().includes(value)
        );
        setFilteredEvents(filtered);
    };

    return (
        <div>
            <main>
                <nav className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <p className="title">Events</p>
                        </div>
                    </div>
                    <div className="level-right">
                        {canEdit(userRole) && (
                            <p className="level-item">
                                <Link to="add" className="button primary-bg">
                                    <i className="fa fa-plus" aria-hidden="true"></i> &nbsp; New
                                </Link>
                            </p>
                        )}
                    </div>
                </nav>

                <h1>Events List</h1>

                {notification.message && (
                    <Notification
                        type={notification.type}
                        message={notification.message}
                        onClose={closeNotification}
                    />
                )}

                <div className="field">
                    <div className="control">
                        <input
                            className="input"
                            type="text"
                            placeholder="Search by event name or location"
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                    </div>
                </div>

                {loading ? (
                    <p>Loading events...</p>
                ) : error ? (
                    <p className="has-text-danger">{error}</p>
                ) : (
                    <table className="table is-striped is-fullwidth">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Venue</th>
                                <th>Start</th>
                                <th>End</th>
                                <th>Status</th>
                                <th>Registration <br /> Fee</th>
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredEvents.length > 0 ? (
                                filteredEvents.map((event, index) => (
                                    <tr key={event.id}>
                                        <td>{index + 1}</td>
                                        <td><Link to={`/dashboard/events/${event?.id}/details`} className="primary-text-color"> {event.name}</Link></td>
                                        <td>{event.venue}
                                            &nbsp; &nbsp;
                                            {event.google_map_pin && (
                                                <a href={`${event.google_map_pin}`} target="_blank" rel="noreferrer">
                                                    <i className='fas fa-map-marker-alt'></i> Map </a>
                                            )}
                                        </td>
                                        <td>{event.start_date && (formatToDayMonthYear(event.start_date) || " ")} - {event.start_time && (`${event.start_time} Hrs` || " ")}</td>
                                        <td>{event.end_date && (formatToDayMonthYear(event.end_date) || " ")} - {event.end_time && (`${event.end_time} Hrs` || " ")}</td>
                                        <td>{event.status || "N/A"}</td>
                                        <td>{event.registration_fee || "N/A"}</td>
                                        <td>
                                            <div className={`dropdown is-right ${isDropdownOpen === index ? 'is-active' : ''}`}>
                                                <div className="dropdown-trigger">
                                                    <button
                                                        className="button is-white"
                                                        aria-haspopup="true"
                                                        aria-controls="dropdown-menu"
                                                        onClick={() => toggleDropdown(index)}
                                                    >
                                                        <span className="icon">
                                                            <i className="fas fa-ellipsis-v"></i>
                                                        </span>
                                                    </button>
                                                </div>
                                                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                                                    <div className="dropdown-content">
                                                        <Link to={`${event.id}/details`} className="dropdown-item">
                                                            <span className="icon">
                                                                <i className="fas fa-eye"></i>
                                                            </span>
                                                            <span>View</span>
                                                        </Link>
                                                        {event.require_registration && (
                                                            <Link to={`${event.id}/details`} className="dropdown-item">
                                                                <span className="icon">
                                                                    <i className="fas fa-list"></i>
                                                                </span>
                                                                <span>Register</span>
                                                            </Link>
                                                        )}
                                                        {canEdit(userRole) && (
                                                            <>
                                                                <Link to={`${event.id}/update`} className="dropdown-item">
                                                                    <span className="icon">
                                                                        <i className="fas fa-edit"></i>
                                                                    </span>
                                                                    <span>Edit</span>
                                                                </Link>
                                                                <Link className="dropdown-item" onClick={() => handleDeleteClick(event)}>
                                                                    <span className="icon">
                                                                        <i className="fas fa-trash-alt"></i>
                                                                    </span>
                                                                    <span>Remove</span>
                                                                </Link>
                                                            </>
                                                        )}

                                                    </div>
                                                </div>
                                            </div>
                                        </td>

                                        {/* Confirmation Dialog */}
                                        <ConfirmDialog
                                            isOpen={isConfirmDialogOpen}
                                            theme="is-danger"
                                            title="Delete Confirmation"
                                            message={`Are you sure you want to delete ${selectedEvent?.name}?`}
                                            onConfirm={confirmDeleteEvent}
                                            onCancel={cancelDeleteEvent}
                                        />
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="7" className="has-text-centered">No events found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                )}
            </main>
        </div>
    );
}

export default Events;
