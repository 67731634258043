import React from "react";
import PropTypes from 'prop-types';

function Notification({ type, message, onClose }) {
    return (
        <div className={`notification is-${type} notification-with-opacity`}>
            <button className="delete" onClick={onClose}></button>
            {message}
        </div>
    );
}

Notification.propTypes = {
    type: PropTypes.oneOf(['primary', 'link', 'info', 'success', 'warning', 'danger']).isRequired,
    message: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
}

export default Notification;
