import React, { useEffect, useState, useCallback } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from "../config";
import { STORAGE_URL } from "../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";

function PublicEvents() {
    const [events, setEvents] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    const fetchEvents = useCallback(async () => {
        try {
            const response = await fetch(`${API_URL}/events/public/upcoming`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.ok) {
                const data = await response.json();
                setEvents(data.events);
            } else if (response.status === 401) {
                navigate("/logout");
            } else {
                throw new Error("Failed to fetch events");
            }
        } catch (error) {
            setError("An error occurred while fetching events.");
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    }, [navigate]);

    useEffect(() => {
        fetchEvents();
    }, [fetchEvents])

    return (
        <div>
            <Header />

            <div className="pt-6 container with-min-height">
                <h1 className="title is-3 has-text-centered">Upcoming Events</h1>


                {error && (<p className="has-text-danger">{error}</p>)}

                <p className="section-desc is-size-5">
                    At CFCM we aim to make and develop effective disciples in fulfilling Christ's Mission by encouraging, equipping,
                    mobilizing, and mentoring using biblical principles as inspired by the Holy Spirit.
                </p>

                <div className="columns is-multiline pt-5 pb-5">
                    {events.length > 0 ? (
                        events.map((event, index) => (
                            <div className="column is-4" key={index}>
                                <div className="card p-4">
                                    <div className="card-image">
                                        <Link to={`/events/${event.id}/${event.slug}`}>
                                            <figure className="image is-4by3 box">
                                                <img src={STORAGE_URL + '/events/' + event.image || 'https://via.placeholder.com/1280x720'} alt={event.name} className="cover-image" />
                                            </figure>
                                        </Link>
                                    </div>
                                    <div className="card-content has-text-weight-semibold pl-0 pr-0">
                                        <p className="title is-5 pb-0 mb-2">
                                            <Link to={`/events/${event.id}/${event.slug}`} className="primary-text-color">{event.name}</Link>
                                        </p>
                                        <p className="content">
                                            {event.description && (
                                                <>
                                                    {event.description.slice(0, 152)}
                                                    {event.description.length > 152 ? "..." : ""}
                                                </>
                                            )}
                                        </p>
                                        <Link to={`/events/${event.id}/${event.slug}`} className="see-more">
                                            <FontAwesomeIcon icon={faChevronRight} className="primary-text-color" /> &nbsp; See More Details
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="column pt-6 pb-6">
                            <p className="has-text-centered">
                                {loading ? (
                                    <>Loading. Please wait ..</>
                                ) : (
                                    <> No events found</>
                                )}
                            </p>
                        </div>
                    )}

                </div>
            </div>

            <Footer />
        </div>
    );
}

export default PublicEvents;
