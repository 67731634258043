import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { API_URL } from "../config";

function PrayerCenterBooking() {
    const [showBookingForm, setShowBookingForm] = useState(true);
    const [bookingType, setBookingType] = useState("");
    const [numberOfPeople, setNumberOfPeople] = useState(1);
    const [groupName, setGroupName] = useState("");
    const [individualName, setIndividualName] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [email, setEmail] = useState("");
    const [confirmEmail, setConfirmEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [specialRequests, setSpecialRequests] = useState("");
    const [searchEmail, setSearchEmail] = useState("");
    const [searchPhoneNumber, setSearchPhoneNumber] = useState("");
    const [searchReference, setSearchReference] = useState("");
    const [errors, setErrors] = useState({});
    const [notification, setNotification] = useState(null);
    const [loading, setLoading] = useState(false);
    const [successful, setSuccessful] = useState(false);

    const navigate = useNavigate();
    const today = new Date().toLocaleDateString("en-CA");

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const newErrors = {};
        setErrors({});
        setLoading(true);

        // Client-side validation (same as before)
        if (!bookingType) {
            newErrors.bookingType = "Booking type is required.";
            setLoading(false);
        }
        if (bookingType === "Group" && (numberOfPeople < 1 || isNaN(numberOfPeople))) {
            newErrors.numberOfPeople = "Please enter a valid number of people.";
            setLoading(false);
        }
        if (!individualName) {
            newErrors.individualName = "Name is required.";
            setLoading(false);
        }
        if (!startDate) {
            newErrors.startDate = "Start date is required.";
            setLoading(false);
        }
        if (!endDate) {
            newErrors.endDate = "End date is required.";
            setLoading(false);
        } else if (startDate && new Date(endDate) < new Date(startDate)) {
            newErrors.endDate = "End date cannot be before start date.";
            setLoading(false);
        }
        if (!email) {
            newErrors.email = "Email is required.";
            setLoading(false);
        }
        if (email !== confirmEmail) {
            newErrors.confirmEmail = "Emails do not match.";
            setLoading(false);
        }
        if (!phoneNumber) {
            newErrors.phoneNumber = "Phone number is required.";
            setLoading(false);
        } else if (!/^(?:\+254|0)\d{9}$/.test(phoneNumber)) {
            newErrors.phoneNumber = "Please enter a valid phone number in the format 0712345678 or +254712345678.";
            setLoading(false);
        }

        if (bookingType === 'Group' && !groupName) {
            newErrors.groupName = "Group name is required.";
            setLoading(false);
        }

        setErrors(newErrors);
        if (Object.keys(newErrors).length > 0) return;

        const formData = {
            booking_type: bookingType,
            individual_name: individualName,
            group_name: groupName,
            start_date: startDate,
            end_date: endDate,
            number_of_people: bookingType === "Group" ? numberOfPeople : 1,
            email: email,
            email_confirmation: confirmEmail,
            phone_number: phoneNumber,
            special_requests: specialRequests,
        };

        for (const key in formData) {
            if (formData[key] === null || formData[key] === "") {
                delete formData[key];
            } 
        }

        try {
            const response = await fetch(`${API_URL}/bookings`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });

                setNotification({ message: "Booking created successfully.", type: "success" });
                setErrors({});
                setSuccessful(true);
                setLoading(false);
                const data = await response.json();
                if (data.token) {
                    setTimeout(() => {
                        navigate(`/makuyu-prayer-center-booking/${data.token}`);
                    }, 1000);
                }

            } else if (response.status === 401) {
                navigate("/logout");
            } else {
                const errorData = await response.json();
                setErrors(errorData || {});
                setNotification({
                    message: errorData.message || "An error occurred while processing your booking.",
                    type: "danger",
                });
                setLoading(false);
            }
        } catch (error) {
            setNotification({ message: "An unexpected error occurred. Please try again.", type: "danger" });
            setLoading(false);
        }
    };

    const handleSearchSubmit = async (e) => {
        e.preventDefault();
        setErrors({});
        setLoading(true);

        if (!searchEmail || !searchPhoneNumber) {
            setErrors({ search: "Email and phone number are required to retrieve your booking." });
            setLoading(false);
            return;
        }

        try {
            const response = await fetch(`${API_URL}/bookings/search`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    search_email: searchEmail,
                    search_phone_number: searchPhoneNumber,
                    search_reference: searchReference,
                }),
            });

            if (response.ok) {
                setNotification({ message: "A booking token has been sent to your email.", type: "success" });
                setLoading(false);
            } else {
                const errorData = await response.json();
                setErrors(errorData || {});
                setLoading(false);
                //setErrors({ search: errorData.message || "Unable to retrieve booking information." });
            }
        } catch (error) {
            setLoading(false);
            setErrors({ search: "An unexpected error occurred. Please try again." });
        }
    };

    return (
        <div>
            <Header />
            <div className="container ">
                <div className={`section mb-5`}>
                    <h1 className="title is-3 has-text-centered">Peniel Prayer and Retreat Center – Makuyu</h1>
                    <p className="subtitle mt-2 has-text-centered pb-2">
                        Welcome to the serene Peniel Prayer and Retreat Center – Makuyu. <br /> <br /> Book your visit today!
                        <br />
                        <button
                            className="button primary-outline-button mt-6 is-pulled-right"
                            onClick={() => setShowBookingForm(!showBookingForm)}
                        >
                            {showBookingForm ? "Check Your Booking Status" : "New Booking"}
                        </button>
                    </p>
                </div>

                {notification && (
                    <p className={`notification is-${notification.type}`}>{notification.message}</p>
                )}

                {showBookingForm ? (
                    <div className="section mt-0 pt-5">
                        <div className="box">
                            <h2 className="title is-4">Book Your Visit</h2>
                            <hr />
                            <form onSubmit={handleFormSubmit}>

                                <div className="columns is-multiline">
                                    <div className="column is-half">
                                        <label className="label">Booking Type</label>
                                        <div className="control">
                                            <div className="select is-fullwidth">
                                                <select
                                                    required
                                                    value={bookingType}
                                                    onChange={(e) => setBookingType(e.target.value)}
                                                    className={`select ${errors.bookingType ? "is-danger" : ""}`}
                                                >
                                                    <option value="">Select...</option>
                                                    <option value="Group">Group</option>
                                                    <option value="Individual">Individual</option>
                                                </select>
                                            </div>
                                            {errors.bookingType && (
                                                <p className="help is-danger">{errors.bookingType}</p>
                                            )}
                                        </div>
                                    </div>

                                    {bookingType === "Group" && (
                                        <div className="column is-half">
                                            <label className="label">Number of People</label>
                                            <div className="control">
                                                <input
                                                    className={`input ${errors.numberOfPeople ? "is-danger" : ""}`}
                                                    type="number"
                                                    min="1"
                                                    value={numberOfPeople}
                                                    onChange={(e) => setNumberOfPeople(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            {errors.numberOfPeople && (
                                                <p className="help is-danger">{errors.numberOfPeople}</p>
                                            )}
                                        </div>
                                    )}

                                    <div className="column is-half">
                                        <label className="label"> Your Name</label>
                                        <div className="control">
                                            <input
                                                className={`input ${errors.individualName ? "is-danger" : ""}`}
                                                type="text"
                                                value={individualName}
                                                onChange={(e) => setIndividualName(e.target.value)}
                                                required
                                                placeholder="e.g John Doe"
                                            />
                                        </div>
                                        {errors.individualName && <p className="help is-danger">{errors.individualName}</p>}
                                    </div>

                                    {bookingType === "Group" && (
                                        <div className="column is-half">
                                            <label className="label"> Group Name</label>
                                            <div className="control">
                                                <input
                                                    className={`input ${errors.groupName ? "is-danger" : ""}`}
                                                    type="text"
                                                    value={groupName}
                                                    onChange={(e) => setGroupName(e.target.value)}
                                                    required
                                                    placeholder="Enter the name of your group"
                                                />
                                            </div>
                                            {errors.groupName && <p className="help is-danger">{errors.groupName}</p>}
                                        </div>
                                    )}

                                    < div className="column is-half">
                                        <label className="label">Start Date</label>
                                        <div className="control">
                                            <input
                                                className={`input ${errors.startDate ? "is-danger" : ""}`}
                                                type="date"
                                                min={today} // Prevents selecting dates before today
                                                value={startDate}
                                                onChange={(e) => setStartDate(e.target.value)}
                                                required
                                            />
                                        </div>
                                        {errors.startDate && <p className="help is-danger">{errors.startDate}</p>}
                                    </div>

                                    <div className="column is-half">
                                        <label className="label">End Date</label>
                                        <div className="control">
                                            <input
                                                className={`input ${errors.endDate ? "is-danger" : ""}`}
                                                type="date"
                                                min={today} // Prevents selecting dates before today
                                                value={endDate}
                                                onChange={(e) => setEndDate(e.target.value)}
                                                required
                                            />
                                        </div>
                                        {errors.endDate && <p className="help is-danger">{errors.endDate}</p>}
                                    </div>

                                    <div className="column is-half">
                                        <label className="label">Email</label>
                                        <div className="control">
                                            <input
                                                className={`input ${errors.email ? "is-danger" : ""}`}
                                                type="email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                        </div>
                                        {errors.email && <p className="help is-danger">{errors.email}</p>}
                                    </div>

                                    <div className="column is-half">
                                        <label className="label">Confirm Email</label>
                                        <div className="control">
                                            <input
                                                className={`input ${errors.confirmEmail ? "is-danger" : ""}`}
                                                type="email"
                                                value={confirmEmail}
                                                onChange={(e) => setConfirmEmail(e.target.value)}
                                                required
                                            />
                                        </div>
                                        {errors.confirmEmail && (
                                            <p className="help is-danger">{errors.confirmEmail}</p>
                                        )}
                                    </div>

                                    <div className="column is-half">
                                        <label className="label">Phone Number</label>
                                        <div className="control">
                                            <input
                                                className={`input ${errors.phoneNumber ? "is-danger" : ""}`}
                                                type="tel"
                                                value={phoneNumber}
                                                onChange={(e) => setPhoneNumber(e.target.value)}
                                                required
                                            />
                                        </div>
                                        {errors.phoneNumber && (
                                            <p className="help is-danger">{errors.phoneNumber}</p>
                                        )}
                                    </div>

                                    <div className="column is-full">
                                        <label className="label">Special Requests (optional) </label>
                                        <div className="control">
                                            <textarea
                                                className="textarea"
                                                value={specialRequests}
                                                onChange={(e) => setSpecialRequests(e.target.value)}
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>

                                <div className="columns">
                                    <div className="column is-half">
                                    </div>
                                    <div className="column is-half">
                                        <button type="submit" className="button primary-bg is-fullwidth" disabled={loading || successful}>
                                            {loading ? "Submitting..." : "Submit Booking"}
                                        </button>
                                    </div>

                                </div>

                            </form>
                        </div>
                    </div>
                ) : (
                    <div className="section mt-0 pt-5">
                        <div className="box pb-6">
                            <h2 className="title is-4">Check Booking Status</h2>
                            <hr />
                            <div className="pb-6">
                                Enter the Email and Phone Number that you used to book so that you can receive your booking token via email. <br /> If you have a Booking Reference Number, you can add it for quicker results.
                            </div>
                            <form onSubmit={handleSearchSubmit}>
                                <div className="columns is-multiline">
                                    <div className="column is-half">
                                        <label className="label">Email</label>
                                        <div className="control">
                                            <input
                                                className={`input ${errors.search ? "is-danger" : ""}`}
                                                type="email"
                                                placeholder="Enter your email"
                                                value={searchEmail}
                                                onChange={(e) => setSearchEmail(e.target.value)}
                                                required
                                            />
                                        </div>
                                        {errors.search_email && (
                                            <p className="help is-danger">{errors.search_email}</p>
                                        )}
                                    </div>
                                    <div className="column is-half">
                                        <label className="label">Phone Number</label>
                                        <div className="control">
                                            <input
                                                className={`input ${errors.search ? "is-danger" : ""}`}
                                                type="tel"
                                                placeholder="Enter your phone number"
                                                value={searchPhoneNumber}
                                                onChange={(e) => setSearchPhoneNumber(e.target.value)}
                                                required
                                            />
                                        </div>
                                        {errors.search_phone_number && (
                                            <p className="help is-danger">{errors.search_phone_number}</p>
                                        )}
                                    </div>
                                    <div className="column is-half">
                                        <label className="label">Reference Number (Optional)</label>
                                        <div className="control">
                                            <input
                                                className="input"
                                                type="text"
                                                placeholder="Enter your booking reference (optional)"
                                                value={searchReference}
                                                onChange={(e) => setSearchReference(e.target.value)}
                                            />
                                        </div>
                                        {errors.search_reference && (
                                            <p className="help is-danger">{errors.search_reference}</p>
                                        )}
                                    </div>
                                </div>
                                {errors.search && <p className="help is-danger">{errors.search}</p>}
                                <button type="submit" className={`button primary-bg is-fullwidth ${loading ? "is-loading" : ""}`}>
                                    Send Booking Token
                                </button>
                            </form>

                        </div>
                    </div>
                )
                }
            </div >
            <Footer />
        </div >
    );
}

export default PrayerCenterBooking;
