import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { API_URL } from "../../config.js";
import Notification from "../../components/Notification.js";

function SchoolUpdate() {
    const [school, setSchool] = useState({
        name: "",
        county_id: "",
        location_description: "",
        patron_name: "",
        patron_number: "",
        google_map_pin: "",
        description: "",
        population: "",
        school_type: "",
        boarding_type: "",
        bible_study_classes: false,
    });
    const [counties, setCounties] = useState([]);
    const [schoolTypes, setSchoolTypes] = useState([]);
    const [boardingTypes, setBoardingTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [notification, setNotification] = useState({ type: "", message: "" });
    const [isEditing, setIsEditing] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const token = localStorage.getItem("access_token");

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const response = await fetch(`${API_URL}/schools/options`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                setSchoolTypes(data.school_types);
                setBoardingTypes(data.boarding_types);
            } catch (error) {
                setNotification({ type: "danger", message: "Failed to fetch options." });
            }
        };

        const fetchCounties = async () => {
            try {
                const response = await fetch(`${API_URL}/counties`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
                });
                const data = await response.json();


                if (response.status === 401) {
                    navigate("/logout");
                }

                setCounties(data);
            } catch (error) {
                setNotification({ type: "danger", message: "Failed to fetch counties." });
            }
        };

        const fetchSchool = async () => {
            if (id) {
                try {
                    const response = await fetch(`${API_URL}/schools/${id}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    if (response.ok) {
                        const data = await response.json();
                        setSchool(data);
                        setIsEditing(true);
                    } else if (response.status === 404) {
                        setNotification({ type: "danger", message: "School not found." });
                    } else if (response.status === 401) {
                        navigate("/logout");
                    } else {
                        throw new Error("Failed to fetch school.");
                    }
                } catch (error) {
                    setNotification({ type: "danger", message: "An error occurred while fetching the school." });
                }
            }
        };

        const fetchData = async () => {
            await fetchOptions();
            await fetchCounties();
            await fetchSchool();
            setLoading(false);
        };

        fetchData();
    }, [id, navigate, token]);

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setSchool((prevSchool) => ({
            ...prevSchool,
            [name]: type === "checkbox" ? checked : value || "",
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        // Clear previous errors before starting validation
        setErrors({});  

        // Google Map Pin validation
        if (school.google_map_pin) {
            const googleMapPinRegex = /^https:\/\/maps\.app\.goo\.gl\/[A-Za-z0-9]+$/;
            if (!googleMapPinRegex.test(school.google_map_pin)) {
                setErrors((prevErrors) => ({ ...prevErrors, google_map_pin: ["Invalid Google Map Pin format."] }));
                return;
            }
        }

        const method = isEditing ? "POST" : "POST";
        const url = isEditing ? `${API_URL}/schools/${id}/update` : `${API_URL}/schools`;

        try {
            setLoading(true);
            const response = await fetch(url, {
                method: method,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(school),
            });

            if (response.ok) {
                setNotification({ message: "School successfully saved.", type: "success" });

                const data = await response.json(); // Assuming the response contains the updated school data

                if (data && data.id) {
                    setNotification({ message: "School successfully saved.", type: "success" });
                    navigate(`/dashboard/schools/${data.id}/details`); // Navigate to the updated school's detail page
                } else {
                    setNotification({ message: "Failed to retrieve updated school information.", type: "danger" });
                }

            } else if (response.status === 401) {
                navigate("/logout");
            } else {
                const errorData = await response.json();
                setErrors(errorData || {});
                setNotification({ message: errorData.message || "An error occurred.", type: "danger" });
                throw new Error("Failed to fetch school.");
            }
            setLoading(false);
        } catch (error) {
            setNotification({ message: "An error occurred while saving the school.", type: "danger" });
            console.error("Error:", error);
            setLoading(false);
        }
    };

    const closeNotification = () => {
        setNotification({ type: "", message: "" });
    };

    if (loading) return <p>Loading...</p>;

    return (
        <main>
            <nav className="level">
                <div className="level-left">
                    <div className="level-item">
                        <p className="title">
                            {isEditing ? "Update School" : "Add School"}
                        </p>
                    </div>
                </div>
                <div className="level-right">
                    <p className="level-item">
                        <Link to="/dashboard/schools" className="button primary-bg">
                            <i className="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Back
                        </Link>
                        &nbsp;
                        &nbsp;
                        <a
                            href={`/dashboard/schools/${school.id}/details`}
                            className="button primary-bg"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <i className="fa fa-eye" aria-hidden="true"></i> &nbsp; View
                        </a>
                    </p>
                </div>
            </nav>
            <div className="column dash-section is-loading">
                {notification.message && (
                    <Notification
                        type={notification.type}
                        message={notification.message}
                        onClose={closeNotification}
                    />
                )}

                <form onSubmit={handleSubmit}>
                    <div className="columns">
                        <div className="column is-half">
                            {/* Name Field */}
                            <div className="field">
                                <label className="label">Name</label>
                                <div className="control">
                                    <input
                                        className={`input ${errors.name ? "is-danger" : ""}`}
                                        type="text"
                                        name="name"
                                        value={school.name}
                                        onChange={handleChange}
                                        placeholder="School Name"
                                        required
                                    />
                                </div>
                                {errors.name && errors.name.map((error, index) => (
                                    <p key={index} className="help is-danger">{error}</p>
                                ))}
                            </div>

                            {/* County Select */}
                            <div className="field">
                                <label className="label">County</label>
                                <div className="control">
                                    <div className={`select is-fullwidth ${errors.county_id ? "is-danger" : ""}`}>
                                        <select
                                            name="county_id"
                                            value={school.county_id}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select a County</option>
                                            {counties.map((county) => (
                                                <option key={county.id} value={county.id}>
                                                    {county.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                {errors.county_id && errors.county_id.map((error, index) => (
                                    <p key={index} className="help is-danger">{error}</p>
                                ))}
                            </div>

                            {/* School Type Select */}
                            <div className="field">
                                <label className="label">School Type</label>
                                <div className="control">
                                    <div className={`select is-fullwidth ${errors.school_type ? "is-danger" : ""}`}>
                                        <select name="school_type" value={school.school_type} onChange={handleChange}>
                                            <option value="">Select School Type</option>
                                            {schoolTypes.map((type) => (
                                                <option key={type} value={type}>
                                                    {type}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                {errors.school_type && errors.school_type.map((error, index) => (
                                    <p key={index} className="help is-danger">{error}</p>
                                ))}
                            </div>

                            {/* Boarding Type Select */}
                            <div className="field">
                                <label className="label">Boarding Type</label>
                                <div className="control">
                                    <div className={`select is-fullwidth ${errors.boarding_type ? "is-danger" : ""}`}>
                                        <select name="boarding_type" value={school.boarding_type} onChange={handleChange}>
                                            <option value="">Select Boarding Type</option>
                                            {boardingTypes.map((type) => (
                                                <option key={type} value={type}>
                                                    {type}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                {errors.boarding_type && errors.boarding_type.map((error, index) => (
                                    <p key={index} className="help is-danger">{error}</p>
                                ))}
                            </div>

                            {/* Patron Name Field */}
                            <div className="field">
                                <label className="label">Patron Name</label>
                                <div className="control">
                                    <input
                                        className={`input ${errors.patron_name ? "is-danger" : ""}`}
                                        type="text"
                                        name="patron_name"
                                        value={school.patron_name}
                                        onChange={handleChange}
                                        placeholder="Patron Name"
                                    />
                                </div>
                                {errors.patron_name && errors.patron_name.map((error, index) => (
                                    <p key={index} className="help is-danger">{error}</p>
                                ))}
                            </div>

                            {/* Patron Phone Field */}
                            <div className="field">
                                <label className="label">Patron Phone Number</label>
                                <div className="control">
                                    <input
                                        className={`input ${errors.patron_number ? "is-danger" : ""}`}
                                        type="text"
                                        name="patron_number"
                                        value={school.patron_number}
                                        onChange={handleChange}
                                        placeholder="Patron Phone Number"
                                    />
                                </div>
                                {errors.patron_number && errors.patron_number.map((error, index) => (
                                    <p key={index} className="help is-danger">{error}</p>
                                ))}
                            </div>

                            {/* Population Field */}
                            <div className="field">
                                <label className="label">Population</label>
                                <div className="control">
                                    <input
                                        className={`input ${errors.population ? "is-danger" : ""}`}
                                        type="number"
                                        name="population"
                                        value={school.population}
                                        onChange={handleChange}
                                        placeholder="Population"
                                    />
                                </div>
                                {errors.population && errors.population.map((error, index) => (
                                    <p key={index} className="help is-danger">{error}</p>
                                ))}
                            </div>

                            {/* Description Textarea */}
                            <div className="field">
                                <label className="label">School Description</label>
                                <div className="control">
                                    <textarea
                                        className={`textarea ${errors.description ? "is-danger" : ""}`}
                                        name="description"
                                        value={school.description}
                                        onChange={handleChange}
                                        placeholder="School Description"
                                    />
                                </div>
                                {errors.description && errors.description.map((error, index) => (
                                    <p key={index} className="help is-danger">{error}</p>
                                ))}
                            </div>

                        </div>

                        <div className="column is-half">

                            {/* Google Map Pin Field */}
                            <div className="field">
                                <label className="label">Google Map Pin</label>
                                <div className="control">
                                    <input
                                        className={`input ${errors.google_map_pin ? "is-danger" : ""}`}
                                        type="url"
                                        name="google_map_pin"
                                        value={school.google_map_pin}
                                        onChange={handleChange}
                                        placeholder="Google Map Pin URL"
                                    />
                                </div>
                                {errors.google_map_pin && errors.google_map_pin.map((error, index) => (
                                    <p key={index} className="help is-danger">{error}</p>
                                ))}
                            </div>

                            {/* Location Description Field */}
                            <div className="field">
                                <label className="label">Location Description</label>
                                <div className="control">
                                    <textarea
                                        className={`textarea ${errors.location_description ? "is-danger" : ""}`}
                                        name="location_description"
                                        value={school.location_description}
                                        onChange={handleChange}
                                        placeholder="e.g 3 km from Othaya Town"
                                    />
                                </div>
                                {errors.location_description && errors.location_description.map((error, index) => (
                                    <p key={index} className="help is-danger">{error}</p>
                                ))}
                            </div>

                            {/* Bible Study Classes Checkbox */}
                            <div className="field mt-6">
                                <label className="label">Does The School Have Bible Study Classes?</label>
                                <div className="control">
                                    <label className="checkbox">
                                        <input
                                            type="checkbox"
                                            name="bible_study_classes"
                                            checked={school.bible_study_classes}
                                            onChange={handleChange}
                                        />
                                        &nbsp; Bible Study Classes
                                    </label>
                                </div>
                                {errors.bible_study_classes && errors.bible_study_classes.map((error, index) => (
                                    <p key={index} className="help is-danger">{error}</p>
                                ))}
                            </div>

                            {/* New Believers Classes Checkbox */}
                            <div className="field">
                                <label className="label">Does The School Have New Believers Classes?</label>
                                <div className="control">
                                    <label className="checkbox">
                                        <input
                                            type="checkbox"
                                            name="new_believers_classes"
                                            checked={school.new_believers_classes}
                                            onChange={handleChange}
                                        />
                                        &nbsp; New Believers Classes
                                    </label>
                                </div>
                                {errors.new_believers_classes && errors.new_believers_classes.map((error, index) => (
                                    <p key={index} className="help is-danger">{error}</p>
                                ))}
                            </div>

                            {/*  Prayer Meetings Checkbox */}
                            <div className="field mb-6">
                                <label className="label">Does The School Have Prayer Meetings?</label>
                                <div className="control">
                                    <label className="checkbox">
                                        <input
                                            type="checkbox"
                                            name="prayer_meetings"
                                            checked={school.prayer_meetings}
                                            onChange={handleChange}
                                        />
                                        &nbsp; Prayer Meetings
                                    </label>
                                </div>
                                {errors.prayer_meetings && errors.prayer_meetings.map((error, index) => (
                                    <p key={index} className="help is-danger">{error}</p>
                                ))}
                            </div>

                            {/* Needs Textarea */}
                            <div className="field">
                                <label className="label">Needs of the school (Prayer Items)</label>
                                <div className="control">
                                    <textarea
                                        className={`textarea ${errors.needs ? "is-danger" : ""}`}
                                        name="needs"
                                        value={school.needs}
                                        onChange={handleChange}
                                        placeholder="Needs of the school"
                                    />
                                </div>
                                {errors.needs && errors.needs.map((error, index) => (
                                    <p key={index} className="help is-danger">{error}</p>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Save Button */}
                    <div className="control">
                        <button type="submit" className={`button primary-bg ${loading ? "is-loading" : ""}`}>
                            {isEditing ? "Update School" : "Add School"}
                        </button>
                    </div>
                </form>
            </div>
        </main>
    );
}

export default SchoolUpdate;
