import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from "../../config.js";
import ConfirmDialog from "../../components/ConfirmDialog.js";
import Notification from "../../components/Notification.js";
import { formatToDayMonthYear, formatTimestamp } from "../../utils/TextUtils.js";

function BookingList() {
    const [bookings, setBookings] = useState([]);
    const [filteredBookings, setFilteredBookings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [isDropdownOpen, setIsDropdownOpen] = useState(null);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [selectedBooking, setSelectedBooking] = useState(null);
    const [notification, setNotification] = useState({ type: "", message: "" });

    const navigate = useNavigate();

    const fetchBookings = useCallback(async () => {
        const token = localStorage.getItem("access_token");

        try {
            const response = await fetch(`${API_URL}/bookings`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                setBookings(data);
                setFilteredBookings(data);
            } else if (response.status === 401) {
                navigate("/logout");
            } else {
                throw new Error("Failed to fetch bookings");
            }
        } catch (error) {
            setError("An error occurred while fetching bookings.");
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    }, [navigate]);

    const toggleDropdown = (index) => {
        if (isDropdownOpen === index) {
            setIsDropdownOpen(null); // Close dropdown if the same one is clicked
        } else {
            setIsDropdownOpen(index); // Open the clicked dropdown
        }
    };

    const handleDeleteClick = (booking) => {
        setSelectedBooking(booking);
        setIsConfirmDialogOpen(true);
    };

    const confirmDeleteBooking = async () => {
        if (selectedBooking) {
            try {
                const token = localStorage.getItem("access_token");
                const response = await fetch(`${API_URL}/bookings/${selectedBooking.id}`, {
                    method: 'DELETE',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    setNotification({ type: "success", message: 'Booking deleted successfully' });
                    fetchBookings(); // Refresh the list
                } else if (response.status === 401) {
                    navigate("/logout");
                } else if (response.status === 404) {
                    setNotification({ type: "danger", message: 'Booking not found.' });
                } else {
                    setNotification({ type: "danger", message: 'There was an error.' });
                    throw new Error('Failed to delete booking');
                }
            } catch (error) {
                setNotification({ message: 'An error occurred while deleting the booking.', type: 'danger' });
                console.error("Error:", error);
            } finally {
                setIsConfirmDialogOpen(false);
                setSelectedBooking(null);
            }
        }
    };

    const cancelDeleteBooking = () => {
        setIsConfirmDialogOpen(false);
        setSelectedBooking(null);
    };

    const closeNotification = () => {
        setNotification({ type: "", message: "" });
    };

    useEffect(() => {
        fetchBookings();
        const handleClickOutside = (event) => {
            if (isDropdownOpen !== null) {
                const dropdown = document.querySelector(`.dropdown.is-active`);
                if (dropdown && !dropdown.contains(event.target)) {
                    setIsDropdownOpen(null);
                }
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [isDropdownOpen, fetchBookings]);

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);

        const filtered = bookings.filter(booking =>
            booking.name.toLowerCase().includes(value) ||
            (booking.email || "").toLowerCase().includes(value)
        );
        setFilteredBookings(filtered);
    };

    return (
        <div>
            <main>
                <nav className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <p className="title">Makuyu Prayer Center Bookings</p>
                        </div>
                    </div>
                    <div className="level-right">
                        <p className="level-item">
                            <Link to="add" className="button primary-bg">
                                <i className="fa fa-plus" aria-hidden="true"></i> &nbsp; New
                            </Link>
                        </p>
                    </div>
                </nav>

                <h1>Booking List</h1>

                {notification.message && (
                    <Notification
                        type={notification.type}
                        message={notification.message}
                        onClose={closeNotification}
                    />
                )}

                <div className="field">
                    <div className="control">
                        <input
                            className="input"
                            type="text"
                            placeholder="Search by booking name or email"
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                    </div>
                </div>

                {loading ? (
                    <p>Loading bookings...</p>
                ) : error ? (
                    <p className="has-text-danger">{error}</p>
                ) : (
                    <table className="table is-striped is-fullwidth">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Phone</th>
                                <th>Type</th>
                                <th>Start</th>
                                <th>End</th>
                                <th>People</th>
                                <th>Status</th>
                                <th>Requested On</th>
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredBookings.length > 0 ? (
                                filteredBookings.map((booking, index) => (
                                    <tr key={booking.id}>
                                        <td>{index + 1}</td>
                                        <td>
                                            <Link to={`${booking.id}/details`} className="default-color">{booking.name}</Link>
                                        </td>
                                        <td>{booking.phone_number}</td>
                                        <td>{booking.booking_type}</td>
                                        <td>{booking.start_date && formatToDayMonthYear(booking.start_date)}</td>
                                        <td>{booking.end_date && formatToDayMonthYear(booking.end_date)}</td>
                                        <td>{booking.number_of_people}</td>
                                        <td>{booking.status || "N/A"}</td>
                                        <td>{formatTimestamp(booking.created_at)}</td>
                                        <td>
                                            <div className={`dropdown is-right ${isDropdownOpen === index ? 'is-active' : ''}`}>
                                                <div className="dropdown-trigger">
                                                    <button
                                                        className="button is-white"
                                                        aria-haspopup="true"
                                                        aria-controls="dropdown-menu"
                                                        onClick={() => toggleDropdown(index)}
                                                    >
                                                        <span className="icon">
                                                            <i className="fas fa-ellipsis-v"></i>
                                                        </span>
                                                    </button>
                                                </div>
                                                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                                                    <div className="dropdown-content">
                                                        <Link to={`${booking.id}/details`} className="dropdown-item">
                                                            <span className="icon">
                                                                <i className="fas fa-eye"></i>
                                                            </span>
                                                            <span>View</span>
                                                        </Link>
                                                        <Link to={`${booking.id}/update`} className="dropdown-item">
                                                            <span className="icon">
                                                                <i className="fas fa-edit"></i>
                                                            </span>
                                                            <span>Edit</span>
                                                        </Link>
                                                        <Link className="dropdown-item" onClick={() => handleDeleteClick(booking)}>
                                                            <span className="icon">
                                                                <i className="fas fa-trash-alt"></i>
                                                            </span>
                                                            <span>Remove</span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <ConfirmDialog
                                            isOpen={isConfirmDialogOpen}
                                            theme="is-danger"
                                            title="Delete Confirmation"
                                            message={`Are you sure you want to delete ${selectedBooking?.name}?`}
                                            onConfirm={confirmDeleteBooking}
                                            onCancel={cancelDeleteBooking}
                                        />
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="10" className="has-text-centered">No bookings found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                )}
            </main>
        </div>
    );
}

export default BookingList;
