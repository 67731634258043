import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/images/logo600px.png";
import { allowedToView } from "../utils/PermissionUtils";

function Sidebar() {
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const sidebarRef = useRef(null);
    const toggleButtonRef = useRef(null); // Reference for the toggle button
    const location = useLocation();
    const userRole = localStorage.getItem("userRole");
    const isAdmin = (userRole) => {
        return (allowedToView(userRole, ['admin', 'superadmin', 'missions-coordinator']));
    };
    const isSuperAdmin = (userRole) => {
        return (allowedToView(userRole, ['superadmin']));
    }

    // Toggle sidebar
    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    // Close sidebar when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                sidebarRef.current &&
                !sidebarRef.current.contains(event.target) && // Exclude sidebar
                toggleButtonRef.current &&
                !toggleButtonRef.current.contains(event.target) // Exclude toggle button
            ) {
                setSidebarOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    // Close sidebar on navigation
    useEffect(() => {
        setSidebarOpen(false);
    }, [location]);

    return (
        <>
            <button
                ref={toggleButtonRef} // Attach ref to toggle button
                className="button is-light toggle-sidebar-btn primary-bg"
                onClick={toggleSidebar}
                aria-label="Toggle Sidebar"
            >
                {isSidebarOpen ? "✕" : "☰"}
            </button>
            <aside
                ref={sidebarRef}
                className={`menu pb-6 sidebar ${isSidebarOpen ? "is-active" : ""}`}
            >
                <div className="sidebar-logo-section">
                    <Link to="/" className="logo-link">
                        <img src={Logo} alt="CFCM" className="sidebar-logo" />
                    </Link>
                </div>
                <div className="sidebar-menu">
                    <ul className="menu-list">
                        <li>
                            <Link to="/dashboard">Dashboard</Link>
                        </li>
                        {isAdmin(userRole) && (
                            <ul className="menu-list" >
                                <li>
                                    <Link to="members/all">Members</Link>
                                    <ul>
                                        <li>
                                            <Link to="members/all">All</Link>
                                        </li>
                                        <li>
                                            <Link to="members/active">Active</Link>
                                        </li>
                                        <li>
                                            <Link to="members/inactive">Inactive</Link>
                                        </li>
                                        <li>
                                            <Link to="members/unapproved">Un Approved</Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        )}
                        <li>
                            <Link to="events">Events</Link>
                        </li>
                        
                        <li>
                            <Link to="schools">Schools</Link>
                        </li>
                        <li>
                            <Link to="missions">Missions</Link>
                        </li>
                        {isAdmin(userRole) && (
                            <li>
                                <Link to="bookings">Makuyu Booking</Link>
                            </li>
                        )}
                        <li>
                            <Link to="books">Books</Link>
                        </li>
                    </ul>

                    <> {isAdmin(userRole) && (<p className="menu-label">Administration</p>)}
                        <ul className="menu-list">
                            <li>
                                <Link>Settings</Link>
                                <ul>
                                    <li>
                                        <Link to="chapters">Chapters</Link>
                                    </li>
                                    <li>
                                        <Link to="zones">Zones</Link>
                                    </li>
                                    {isAdmin(userRole) && (
                                        <li>
                                            <Link to="mission-types">Mission Types</Link>
                                        </li>
                                    )}
                                    {isSuperAdmin(userRole) && (
                                        <li>
                                            <Link to="roles">Roles</Link>
                                        </li>
                                    )}
                                </ul>
                            </li>
                        </ul>
                    </>

                    <hr />
                    <ul className="menu-list">
                        <li>
                            <Link to="/dashboard/profile">Profile</Link>
                        </li>
                        <li>
                            <Link to="/dashboard/profile/reset-password">Reset Password</Link>
                        </li>
                        <li>
                            <Link to="/logout">Log Out</Link>
                        </li>
                    </ul>
                </div>
            </aside>
        </>
    );
}

export default Sidebar;
