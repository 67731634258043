import React, { useState, useEffect, useRef } from "react";
import { API_URL } from "../config";
import { Link, useSearchParams } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

function EmailConfirmation() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [searchParams] = useSearchParams(); 
    const token = searchParams.get("token");
    const hasCalledAPI = useRef(false);

    useEffect(() => {
        const confirmEmail = async () => {
            // Prevent duplicate API calls
            if (hasCalledAPI.current) return; 
            hasCalledAPI.current = true;
            try {
                const response = await fetch(`${API_URL}/auth/email-confirmation?token=${token}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                const data = await response.json();

                if (response.ok) {
                    setError("")
                    setSuccessMessage(data.message || "Email confirmed successfully.");
                } else {
                    setError(data.message || "Failed to confirm email.");
                }
            } catch (err) {
                setError("An unexpected error occurred. Please try again.");
            } finally {
                setLoading(false);
            }
        };

        if (token) {
            confirmEmail();
        } else {
            setError("Invalid token. Please check your email for a valid link.");
            setLoading(false);
        }
    }, [token]);

    return (
        <div>
            <Header />
            <div className="container mt-6 with-min-height">
                <div className="section">
                    {loading && (
                        <div className="notification is-info has-text-centered">
                            <p className="is-size-5">Validating your email...</p>
                        </div>
                    )}

                    {!loading && error && (
                        <div className="notification is-danger">
                            <p className="is-size-5">Error: {error}</p>
                            <Link to="/login" className="button is-danger mt-4">
                                <span className="icon">
                                    <i className="fas fa-arrow-left"></i>
                                </span>
                                <span>Back to Login</span>
                            </Link>
                        </div>
                    )}

                    {!loading && successMessage && (
                        <div className="notification is-success has-text-centered">
                            <p className="is-size-5">{successMessage}</p>
                            <Link to="/login" className="button is-primary mt-4">
                                <span className="icon">
                                    <i className="fas fa-sign-in-alt"></i>
                                </span>
                                <span>Go to Login</span>
                            </Link>
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default EmailConfirmation;
