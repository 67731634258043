import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../config.js";
import Notification from "../../components/Notification.js";

const DashPasswordReset = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errors, setErrors] = useState({});
    const [notification, setNotification] = useState({ type: "", message: "", isVisible: false });
    const TOKEN = localStorage.getItem("access_token");

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!validate()) {
            return;
        }

        setLoading(true);

        try {
            const response = await fetch(`${API_URL}/profile/password-reset`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${TOKEN}`,
                },
                body: JSON.stringify({
                    current_password: currentPassword,
                    new_password: newPassword,
                    new_password_confirmation: confirmPassword,
                }),
            });

            const data = await response.json();
            console.log("API Response:", data);

            if (response.ok) {
                setNotification({
                    type: "success",
                    message: "Password reset successful. Redirecting to login...",
                    isVisible: true,
                });
                setTimeout(() => navigate("/login"), 3000);
            } else {
                setNotification({ message: data.message || "An error occurred.", type: "danger", isVisible: true });
                setErrors({
                    currentPassword: data.current_password ? data.current_password[0] : "",
                    newPassword: data.new_password ? data.new_password[0] : "",
                    confirmPassword: data.new_password_confirmation ? data.new_password_confirmation[0] : "",
                });
            }
        } catch (error) {
            console.error("Error:", error);
            setNotification({
                type: "danger",
                message: "An unexpected error occurred. Please try again.",
                isVisible: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleCloseNotification = () => {
        setNotification({ ...notification, isVisible: false });
    };

    const validate = () => {
        const newErrors = {};
        let isValid = true;

        if (!currentPassword) {
            newErrors.currentPassword = "Current password is required.";
            isValid = false;
        }
        if (!newPassword) {
            newErrors.newPassword = "New password is required.";
            isValid = false;
        }
        if (!confirmPassword) {
            newErrors.confirmPassword = "Please confirm your password.";
            isValid = false;
        } else if (newPassword !== confirmPassword) {
            newErrors.newPassword = "Passwords do not match.";
            newErrors.confirmPassword = "Passwords do not match.";
            isValid = false;
        }

        console.log("Validation Errors:", newErrors);
        setErrors(newErrors);
        return isValid;
    };

    return (
        <div className="box">
            <div className="container">            <h2 className="title is-4">Reset Password</h2>

                {notification.isVisible && (
                    <Notification
                        type={notification.type}
                        message={notification.message}
                        onClose={handleCloseNotification}
                    />
                )}

                <form onSubmit={handleSubmit}>
                    <div className={`field ${errors.currentPassword ? "is-danger" : ""}`}>
                        <label className="label">Current Password</label>
                        <div className="control has-icons-left">
                            <input
                                className={`input ${errors.currentPassword ? "is-danger" : ""}`}
                                type="password"
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                                placeholder="Enter current password"
                            />
                            <span className="icon is-small is-left">
                                <i className="fas fa-lock"></i>
                            </span>
                        </div>
                        {errors.currentPassword && <p className="help is-danger">{errors.currentPassword}</p>}
                    </div>

                    <hr />
                    
                    <div className={`field ${errors.newPassword ? "is-danger" : ""}`}>
                        <label className="label">New Password</label>
                        <div className="control has-icons-left">
                            <input
                                className={`input ${errors.newPassword ? "is-danger" : ""}`}
                                type="password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                placeholder="Enter new password"
                            />
                            <span className="icon is-small is-left">
                                <i className="fas fa-lock"></i>
                            </span>
                        </div>
                        {errors.newPassword && <p className="help is-danger">{errors.newPassword}</p>}
                    </div>

                    <div className={`field ${errors.confirmPassword ? "is-danger" : ""}`}>
                        <label className="label">Confirm New Password</label>
                        <div className="control has-icons-left">
                            <input
                                className={`input ${errors.confirmPassword ? "is-danger" : ""}`}
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                placeholder="Confirm new password"
                            />
                            <span className="icon is-small is-left">
                                <i className="fas fa-lock"></i>
                            </span>
                        </div>
                        {errors.confirmPassword && <p className="help is-danger">{errors.confirmPassword}</p>}
                    </div>

                    <div className="field">
                        <div className="control">
                            <button
                                type="submit"
                                className="button is-fullwidth primary-bg"
                                disabled={loading}
                            >
                                {loading ? "Loading..." : "Reset Password"}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default DashPasswordReset;
