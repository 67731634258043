import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { API_URL } from "../../config";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog"; // Import the ConfirmDialog component

function Role() {
    const { roleId } = useParams(); // Get the role ID from the URL
    const [users, setUsers] = useState([]);
    const [role, setRole] = useState("");
    const [notification, setNotification] = useState({ type: "", message: "" });
    const [isDialogOpen, setIsDialogOpen] = useState(false); // State for confirmation dialog
    const [userToRemove, setUserToRemove] = useState(null); // State for the user to remove
    const token = localStorage.getItem("access_token");

    // Fetch users for the selected role
    useEffect(() => {
        const fetchUsersForRole = async () => {
            try {
                const response = await fetch(`${API_URL}/roles/${roleId}/users`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setUsers(data.users); // Set the users for this role
                    setRole(data.role); // Optionally, set the role name
                } else {
                    throw new Error("Failed to fetch users for this role");
                }
            } catch (error) {
                console.error("Error fetching users", error);
                setNotification({ type: "danger", message: "Failed to load users." });
            }
        };

        fetchUsersForRole();
    }, [roleId, token]);

    const closeNotification = () => {
        setNotification({ type: "", message: "" });
    };

    // Handle the remove button click
    const handleRemoveClick = (user) => {
        setUserToRemove(user); // Set the user to remove
        setIsDialogOpen(true); // Open the confirmation dialog
    };

    // Function to confirm removal
    const confirmRemoveUser = async () => {
        try {
            const response = await fetch(`${API_URL}/users/${userToRemove.id}/reset-role`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
                // body: JSON.stringify({ user_id: userToRemove.id }), // Send user ID in the request body
            });

            if (response.ok) {
                // Refresh user list after successful role reset
                setUsers(users.filter(u => u.id !== userToRemove.id));
                const data = await response.json();
                setNotification({ type: "success", message: data.message });
            } else {
                const errorData = await response.json();
                throw new Error(errorData.errors ? errorData.errors : "Failed to reset user role.");
            }
        } catch (error) {
            console.error("Error removing user", error);
            setNotification({ type: "danger", message: error.message });
        } finally {
            setIsDialogOpen(false); // Close the confirmation dialog
            setUserToRemove(null); // Reset the user to remove
        }
    };

    const closeDialog = () => {
        setIsDialogOpen(false);
        setUserToRemove(null);
    };

    return (
        <main>
            <nav className="level">
                <div className="level-left">
                    <div className="level-item">
                        <p className="title">Users in Role: {role?.name?.toUpperCase()}</p>
                    </div>
                </div>

                <div className="level-right">
                    <p className="level-item">
                        <Link to="/dashboard/roles" className="button primary-bg">
                            <i className="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Back
                        </Link>
                    </p>
                </div>
            </nav>

            <div className="column">
                {notification.message && (
                    <Notification
                        type={notification.type}
                        message={notification.message}
                        onClose={closeNotification}
                    />
                )}
            </div>

            <div className="columns">
                <div className="column is-full-width dash-section">
                    <table className="table is-striped is-fullwidth">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Role</th>
                                <th>Option</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user, index) => (
                                <tr key={user.id}>
                                    <td>{index + 1}</td>
                                    <td>{user.first_name}</td>
                                    <td>{user.last_name}</td>
                                    <td>{user.email}</td>
                                    <td>{role.name}</td>
                                    <td>
                                        {role.name !== 'superadmin' && (
                                            <button 
                                                className="has-text-bold danger-outline-button p-1"
                                                onClick={() => handleRemoveClick(user)} // Set up the remove button click handler
                                            >
                                                Remove
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Confirmation Dialog */}
            <ConfirmDialog
                isOpen={isDialogOpen}
                onConfirm={confirmRemoveUser}
                onCancel={closeDialog}
                title="Confirm Removal"
                message={`Are you sure you want to reset the role for ${userToRemove?.first_name} ${userToRemove?.last_name}?`}
            />
        </main>
    );
}

export default Role;
