import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { API_URL, STORAGE_URL } from "../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

function EventDetails() {
    const { id, slug } = useParams();
    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    // Fetch event data from API
    const fetchEvent = useCallback(async () => {
        try {
            const response = await fetch(`${API_URL}/events/public/${id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.ok) {
                const data = await response.json();
                setEvent(data.event);
            } else {
                throw new Error("Failed to fetch event details");
            }
        } catch (error) {
            setError("An error occurred while fetching event details.");
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    }, [id, slug]);

    useEffect(() => {
        fetchEvent();
    }, [fetchEvent]);

    if (loading) {
        return (
            <div>
                <Header />
                <div className="container pt-6 pb-6">
                    <p>Loading event details...</p>
                </div>
                <Footer />
            </div>
        );
    }

    if (error) {
        return (
            <div>
                <Header />
                <div className="container pt-6 pb-6">
                    <p className="has-text-danger">{error}</p>
                </div>
                <Footer />
            </div>
        );
    }

    if (!event) {
        return (
            <div>
                <Header />
                <div className="container pt-6 pb-6">
                    <p>No event details available.</p>
                </div>
                <Footer />
            </div>
        );
    }

    return (
        <div>
            <Header />
            <div className="container pt-6 pb-6">
                <h1 className="title">{event.name}</h1>

                <div className="columns is-multiline">
                    <div className="column is-6">
                        <figure className="image is-4by3">
                            <img src={STORAGE_URL + "/events/" + event.image || "https://via.placeholder.com/1280x720"} alt={event.name} className="fit-image" />
                        </figure>
                    </div>
                    <div className="column is-6">
                        <p className="content is-medium has-text-justified">
                            {event.description}
                        </p>

                        <div className="mb-4">
                            <p><strong>Status:</strong> {event.status}</p>
                        </div>

                        <div className="mb-4">
                            <p className="is-flex is-align-items-center">
                                <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
                                <strong>Venue:</strong> &nbsp; {event.venue}
                            </p>
                        </div>

                        {event.require_registration && (
                            <div className="mb-4">
                                <p><strong>Registration Fee:</strong> {event.registration_fee}</p>
                                <p className="is-flex is-align-items-center">
                                    <strong>Payment Option:</strong>&nbsp; {event.payment_option}
                                </p>
                                <p><strong>Payment Description:</strong> {event.payment_description}</p>
                            </div>
                        )}

                        <div className="mb-4">
                            <p><strong>Event Date:</strong> {event.start_date} at {event.start_time}</p>
                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    );
}

export default EventDetails;
