import React, { useState, useEffect, useRef } from "react";
import { API_URL } from "../config";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Notification from "../components/Notification";

function PasswordReset() {
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({ password: "", repeatPassword: "" });
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const hasCalledAPI = useRef(false);
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [notification, setNotification] = useState({ type: "", message: "", isVisible: false });
    const [isValidToken, setIsValidToken] = useState(false);
    const navigate = useNavigate();

    // Confirm token validity on load
    useEffect(() => {
        const confirmToken = async () => {
            if (hasCalledAPI.current) return;
            hasCalledAPI.current = true;
            try {
                const response = await fetch(`${API_URL}/auth/password-reset/token-confirmation?token=${token}`, {
                    method: "GET",
                    headers: { "Content-Type": "application/json" },
                });

                const data = await response.json();
                if (response.ok) {
                    setIsValidToken(true);
                } else {
                    setNotification({
                        type: "danger",
                        message: data.error || "Failed to confirm token.",
                        isVisible: true,
                    });
                }
            } catch {
                setNotification({
                    type: "danger",
                    message: "An unexpected error occurred. Please try again.",
                    isVisible: true,
                });
            } finally {
                setLoading(false);
            }
        };

        if (token) {
            confirmToken();
        } else {
            setNotification({
                type: "danger",
                message: "Invalid token. Please check your email for a valid link.",
                isVisible: true,
            });
            setLoading(false);
        }
    }, [token]);

    const handleCloseNotification = () => {
        setNotification({ ...notification, isVisible: false });
    };

    const validate = () => {
        const newErrors = {};
        let isValid = true;

        if (!password) {
            newErrors.password = "Password is required.";
            isValid = false;
        }
        if (!repeatPassword) {
            newErrors.repeatPassword = "Please confirm your password.";
            isValid = false;
        } else if (password !== repeatPassword) {
            newErrors.repeatPassword = "Passwords do not match.";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!validate()) {
            return;
        }

        setLoading(true);

        try {
            const response = await fetch(`${API_URL}/auth/password-reset/reset`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ token, password, password_confirmation: repeatPassword }),
            });

            const data = await response.json();
            if (response.ok) {
                setNotification({
                    type: "success",
                    message: "Password reset successful. Redirecting to login...",
                    isVisible: true,
                });
                setTimeout(() => navigate("/login"), 3000); // Redirect after success
            } else {
                setNotification({ message: data.message || "An error occurred.", type: "danger", isVisible: true });
                if (data.password) {
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        password: data.password[0],
                    }));
                }

                if (data.password_confirmation) {
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        repeatPassword: data.password_confirmation[0],
                    }));
                }
            }
        } catch {
            setNotification({
                type: "danger",
                message: "An unexpected error occurred. Please try again.",
                isVisible: true,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Header />
            <main>
                <div className="signin-page mb-4">
                    <div className="signin-bg"></div>
                    <div className="signin-card">
                        <div className="signin-content">
                            <div className="media pb-4 mb-4 is-justify-content-space-between">
                                <div className="media-left">
                                    <Link to="/login"><i className="fa fa-arrow-left"></i> &nbsp; To Log In</Link>
                                </div>
                                <div className="media-right">
                                    <Link to="/"><i className="fa-solid fa-x"></i></Link>
                                </div>
                            </div>
                            <div className="content">
                                <h1 className="sign-in-title">Password Reset</h1>

                                {notification.isVisible && (
                                    <Notification
                                        type={notification.type}
                                        message={notification.message}
                                        onClose={handleCloseNotification}
                                    />
                                )}

                                {loading && <p>Loading. Please wait...</p>}

                                {!loading && isValidToken && (
                                    <form onSubmit={handleSubmit}>
                                        <div className={`field ${errors.password ? "is-danger" : ""}`}>
                                            <label className="label">New Password</label>
                                            <div className="control has-icons-left">
                                                <input
                                                    className={`input ${errors.password ? "is-danger" : ""}`}
                                                    type="password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    placeholder="Enter your new password"
                                                />
                                                <span className="icon is-small is-left">
                                                    <i className="fas fa-lock"></i>
                                                </span>
                                            </div>
                                            {errors.password && <p className="help is-danger">{errors.password}</p>}
                                        </div>

                                        <div className={`field ${errors.repeatPassword ? "is-danger" : ""}`}>
                                            <label className="label">Repeat New Password</label>
                                            <div className="control has-icons-left">
                                                <input
                                                    className={`input ${errors.repeatPassword ? "is-danger" : ""}`}
                                                    type="password"
                                                    value={repeatPassword}
                                                    onChange={(e) => setRepeatPassword(e.target.value)}
                                                    placeholder="Re-enter password"
                                                />
                                                <span className="icon is-small is-left">
                                                    <i className="fas fa-lock"></i>
                                                </span>
                                            </div>
                                            {errors.repeatPassword && <p className="help is-danger">{errors.repeatPassword}</p>}
                                        </div>

                                        <div className="field">
                                            <div className="control">
                                                <button
                                                    type="submit"
                                                    className="button is-fullwidth primary-bg"
                                                    disabled={loading}
                                                >
                                                    {loading ? "Loading..." : "Reset Password"}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                )}

                                <div className="field mb-5 mt-4">
                                    <div className="control">
                                        <label>
                                            Don't have an account? &nbsp;
                                            <Link to="/signup">Sign Up Here.</Link>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default PasswordReset;
