import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../config";
import Notification from "../../components/Notification";
import { allowedToView } from "../../utils/PermissionUtils";

function Zones() {
    const [zones, setZones] = useState([]);
    const [newZone, setNewZone] = useState({ name: "", description: "", chapter_id: "" });
    const [editZone, setEditZone] = useState(null);
    const [notification, setNotification] = useState({ type: "", message: "" });
    const [errors, setErrors] = useState({}); // State for input errors
    const [chapters, setChapters] = useState([]); // State for chapters
    const navigate = useNavigate();

    const token = localStorage.getItem("access_token");
    const userRole = localStorage.getItem("userRole");

    const canEdit = (userRole) => {
        return (allowedToView(userRole, ['admin', 'superadmin', 'missions-coordinator']));
    };

    // Fetch Zones
    const fetchZones = useCallback(async () => {
        try {
            const response = await fetch(`${API_URL}/zones`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                setZones(data);
            } else if (response.status === 401) {
                navigate("/logout");
            } else {
                throw new Error("Failed to fetch zones");
            }
        } catch (error) {
            console.error("There was an error fetching the zones!", error);
            setNotification({ type: "danger", message: "Failed to fetch zones." });
        }
    }, [token, navigate]);

    // Fetch Chapters
    const fetchChapters = useCallback(async () => {
        try {
            const response = await fetch(`${API_URL}/chapters`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                setChapters(data);
            } else if (response.status === 401) {
                navigate("/logout");
            } else {
                throw new Error("Failed to fetch chapters");
            }
        } catch (error) {
            console.error("There was an error fetching the chapters!", error);
            setNotification({ type: "danger", message: "Failed to fetch chapters." });
        }
    }, [token, navigate]);

    useEffect(() => {
        fetchZones();
        fetchChapters();
    }, [fetchZones, fetchChapters]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({}); // Clear previous errors

        if (!newZone.name.trim() || !newZone.chapter_id) {
            setErrors({ name: ["Zone name is required"], chapter_id: ["Chapter is required"] });
            return;
        }

        try {
            const response = editZone
                ? await updateZone(editZone.id, newZone)
                : await createZone(newZone);

            if (!response.ok) {
                const errorData = await response.json();
                setErrors(errorData || {});
                return;
            }

            const message = editZone
                ? "Zone updated successfully."
                : "Zone added successfully.";

            setNotification({ type: "success", message });
            setNewZone({ name: "", description: "", chapter_id: "" });
            setEditZone(null);
            fetchZones();
        } catch (error) {
            console.error("There was an error submitting the form!", error);
            setNotification({ type: "danger", message: "Failed to submit the zone." });
        }
    };

    const createZone = async (zone) => {
        const response = await fetch(`${API_URL}/zones`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify(zone),
        });
        return response;
    };

    const updateZone = async (id, zone) => {
        const response = await fetch(`${API_URL}/zones/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify(zone),
        });
        return response;
    };

    const handleEdit = (zone) => {
        setNewZone({ name: zone.name, description: zone.description, chapter_id: zone.chapter_id });
        setEditZone(zone);
    };

    const handleDelete = async (id) => {
        try {
            await fetch(`${API_URL}/zones/${id}`, {
                method: "DELETE",
                headers: {
                    "Authorization": `Bearer ${token}`,
                },
            });
            fetchZones();
            setNotification({ type: "success", message: "Zone deleted successfully." });
        } catch (error) {
            console.error("There was an error deleting the zone!", error);
            setNotification({ type: "danger", message: "Failed to delete the zone." });
        }
    };

    const closeNotification = () => {
        setNotification({ type: "", message: "" });
    };

    return (
        <main>
            <nav className="level">
                <div className="level-left">
                    <div className="level-item">
                        <p className="title">Zones</p>
                    </div>
                </div>
                <div className="level-right">

                </div>
            </nav>

            <div className="columns">
                <div className={`column ${canEdit(userRole) ? 'is-half' : 'is-fullwidth'} dash-section vertical-separator`}>
                    <h1 className="section-title">Zones</h1>
                    <table className="table is-striped is-fullwidth">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Chapter</th>
                                {canEdit(userRole) && (
                                    <th>Options</th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {zones.map((zone, index) => (
                                <tr key={zone.id}>
                                    <td>{index + 1}</td>
                                    <td>{zone.name}</td>
                                    <td>{zone.description}</td>
                                    <td>{zone.chapter ? zone.chapter.name : 'N/A'}</td>
                                    {canEdit(userRole) && (
                                        <td>
                                            <div className="dropdown is-right">
                                                <div className="dropdown-trigger">
                                                    <button
                                                        className="button is-white"
                                                        aria-haspopup="true"
                                                        aria-controls="dropdown-menu"
                                                        onClick={() => handleEdit(zone)}
                                                    >
                                                        <span className="icon">
                                                            <i className="fas fa-edit"></i>
                                                        </span>
                                                    </button>
                                                    <button
                                                        className="button is-white"
                                                        aria-haspopup="true"
                                                        aria-controls="dropdown-menu"
                                                        onClick={() => handleDelete(zone.id)}
                                                    >
                                                        <span className="icon">
                                                            <i className="fas fa-trash-alt"></i>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {canEdit(userRole) && (
                    <div className="column is-half dash-section">
                        <h1 className="section-title">{editZone ? "Edit Zone" : "Add New Zone"}</h1>

                        {notification.message && (
                            <Notification
                                type={notification.type}
                                message={notification.message}
                                onClose={closeNotification}
                            />
                        )}

                        <form onSubmit={handleSubmit}>
                            <div className="field">
                                <label className="label">Name</label>
                                <div className="control has-icons-left has-icons-right">
                                    <input
                                        className={`input ${errors.name ? "is-danger" : ""}`}
                                        type="text"
                                        placeholder="e.g. Waiyaki Way"
                                        value={newZone.name}
                                        onChange={(e) => setNewZone({ ...newZone, name: e.target.value })}
                                        required
                                    />
                                    <span className="icon is-small is-left">
                                        <i className="fas fa-map-marker-alt"></i>
                                    </span>
                                </div>
                                {errors.name && errors.name.map((error, index) => (
                                    <p key={index} className="help is-danger">{error}</p>
                                ))}
                            </div>

                            <div className="field">
                                <label className="label">Description</label>
                                <div className="control">
                                    <textarea
                                        className="textarea"
                                        placeholder="Description"
                                        value={newZone.description}
                                        onChange={(e) => setNewZone({ ...newZone, description: e.target.value })}
                                    ></textarea>
                                </div>
                            </div>

                            <div className="field">
                                <label className="label">Chapter</label>
                                <div className="control">
                                    <div className="select">
                                        <select
                                            value={newZone.chapter_id}
                                            onChange={(e) => setNewZone({ ...newZone, chapter_id: e.target.value })}
                                            required
                                        >
                                            <option value="">Select a chapter</option>
                                            {chapters.map(chapter => (
                                                <option key={chapter.id} value={chapter.id}>
                                                    {chapter.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                {errors.chapter_id && errors.chapter_id.map((error, index) => (
                                    <p key={index} className="help is-danger">{error}</p>
                                ))}
                            </div>

                            <div className="field">
                                <div className="control">
                                    <button type="submit" className="button primary-bg">{editZone ? "Update" : "Add New"}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </main>
    );
}

export default Zones;
