import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../../config.js";
import { formatTimestamp } from '../../utils/TextUtils.js';

function Users() {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [isDropdownOpen, setIsDropdownOpen] = useState(null);
    const navigate = useNavigate();
    const { status } = useParams();

    const toggleDropdown = (index) => {
        if (isDropdownOpen === index) {
            setIsDropdownOpen(null); // Close dropdown if the same one is clicked
        } else {
            setIsDropdownOpen(index); // Open the clicked dropdown
        }
    };

    useEffect(() => {
        const fetchUsers = async () => {
            const token = localStorage.getItem("access_token");

            try {
                const link = status === 'all' ? '/users' : `/users/status/${status}`;

                const response = await fetch(`${API_URL}${link}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setUsers(data);
                    setFilteredUsers(data);
                } else if (response.status === 401) {
                    navigate("/logout");
                } else {
                    throw new Error("Failed to fetch users");
                }
            } catch (error) {
                setError("An error occurred while fetching users.");
                console.error("Error:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, [navigate, status]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isDropdownOpen !== null) {
                const dropdown = document.querySelector(`.dropdown.is-active`);
                if (dropdown && !dropdown.contains(event.target)) {
                    setIsDropdownOpen(null);
                }
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [isDropdownOpen]);

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);

        const filtered = users.filter(user =>
            user.first_name.toLowerCase().includes(value) ||
            user.last_name.toLowerCase().includes(value) ||
            user.email.toLowerCase().includes(value)
        );
        setFilteredUsers(filtered);
    };

    return (
        <div>
            <main>
                <nav className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <p className="title">Members {status.charAt(0).toUpperCase() + status.slice(1)} </p>
                        </div>
                    </div>
                    <div className="level-right">
                        
                    </div>
                </nav>

                <h1>Users List</h1>

                <div className="field">
                    <div className="control">
                        <input
                            className="input"
                            type="text"
                            placeholder="Search by name, email, or role"
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                    </div>
                </div>

                {loading ? (
                    <p>Loading users...</p>
                ) : error ? (
                    <p className="has-text-danger">{error}</p>
                ) : (
                    <table className="table is-striped is-fullwidth">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone #</th>
                                <th>Role</th>
                                <th>Created At</th>
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredUsers.length > 0 ? (
                                filteredUsers.map((user, index) => (
                                    <tr key={user.id}>
                                        <td>{index + 1}</td>
                                        <td> <Link to={`/dashboard/members/${user.id}/details`} className="primary-text-color"> {user.first_name} {user.last_name} </Link></td>
                                        <td>{user?.email}</td>
                                        <td>{user?.phone_number || "N/A"}</td>
                                        <td>{user?.role?.name}</td>
                                        <td>{formatTimestamp(user.created_at)}</td>
                                        <td>
                                            <div className={`dropdown is-right ${isDropdownOpen === index ? 'is-active' : ''}`}>
                                                <div className="dropdown-trigger">
                                                    <button
                                                        className="button is-white"
                                                        aria-haspopup="true"
                                                        aria-controls="dropdown-menu"
                                                        onClick={() => toggleDropdown(index)}
                                                    >
                                                        <span className="icon">
                                                            <i className="fas fa-ellipsis-v"></i>
                                                        </span>
                                                    </button>
                                                </div>
                                                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                                                    <div className="dropdown-content">
                                                        <Link to={`/dashboard/members/${user.id}/details`} className="dropdown-item">
                                                            <span className="icon">
                                                                <i className="fas fa-eye"></i>
                                                            </span>
                                                            <span>View</span>
                                                        </Link>
                                                        {/* <Link to="#" className="dropdown-item">
                                                            <span className="icon">
                                                                <i className="fas fa-edit"></i>
                                                            </span>
                                                            <span>Edit</span>
                                                        </Link> */}
                                                        <Link to="#" className="dropdown-item">
                                                            <span className="icon">
                                                                <i className="fas fa-trash-alt"></i>
                                                            </span>
                                                            <span>De-activate</span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="7" className="has-text-centered">No users found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                )}
            </main>
        </div>
    );
}

export default Users;
