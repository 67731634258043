import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { API_URL } from "../../config";
import { Pie } from "react-chartjs-2";
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend
} from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

function Dashboard() {
    const navigate = useNavigate();

    // State to hold the statistics
    const [stats, setStats] = useState({
        activeUsers: 0,
        upcomingEvents: 0,
        pastEvents: 0,
        upcomingMissions: 0,
        pastMissions: 0,
        schoolsCount: 0,
        missionBooksCount: 0,
        genderDistribution: {
            male: 0,
            female: 0,
            unspecified: 0
        },
        schoolType: {
            girls: 0,
            boys: 0,
            mixed: 0
        },
        missionBooksAvailability: {
            available: 0,
            unavailable: 0,
            unspecified: 0
        }
    });

    const fetchDashboardStats = async () => {
        try {
            const token = localStorage.getItem("access_token");

            if (!token) {
                navigate("/logout");
                return;
            }

            const response = await fetch(`${API_URL}/dashboard-stats`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            });

            if (response.ok) {
                const data = await response.json();
                setStats(data);
            } else if (response.status === 401) {
                navigate("/logout");
            } else {
                console.error("Failed to fetch dashboard stats", response.statusText);
            }
        } catch (error) {
            console.error("Error fetching dashboard stats:", error);
        }
    };

    useEffect(() => {
        fetchDashboardStats();
    }, [navigate]);

    const genderData = {
        labels: ["Male", "Female", "Unspecified"],
        datasets: [
            {
                data: [
                    stats.genderDistribution.male,
                    stats.genderDistribution.female,
                    stats.genderDistribution.unspecified
                ],
                backgroundColor: ["#36A2EB", "#FF6384", "#FFCE56"],
                hoverBackgroundColor: ["#36A2EB", "#FF6384", "#FFCE56"]
            }
        ]
    };

    const schoolTypeData = {
        labels: ["Girls", "Boys", "Mixed"],
        datasets: [
            {
                data: [
                    stats.schoolType.girls,
                    stats.schoolType.boys,
                    stats.schoolType.mixed
                ],
                backgroundColor: ["#36A2EB", "#FF6384", "#FFCE56"],
                hoverBackgroundColor: ["#36A2EB", "#FF6384", "#FFCE56"]
            }
        ]
    };

    const missionBooksAvailability = {
        labels: ["Available", "UnAvailable", "Unspecified"],
        datasets: [
            {
                data: [
                    stats.missionBooksAvailability.available,
                    stats.missionBooksAvailability.unavailable,
                    stats.missionBooksAvailability.unspecified
                ],
                backgroundColor: ["#36A2EB", "#FF6384", "#FFCE56"],
                hoverBackgroundColor: ["#36A2EB", "#FF6384", "#FFCE56"]
            }
        ]
    };

    return (
        <div>
            <main>
                <div className="columns">
                    <div className="column is-3">
                        <div className="card p-4">
                            <p className="dash-card-title">ACTIVE USERS</p>
                            <div className="card-content has-text-weight-semibold pl-0 pr-0">
                                <p className="title">{stats.activeUsers}</p>
                                <hr />
                            </div>
                        </div>
                    </div>

                    <div className="column is-3">
                        <div className="card p-4">
                            <p className="dash-card-title"><Link to="/dashboard/missions" className="no_deco_link">UPCOMING MISSIONS</Link> </p>
                            <div className="card-content has-text-weight-semibold pl-0 pr-0">
                                <Link to="/dashboard/missions" className="no_deco_link"><p className="title">{stats.upcomingMissions}</p></Link>
                                <hr />
                            </div>
                        </div>
                    </div>

                    <div className="column is-3">
                        <div className="card p-4">
                            <p className="dash-card-title"><Link to="/dashboard/missions" className="no_deco_link">PAST MISSIONS</Link> </p>
                            <div className="card-content has-text-weight-semibold pl-0 pr-0">
                                <Link to="/dashboard/missions" className="no_deco_link"><p className="title">{stats.pastMissions}</p></Link>
                                <hr />
                            </div>
                        </div>
                    </div>

                    <div className="column is-3">
                        <div className="card p-4">
                            <p className="dash-card-title"><Link to="/dashboard/events" className="no_deco_link">UPCOMING EVENTS</Link> </p>
                            <div className="card-content has-text-weight-semibold pl-0 pr-0">
                                <Link to="/dashboard/events" className="no_deco_link"><p className="title">{stats.upcomingEvents}</p></Link>
                                <hr />
                            </div>
                        </div>
                    </div>

                </div> {/* End columns */}

                <div className="columns">


                    <div className="column is-3">
                        <div className="card p-4">
                            <p className="dash-card-title"> <Link to="/dashboard/events" className="no_deco_link">PAST EVENTS</Link></p>
                            <div className="card-content has-text-weight-semibold pl-0 pr-0">
                                <Link to="/dashboard/events" className="no_deco_link"><p className="title">{stats.pastEvents}</p></Link>
                                <hr />
                            </div>
                        </div>
                    </div>

                    <div className="column is-3">
                        <div className="card p-4">
                            <p className="dash-card-title"> <Link to="/dashboard/schools" className="no_deco_link">SCHOOLS</Link></p>
                            <div className="card-content has-text-weight-semibold pl-0 pr-0">
                                <Link to="/dashboard/schools" className="no_deco_link"><p className="title">{stats.schoolsCount}</p></Link>
                                <hr />
                            </div>
                        </div>
                    </div>

                    <div className="column is-3">
                        <div className="card p-4">
                            <p className="dash-card-title"> <Link to="/dashboard/books" className="no_deco_link">MISSION BOOKS</Link></p>
                            <div className="card-content has-text-weight-semibold pl-0 pr-0">
                                <Link to="/dashboard/books" className="no_deco_link"><p className="title">{stats.missionBooksCount}</p></Link>
                                <hr />
                            </div>
                        </div>
                    </div>
                </div> {/* End columns */}

                <div className="columns">
                    <div className="column is-3">
                        <div className="card p-4">
                            <p className="dash-card-title">CFCM MEMBERS BY GENDER</p>
                            <div className="card-content has-text-centered">
                                <Pie data={genderData} />
                            </div>
                        </div>
                    </div>

                    <div className="column is-3">
                        <div className="card p-4">
                            <p className="dash-card-title">School Type</p>
                            <div className="card-content has-text-centered">
                                <Pie data={schoolTypeData} />
                            </div>
                        </div>
                    </div>

                    <div className="column is-3">
                        <div className="card p-4">
                            <p className="dash-card-title">Mission Books Availability</p>
                            <div className="card-content has-text-centered">
                                <Pie data={missionBooksAvailability} />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Dashboard;
