import React, { useState, useEffect, useCallback } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../styles/Home.css";
import { Link } from "react-router-dom";
import munaImg from '../assets/images/muna.jpg';
import charaguImg from '../assets/images/charagu.jpg';
import lizImg from '../assets/images/elizabeth.jpg';
import makuyu from '../assets/images/makuyu.jpg';
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { API_URL, STORAGE_URL } from "../config";


function Home() {
    const [events, setEvents] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    const fetchEvents = useCallback(async () => {
        try {
            const response = await fetch(`${API_URL}/events/public/upcoming`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.ok) {
                const data = await response.json();
                setEvents(data.events);
            } else if (response.status === 401) {
                navigate("/logout");
            } else {
                throw new Error("Failed to fetch events");
            }
        } catch (error) {
            setError("An error occurred while fetching events.");
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    }, [navigate]);

    useEffect(() => {
        fetchEvents();
    }, [fetchEvents])

    return (
        <div>
            <Header />
            <main>
                <div className="banner light-gray-bg">

                    <div className="columns is-vcentered big-margin">
                        <div className="column is-8">
                            <p className="big-title">
                                Commissioners For Christ Ministry.<br /> Our Mission Statement:
                            </p>
                            <br />
                            <p className="normal-text">
                                At CFCM we aim to make and develop effective disciples in fulfilling
                                of Christ Mission by encouraging, equipping,
                                mobilizing and mentoring using biblical principles as inspired by the Holy Spirit.
                            </p>

                            <div className="columns mt-5">
                                <div className="column is-half">
                                    <div className="buttons banner-buttons">
                                        <Link className="button primary-bg">Support The Ministry</Link>
                                        <Link className="button is-outline" to="/signup">Become A Commissioner</Link>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="column">

                        </div>
                    </div>
                </div>

                <div className="primary-bg mb-4">
                    <div className="columns is-vcentered small-margin">
                        <div className="column overwrapping-divs">
                            <div className="overwrapping-item">
                                <img src={charaguImg} alt="" />
                            </div>
                            <div className="overwrapping-item">
                                <img src={lizImg} alt="" />
                            </div>
                            <div className="overwrapping-item">
                                <img src={munaImg} alt="" />
                            </div>
                            <div className="overwrapping-item">
                                <img src={makuyu} alt="" />
                            </div>
                        </div>

                        <div className="column">
                            <div className="story-section">
                                <h1>Become a member</h1>
                                <p>At CFCM we aim to make and develop effective disciples in fulfilling
                                    of Christ Mission by encouraging, equipping, mobilizing and
                                    mentoring using biblical principles as inspired by the Holy Spirit.</p>
                            </div>
                            <div className="story-section">
                                <h1>Join Us For Missions</h1>
                                <p>At CFCM we aim to make and develop effective disciples in fulfilling
                                    of Christ Mission by encouraging, equipping, mobilizing and
                                    mentoring using biblical principles as inspired by the Holy Spirit.</p>
                            </div>
                            <div className="story-section">
                                <h1>Provide mentorship</h1>
                                <p>At CFCM we aim to make and develop effective disciples in fulfilling
                                    of Christ Mission by encouraging, equipping, mobilizing and
                                    mentoring using biblical principles as inspired by the Holy Spirit.</p>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="pt-6 container">
                    <h1 className="title">Upcoming Events</h1>
                    <p className="section-desc">
                        At CFCM we aim to make and develop effective disciples in fulfilling Christ's Mission by encouraging, equipping,
                        mobilizing, and mentoring using biblical principles as inspired by the Holy Spirit.
                    </p>

                    <div className="columns is-multiline pt-5 pb-5">
                        {events.length > 0 ? (
                            events.slice(0, 3).map((event, index) => (
                                <div className="column is-4" key={index}>
                                    <div className="card p-4">
                                        <div className="card-image">
                                            <Link to={`/events/${event.id}/${event.slug}`}>
                                                <figure className="image is-4by3 box">
                                                    <img src={STORAGE_URL + '/events/' + event.image || 'https://via.placeholder.com/1280x720'} alt={event.name} className="cover-image" />
                                                </figure>
                                            </Link>
                                        </div>
                                        <div className="card-content has-text-weight-semibold pl-0 pr-0">
                                            <p className="title is-5 pb-0 mb-2">
                                                <Link to={`/events/${event.id}/${event.slug}`} className="primary-text-color">{event.name}</Link>
                                            </p>
                                            <p className="content">
                                                {event.description && (
                                                    <>
                                                        {event.description.slice(0, 152)}
                                                        {event.description.length > 152 ? "..." : ""}
                                                    </>
                                                )}
                                            </p>
                                            <Link to={`/events/${event.id}/${event.slug}`} className="see-more">
                                                <FontAwesomeIcon icon={faChevronRight} className="primary-text-color" /> &nbsp; See More Details
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="column pt-6 pb-6">
                                <p className="has-text-centered">No events found</p>
                            </div>
                        )}

                    </div>
                </div>

            </main>
            <Footer />
        </div>
    );
}

export default Home;
