import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from "../../config";
import Notification from "../../components/Notification";

function MissionTypes() {
    const [missionTypes, setMissionTypes] = useState([]);
    const [newMissionType, setNewMissionType] = useState("");
    const [editMissionType, setEditMissionType] = useState(null);
    const [notification, setNotification] = useState({ type: "", message: "" });
    const [errors, setErrors] = useState({}); // State for input errors
    const navigate = useNavigate();

    const token = localStorage.getItem("access_token");

    const fetchMissionTypes = useCallback(async () => {
        try {
            const response = await fetch(`${API_URL}/mission-types`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                setMissionTypes(data);
            } else if (response.status === 401) {
                navigate("/logout");
            } else {
                throw new Error("Failed to fetch the mission types");
            }
        } catch (error) {
            console.error("There was an error fetching the mission types!", error);
            setNotification({ type: "danger", message: "Failed to fetch mission types." });
        }
    }, [token, navigate]);

    useEffect(() => {
        fetchMissionTypes();
    }, [fetchMissionTypes]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({}); // Clear previous errors

        if (!newMissionType.trim()) {
            setErrors({ name: ["Mission type name is required"] });
            return;
        }

        try {
            const response = editMissionType
                ? await updateMissionType(editMissionType.id, newMissionType)
                : await createMissionType(newMissionType);

            if (!response.ok) {
                const errorData = await response.json();
                setErrors(errorData || {});
                return;
            }

            const message = editMissionType
                ? "Mission type updated successfully."
                : "Mission type added successfully.";

            setNotification({ type: "success", message });
            setNewMissionType("");
            setEditMissionType(null);
            fetchMissionTypes();
        } catch (error) {
            console.error("There was an error submitting the form!", error);
            setNotification({ type: "danger", message: "Failed to submit the mission type." });
        }
    };

    const createMissionType = async (name) => {
        const response = await fetch(`${API_URL}/mission-types`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({ name }),
        });
        return response;
    };

    const updateMissionType = async (id, name) => {
        const response = await fetch(`${API_URL}/mission-types/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({ name }),
        });
        return response;
    };

    const handleEdit = (missionType) => {
        setNewMissionType(missionType.name);
        setEditMissionType(missionType);
    };

    const handleDelete = async (id) => {
        try {
            await fetch(`${API_URL}/mission-types/${id}`, {
                method: "DELETE",
                headers: {
                    "Authorization": `Bearer ${token}`,
                },
            });
            fetchMissionTypes();
            setNotification({ type: "success", message: "Mission type deleted successfully." });
        } catch (error) {
            console.error("There was an error deleting the mission type!", error);
            setNotification({ type: "danger", message: "Failed to delete the mission type." });
        }
    };

    const closeNotification = () => {
        setNotification({ type: "", message: "" });
    };

    return (
        <main>
            <nav className="level">
                <div className="level-left">
                    <div className="level-item">
                        <p className="title">Mission Types</p>
                    </div>
                </div>
                <div className="level-right">
                    
                </div>
            </nav>

            <div className="columns">
                <div className="column is-half dash-section vertical-separator">
                    <h1 className="section-title">Mission Types</h1>
                    <table className="table is-striped is-fullwidth">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {missionTypes.map((missionType, index) => (
                                <tr key={missionType.id}>
                                    <td>{index + 1}</td>
                                    <td>{missionType.name}</td>
                                    <td>
                                        <div className="dropdown is-right">
                                            <div className="dropdown-trigger">
                                                <button
                                                    className="button is-white"
                                                    aria-haspopup="true"
                                                    aria-controls="dropdown-menu"
                                                    onClick={() => handleEdit(missionType)}
                                                >
                                                    <span className="icon">
                                                        <i className="fas fa-edit"></i>
                                                    </span>
                                                </button>
                                                <button
                                                    className="button is-white"
                                                    aria-haspopup="true"
                                                    aria-controls="dropdown-menu"
                                                    onClick={() => handleDelete(missionType.id)}
                                                >
                                                    <span className="icon">
                                                        <i className="fas fa-trash-alt"></i>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="column is-half dash-section">
                    <h1 className="section-title">{editMissionType ? "Edit Mission Type" : "Add New Mission Type"}</h1>

                    {notification.message && (
                        <Notification
                            type={notification.type}
                            message={notification.message}
                            onClose={closeNotification}
                        />
                    )}

                    <form onSubmit={handleSubmit}>
                        <div className="field">
                            <label className="label">Type</label>
                            <div className="control has-icons-left has-icons-right">
                                <input
                                    className={`input ${errors.name ? "is-danger" : ""}`}
                                    type="text"
                                    placeholder="e.g Sunday Service"
                                    value={newMissionType}
                                    onChange={(e) => setNewMissionType(e.target.value)}
                                    required
                                />
                                <span className="icon is-small is-left">
                                    <i className="fas fa-school"></i>
                                </span>
                            </div>
                            {errors.name && errors.name.map((error, index) => (
                                <p key={index} className="help is-danger">{error}</p>
                            ))}
                        </div>

                        <div className="field">
                            <div className="control">
                                <button type="submit" className="button primary-bg">{editMissionType ? "Update" : "Add New"}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </main>
    );
}

export default MissionTypes;
